export const getFillByLot = (id, selected, lands) => {
    const land = lands.find(l => Number(l.lote) === Number(id));
    const disabledColor = "#6F8789";
    const normalColor = "#0096B3";
    const selectedColor = "#5AE5F4";

    if (!land) {
        return disabledColor; // disabled
    }

    if (selected) {
        if (Number(id) === Number(selected.lote)) {
            return selectedColor;
        }
    }

    if (land.status !== 'on_sale') {
        return disabledColor; // disabled
    } else {
        return normalColor; // normal color
    }
}

export const Manzana2Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }



    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1874_484)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('1'))} d="M732.486 429.952L701.807 331.69L658.234 397.05L722.704 443.735L732.486 429.952Z" fill={getFillByLot(1, selected, lands)} fillOpacity="0.64"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('2'))} d="M696.918 478.414L722.706 443.734L658.236 397.049L632.892 431.729L696.918 478.414Z" fill={getFillByLot(2, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('3'))} d="M670.683 511.763L696.916 478.417L632.891 431.731L607.547 464.633L670.683 511.763Z" fill={getFillByLot(3, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M583.538 493.978L607.548 464.633L670.684 511.763L641.339 545.109L583.538 493.978Z" fill={getFillByLot(4, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('5'))} d="M611.55 577.123L641.34 545.11L583.539 493.979L555.973 525.547L611.55 577.123Z" fill={getFillByLot(5, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('6'))} d="M582.204 606.91L611.549 577.12L555.971 525.544L527.071 555.334L582.204 606.91Z" fill={getFillByLot(6, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('7'))} d="M551.08 634.921L582.204 606.91L527.071 555.334L497.726 584.679L551.08 634.921Z" fill={getFillByLot(7, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('8'))} d="M518.622 665.156L551.079 634.922L497.725 584.68L468.38 612.691L518.622 665.156Z" fill={getFillByLot(8, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('9'))} d="M486.612 691.836L518.625 665.159L468.383 612.693L436.815 640.704L486.612 691.836Z" fill={getFillByLot(9, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('10'))} d="M475.051 702.95L486.611 691.834L436.813 640.703L398.576 673.16C418.584 694.502 448.373 702.95 451.041 702.95H475.051Z" fill={getFillByLot(10, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1874_484" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1874_484" transform="matrix(0.00125062 0 0 0.0016209 -2.94302 -1.13522)"/>
        </pattern>
        <image id="image0_1874_484" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}