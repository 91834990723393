import { ReactComponent as Icon } from "../images/arrowed-document.svg";
import { ReactComponent as Icon2 } from "../images/arrowed-document-gray.svg";

const ArrowedDocumentIcon = ({ size = "120", color, disable }) => {
  if (disable) {
    return <Icon2 width={size} height={size} fill={color} />;
  }

  return <Icon width={size} height={size} fill={color} />;
};
export default ArrowedDocumentIcon;
