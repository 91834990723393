import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./RegisterList.css";
import { useTranslation } from "react-i18next";
import {
  getUsers,
  deleteUser,
  getUsersByRole,
} from "../../../services/UsersService";
import _ from "lodash";
import { FaEdit, FaTrash, FaShoppingCart, FaUser } from "react-icons/fa";
import Modal from "../../../components/modal/Modal";
import Register from "./Register";
import RegisterDelete from "./components/registerDelete/RegisterDelete";
import Pagination from "../../../components/pagination/Pagination";
import { toast, ToastContainer } from "react-toastify";
import Loading from "../../../components/loading/Loading";
import { useAuth } from "../../../hooks/useAuth";
import SalesWizard from "../sale/SalesWizard";
import { useDispatch } from "react-redux";
import { selectClient } from "../../../features/userSlice/userSlice";
import UserDetails from "./components/UserDetails";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { roles, rolesIds } from "../../../constants/roles";
import { PrimaryButton } from "../../../components/primary/PrimaryButton";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { Selector } from "../../../components/selector/Selector";
import { capitalized } from "../../../utils";

export const initializeRoles = [
  { value: "all", label: "Todos" },
  { value: roles.ADMIN, label: capitalized(roles.ADMIN) },
  { value: roles.CLIENT, label: capitalized(roles.CLIENT) },
  { value: roles.COORDINATOR, label: capitalized(roles.COORDINATOR) },
  { value: roles.ORGANIZATION, label: capitalized(roles.ORGANIZATION) },
  { value: roles.SELLER, label: capitalized(roles.SELLER) },
];

const removeRoles = (role) => {
  if (role === roles.CLIENT) {
    return [];
  }

  if (role === roles.ADMIN) {
    return initializeRoles.filter((r) => r.value !== roles.ADMIN);
  }

  if (role === roles.COORDINATOR) {
    return initializeRoles.filter((r) => ![roles.ADMIN, roles.COORDINATOR].includes(r.value));
  }

  if (role === roles.ORGANIZATION) {
    return initializeRoles.filter((r) => ![roles.ADMIN, roles.ORGANIZATION, roles.COORDINATOR].includes(r.value));
  }

  if (role === roles.SELLER) {
    return initializeRoles.filter((r) => ![roles.ADMIN, roles.ORGANIZATION, roles.COORDINATOR, roles.SELLER].includes(r.value));
  }

  return initializeRoles;
};

const heightRegistrationForm = (type, role) => {
  if (type === "cliente") {
    return "690px";
  }
  if (type === "organizacion") {
    return "730px";
  }
  if (type === "vendedor") {
    return "615px";
  }
  return role === roles.SELLER ? "690px" : "615px";
};

export default function RegisterList() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useAuth().user;
  const [users, setUsers] = useState([]);
  const [meta, setMeta] = useState(null);
  const [first, setFirst] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showSale, setShowSale] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToShow, setUserToShow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [toggleDetails, setToggleDetails] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [searchByRol, setSearchByRol] = useState("all");
  const [type, setType] = useState("");

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const debug = searchParams.get("debug") === "true";

  const role = useMemo(() => user?.roles?.[0]?.name?.toLowerCase(), [user]);

  /**
   * Handle show form to send values to register component
   */
  const handleShowForm = useCallback((theUser) => {
    const data = {
      id: theUser.id ? theUser.id : undefined,
      name: theUser.name,
      lastName: theUser.last_name,
      countryCode: theUser.country_code,
      phone: theUser.phone,
      email: theUser.email,
      zipCode: theUser.client ? theUser.client.zip_code : undefined,
      address: theUser.client ? theUser.client.full_address : undefined,
    };
    setUserToEdit(data);
    setShowForm(true);
  }, []);

  /**
   * Show alert confirm to delete
   */
  const handleDelete = useCallback((theUser) => {
    setUserToDelete(theUser);
    setShowDelete(true);
  }, []);

  const downloadFile = useCallback((theResponse) => {
    const url = window.URL.createObjectURL(new Blob([theResponse]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `users.xlsx`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }, []);

  /**
   * Get user list in the page selected
   */
  const getUserList = useCallback(
    async (page, download = undefined) => {
      setLoading(true);
      const theUsers = await getUsers(page, download);
      setLoading(false);
      if (!download) {
        if (theUsers.data.data) {
          theUsers.data.data.forEach((theUser) => {
            theUser.show = false;
          });
          setUsers(theUsers.data.data);
          setMeta(theUsers.data.meta);
        }
      } else {
        downloadFile(theUsers.data);
      }
    },
    [downloadFile]
  );

  /**
   * Get user list in the page selected
   */
  const getUserListByRole = useCallback(
    async (page, role, download = undefined) => {
      setLoading(true);
      const theUsers = await getUsersByRole(page, role, download);
      setLoading(false);
      if (!download) {
        if (theUsers.data.data) {
          theUsers.data.data.forEach((theUser) => {
            theUser.show = false;
          });
          setUsers(theUsers.data.data);
          setMeta(theUsers.data.meta);
        }
      } else {
        downloadFile(theUsers.data);
      }
    },
    [downloadFile]
  );

  /**
   * Handle page selected on pagination
   */
  const onPageSelected = useCallback(
    (pageSelected) => {
      if (meta && meta.current_page !== pageSelected) {
        getUserList(pageSelected);
      }
    },
    [getUserList, meta]
  );

  /**
   * Handle close modal and reload users list
   */
  const handleCloseModal = useCallback(() => {
    getUserList(1);
    setShowForm(false);
    setUserToEdit(null);
  }, [getUserList]);

  const handleShowUserDetails = useCallback((theUser) => {
    setUserToShow(theUser);
    setToggleDetails(true);
  }, []);

  /**
   * Handle cancel delete section
   */
  const handleCancelDelete = useCallback(() => {
    setUserToDelete(null);
    setShowDelete(false);
  }, []);

  /**
   * Handle confirm delete
   */
  const handleConfirmDelete = useCallback(async () => {
    try {
      setLoading(true);
      const response = await deleteUser(userToDelete.id);
      setLoading(false);
      if (response.status === 200) {
        toast.success(t("registerPage.delete"));
        setShowDelete(false);
        setUserToDelete(null);
        getUserList(1);
      }
    } catch (error) {
      toast.error(error.message);
    }
  }, [getUserList, t, userToDelete]);

  /**
   * Handle add user to show form
   */
  const handleAdd = useCallback(() => {
    setShowForm(true);
  }, []);

  /**
   * Handle success in form
   */
  const handleSuccess = useCallback(() => {
    getUserList(1);
  }, [getUserList]);

  const handleRoleSelected = useCallback(
    (e) => {
      const roleSelected = e.target.value;
      setSearchByRol(roleSelected);
      if (roleSelected === "all") {
        getUserList(1);
      } else {
        getUserListByRole(1, roleSelected);
      }
    },
    [getUserList, getUserListByRole]
  );

  const handleArrowClick = useCallback(
    (userSelected, show) => {
      const theIndex = users.findIndex(
        (theUser) => theUser.id === userSelected.id
      );
      const currentUsers = [...users];
      currentUsers[theIndex].show = show;
      setUsers(currentUsers);
    },
    [users]
  );

  const handleDownload = useCallback(async () => {
    if (searchByRol === "all") {
      getUserList(1, "excel");
    } else {
      getUserListByRole(1, searchByRol, "excel");
    }
  }, [getUserList, getUserListByRole, searchByRol]);

  const navigateToProfile = useCallback(
    (theUser) => {
      const userRole = theUser?.roles?.[0]?.name?.toLowerCase();
      const userId = theUser.id;
      //dispatch(selectClient(theUser));

      /*if (userRole === roles.CLIENT) {
        return navigate(`/admin/details/${userId}`);
      }*/

      return navigate(`/admin/user-process/${userId}`);
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    if (first) {
      setFirst(false);
      getUserList(1);
      if (user.roles[0].name.toLowerCase() !== roles.CLIENT) {
        setCanDelete(true);
      }
    }
  }, [first, getUserList, user]);

  return (
    <div className="RegisterList container mt-10">
      {loading && <Loading />}
      <div className="flex flex-col justify-center lg:flex-row lg:justify-between items-center mb-10">
        <div className="flex flex-row items-center">
          <h1 className="title mr-4">{t("adminSections.users")}</h1>
          {user &&
            user.roles &&
            [
              "Super Admin",
              "Organizacion",
              "Administrador",
              "Vendedor",
              "Coordinador",
            ].includes(user.roles[0].name) && (
              <div className="w-full lg:w-1/6 ml-10" style={{ width: "200px" }}>
                <Selector
                  label=""
                  name="role"
                  defaultValue={removeRoles(role)[0]}
                  options={removeRoles(role)}
                  onChange={(value) => {
                    handleRoleSelected({ target: { value: value?.value } });
                  }}
                  isNewVersion
                />
              </div>
            )}
        </div>
        <PrimaryButton className="shadow max-lg:mt-10 mt-0" onClick={handleAdd}>
          {t("registerPage.add")}
        </PrimaryButton>
      </div>

      <div className="w-full">
        {/* Vista movil */}
        <div className="block lg:hidden">
          {_.map(users, (theUser, index) => (
            <div key={index} className="card-info">
              <div className="flex justify-between px-4">
                <div
                  className="text-sm text-black-50 hover-links"
                  onClick={() => navigateToProfile(theUser)}
                >
                  {theUser.name} {theUser.last_name}
                </div>
                <div>
                  {!theUser.show && (
                    <BiSolidDownArrow
                      onClick={() => handleArrowClick(theUser, true)}
                    />
                  )}
                  {theUser.show && (
                    <BiSolidUpArrow
                      onClick={() => handleArrowClick(theUser, false)}
                    />
                  )}
                </div>
              </div>
              {theUser.show && (
                <div>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="field-title text-black-50">
                      {t("registerPage.phone")}
                    </div>
                    <div className="text-left field-value text-black-50">
                      {theUser.phone}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="field-title">{t("registerPage.email")}</div>
                    <div className="text-left field-value text-black-50">
                      {theUser.email}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="field-title">{t("global.options")}</div>
                    {debug && canDelete && (
                      <div className="text-left field-value">
                        <div className="flex justify-end gap-2">
                          <div
                            className={`${
                              theUser.client && theUser.client.lands.length > 0
                                ? "cursor-pointer text-primary"
                                : ""
                            }`}
                          >
                            <FaUser
                              onClick={() => {
                                if (theUser.client.lands.length > 0) {
                                  dispatch(selectClient(theUser));
                                  navigate("/admin/details");
                                }
                              }}
                            />
                          </div>
                          {theUser.roles[0].id === rolesIds.CLIENT && (
                            <div className="cursor-pointer text-primary">
                              <FaShoppingCart
                                onClick={() => {
                                  dispatch(selectClient(theUser));
                                  navigate("/admin/categories");
                                }}
                              />
                            </div>
                          )}
                          <div
                            className="cursor-pointer text-primary"
                            onClick={() => handleShowForm(theUser)}
                          >
                            <FaEdit />
                          </div>
                          {canDelete && (
                            <div
                              className="cursor-pointer text-primary"
                              onClick={() => handleDelete(theUser)}
                            >
                              <FaTrash />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div
                      className="text-left field-value cursor-pointer underline"
                      onClick={() => handleDelete(theUser)}
                      style={{ color: "#024E82" }}
                    >
                      {t("registerPage.removeUser")}
                    </div>
                  </div>
                  <div>
                    {/* Si rol es super admin puede ver otros roles */}
                    {user.roles[0].id === rolesIds.SUPER_ADMIN && (
                      <div className="grid grid-cols-2 gap-4 my-2">
                        <div className="field-title">
                          {t("registerPage.role")}
                        </div>
                        <div className="text-left field-value">
                          {theUser.roles.length > 0
                            ? theUser.roles[0].name
                            : "n/a"}
                        </div>
                      </div>
                    )}

                    {user.roles[0].id === rolesIds.CLIENT && (
                      <div className="grid grid-cols-2 gap-4 my-2">
                        <div>{t("lotLandFormPage.title")}</div>
                        <div className="p-2">
                          {theUser.client?.lands?.length > 0
                            ? theUser.client.lands.map(
                                (l) => `${l.identifying_label}, `
                              )
                            : "Sin terrenos"}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* vista web */}
        <div className="hidden lg:block overflow-hidden rounded-lg shadow-lg mt-5">
          <table className="w-full  table-auto">
            <thead className="text-black bg-header">
              <tr>
                <td className="p-2">{t("registerPage.user")}</td>
                <td className="p-2">{t("registerPage.name")}</td>
                <td className="p-2">{t("registerPage.lastName")}</td>
                <td className="p-2">{t("registerPage.phone")}</td>
                <td className="p-2">{t("registerPage.email")}</td>
                {user.roles[0].id === rolesIds.SUPER_ADMIN && (
                  <td className="p-2">{t("registerPage.role")}</td>
                )}
                {user.roles[0].id === rolesIds.SELLER && (
                  <td className="p-2">{"Terreno"}</td>
                )}
                {debug && canDelete && <td>{t("registerPage.status")}</td>}
                {canDelete && <td></td>}
              </tr>
            </thead>
            <tbody className="text-cell ">
              {_.map(users, (theUser, index) => (
                <tr
                  onClick={() => navigateToProfile(theUser)}
                  className={`border-b border-white text-sm ${
                    index % 2 === 0 ? "bg-white" : "bg-header"
                  }`}
                  key={theUser.id}
                >
                  
                  <td className="p-2 text-primary">{theUser.id}</td>
                  <td className="p-2 text-black-50">{theUser.name}</td>
                  <td className="p-2 text-black-50">{theUser.last_name}</td>
                  <td className="p-2 text-black-50">
                    ({theUser.country_code}) {theUser.phone}
                  </td>
                  <td className="p-2 text-black-50">{theUser.email}</td>
                  {/* Si rol es super admin puede ver otros roles */}
                  {user.roles[0].id === rolesIds.SUPER_ADMIN && (
                    <td className="p-2 text-black-50">
                      {theUser.roles.length > 0 ? theUser.roles[0].name : "n/a"}
                    </td>
                  )}
                  {user.roles[0].id === rolesIds.SELLER && (
                    <td className="p-2 text-black-50">
                      {theUser.client?.lands?.length > 0
                        ? theUser.client.lands.map(
                            (l) => `${l.identifying_label}, `
                          )
                        : "Sin terrenos"}
                    </td>
                  )}

                  {debug && canDelete && (
                    <td className="p-2">
                      <div className="flex gap-2">
                        <div
                          className={`${
                            theUser.client && theUser.client.lands.length > 0
                              ? "cursor-pointer text-primary"
                              : ""
                          }`}
                        >
                          <FaUser
                            onClick={(e) => {
                              e.stopPropagation();
                              if (theUser.client.lands.length > 0) {
                                dispatch(selectClient(theUser));
                                navigate("/admin/details");
                              }
                            }}
                          />
                        </div>
                        {theUser.roles[0] &&
                          theUser.roles[0].id === rolesIds.CLIENT && (
                            <div className="cursor-pointer text-primary">
                              <FaShoppingCart
                                onClick={(e) => {
                                  e.stopPropagation();
                                  dispatch(selectClient(theUser));
                                  navigate("/admin/categories");
                                }}
                              />
                            </div>
                          )}
                        <div
                          className="cursor-pointer text-primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleShowForm(theUser);
                          }}
                        >
                          <FaEdit />
                        </div>
                        {canDelete && (
                          <div
                            className="cursor-pointer text-primary"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDelete(theUser);
                            }}
                          >
                            <FaTrash />
                          </div>
                        )}
                      </div>
                    </td>
                  )}
                  {canDelete && (
                    <td className="p-2 text-black-50">
                      <div
                        className="cursor-pointer text-primary underline"
                        onClick={(e) => {
                          e.stopPropagation(); 
                          handleDelete(theUser);
                        }}
                      >
                        {t("registerPage.removeUser")}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-center w-full mt-4 mb-4">
          <Pagination meta={meta} onPageSelected={onPageSelected} />
        </div>
      </div>

      {showForm && (
        <Modal
          onCloseModal={handleCloseModal}
          type="medium"
          title={userToEdit ? t("registerPage.edit") : t("registerPage.add")}
          style={{ width: "600px", minHeight: heightRegistrationForm(type, role) }}
        >
          <Register
            userToEdit={userToEdit}
            onSuccess={handleSuccess}
            setType={setType}
            type={type}
          />
        </Modal>
      )}

      {toggleDetails && (
        <Modal
          onCloseModal={() => setToggleDetails(false)}
          type="medium"
          title={t("userDetails.title")}
        >
          <UserDetails user={userToShow} />
        </Modal>
      )}

      {showDelete && (
        <Modal
          onCloseModal={() => setShowDelete(false)}
          type="small"
          style={{ width: "600px", minHeight: "100px", maxHeight: "400px" }}
          modalHeader={false}
        >
          <RegisterDelete
            userName={
              capitalized(userToDelete?.name || "") +
              " " +
              capitalized(userToDelete?.last_name || "")
            }
            onCancel={handleCancelDelete}
            onConfirm={handleConfirmDelete}
          />
        </Modal>
      )}

      {showSale && (
        <Modal
          onCloseModal={() => {
            setShowSale(false);
          }}
          type="medium"
        >
          <SalesWizard
            toggle={showSale}
            toggleHandler={(b) => setShowSale(b)}
          />
        </Modal>
      )}

      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: "500px" }}
      />
    </div>
  );
}
