import { useSelector } from "react-redux";
import { PrimaryButton } from "../../../../../components/primary/PrimaryButton";
import InitialPaymentIcon from "../../../../../assets/icons/InitialPaymentIcon";
import Modal from "../../../../../components/modal/Modal";
import { useState } from "react";
import CopyIcon from "../../../../../assets/icons/Copy";
import { roles } from "../../../../../constants/roles";

const handleCopy = (link) => {
  navigator.clipboard.writeText(link);
  alert("¡Enlace copiado!");
};
export const InitialPayment = ({ onSubmit = () => {}, role }) => {
  const [showModal, setShowModal] = useState(false);

  const { latestProgress } = useSelector((state) => state.payment);

  return (
    <>
      <div className="flex flex-col justify-center">
        <div className="flex items-center justify-center mt-4 mb-3">
          <div className="">
            <InitialPaymentIcon size="100" />
          </div>
        </div>
        {role === roles.CLIENT ? (
          <label className="text-center text-[#1E5F6B] mb-2">
            Realiza tu apartado solicitando
            <br /> link de pago
          </label>
        ) : null}

        <div className="w-full text-center">
          {/*<a href={latestProgress.data.link} target="_blank" rel="noreferrer">
      <PrimaryButton>
          {t('userDetails.pay')}
        </PrimaryButton>
        
        </a>*/}
          <PrimaryButton
            className="shadow-md"
            onClick={() => {
              if (role === roles.CLIENT) {
                // TODO: Send email
                setShowModal(true);
                return;
              }
              setShowModal(true);
            }}
          >
            Solicitar
          </PrimaryButton>
        </div>
      </div>
      {showModal && (
        <Modal
          type="small"
          onCloseModal={() => setShowModal(false)}
          title="Pago"
          style={{ width: "590px", height: "320px" }}
        >
          <div
            className="flex flex-col justify-center items-center m-2"
            style={{ height: "80%" }}
          >
            <label
              className="primary-btn-text"
              style={{ color: "#7E7E7E", fontSize: "15px" }}
            >
              Para proceder al pago del pago inicial envíe el siguiente enlace:
            </label>

            <div className="flex mt-4 w-full justify-center">
              <button
                onClick={() => {
                  handleCopy(latestProgress.data.link);
                }}
                className="mr-2 bg-transparent hover:text-blue-600 focus:outline-none"
              >
                {/* Puedes reemplazar esto con un ícono SVG */}
                <CopyIcon size="25" />
              </button>
              <div
                className="flex items-center rounded-md px-4 p-2 shadow-sm"
                style={{
                  width: "90%",
                  borderColor: "#D1DCEB",
                  borderWidth: "1px",
                }}
              >
                <input
                  style={{ color: "#8598AD", width: "100%" }}
                  type="text"
                  value={latestProgress.data.link}
                  readOnly
                  className="bg-transparent border-none outline-none text-gray-600 text-sm "
                />
              </div>
            </div>
            <PrimaryButton
              onClick={() => {
                // Enviar al correo del usuario
                setShowModal(false);
                onSubmit();
              }}
              style={{ position: "absolute", bottom: "45px" }}
            >
              Enviar
            </PrimaryButton>
          </div>
        </Modal>
      )}
    </>
  );
};
