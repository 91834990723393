import { getFillByLot } from "./Manzana2Div1";

export const Manzana17Div1 = ({ lands, landSelector, selected }) => {
  const findLandById = (id) => {
    return lands.filter((l) => l.id === id)[0];
  };

  const findLandByLot = (lot) => {
    return lands.filter((l) => l.lote === lot)[0];
  };

  const getFill = (id) => {
    const land = lands.filter((l) => l.id === id)[0];
    const disabledColor = "rgba(223, 250, 255, 0.48)";
    const normalColor = "#0096B3";
    const selectedColor = "#DFFAFF";

    if (!land) {
      return disabledColor; // disabled
    }

    if (selected) {
      if (id === selected.id) {
        return selectedColor;
      }
    }

    if (land.status !== "on_sale") {
      return disabledColor; // disabled
    } else {
      return normalColor; // normal color
    }
  };

  if (!lands) {
    return <></>;
  }

  return (
    <svg
      width="1219"
      height="941"
      viewBox="0 0 1219 941"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1219" height="941" fill="url(#pattern0_1878_6504)" />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("4"))}
        d="M596.482 489.934L549.111 503.553L579.902 577.57L624.905 559.214L596.482 489.934Z"
        fill={getFillByLot(4, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("5"))}
        d="M643.853 475.72L596.482 489.931L624.904 559.804L674.052 540.263L643.853 475.72Z"
        fill={getFillByLot(5, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("3"))}
        d="M549.115 503.552L504.112 517.171L536.68 595.926L579.906 577.569L549.115 503.552Z"
        fill={getFillByLot(3, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("1"))}
        d="M436.607 590.011L454.371 630.276L495.82 612.512L462.069 529.612L422.987 542.047L436.607 590.011Z"
        fill={getFillByLot(1, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("2"))}
        d="M536.678 595.934L495.821 612.514L462.069 529.615L504.111 517.18L536.678 595.934Z"
        fill={getFillByLot(2, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("10"))}
        d="M490.487 704.284L531.937 687.112L499.962 610.726L454.367 630.266L482.197 700.139L490.487 704.284Z"
        fill={getFillByLot(10, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("8"))}
        d="M575.759 668.171L620.762 649.222L589.378 574.021L543.783 592.969L575.759 668.171Z"
        fill={getFillByLot(8, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("9"))}
        d="M531.944 687.115L575.762 668.167L543.787 592.965L499.968 610.729L531.944 687.115Z"
        fill={getFillByLot(9, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("6"))}
        d="M673.466 539.672L708.995 610.729L663.4 630.861L633.793 556.252L673.466 539.672Z"
        fill={getFillByLot(6, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("7"))}
        d="M620.166 649.224L663.392 630.867L633.785 556.258L589.375 574.022L620.166 649.224Z"
        fill={getFillByLot(7, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <defs>
        <pattern
          id="pattern0_1878_6504"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1878_6504"
            transform="matrix(0.00166555 0 0 0.00215869 -3.79566 -2.70598)"
          />
        </pattern>
        <image
          id="image0_1878_6504"
          width="4096"
          height="2304"
          href="/mz1.png"
        />
      </defs>
    </svg>
  );
};
