import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Selector } from "../../../components/selector/Selector";
import { PrimaryButton } from "../../../components/primary/PrimaryButton";
import { FaArrowLeft } from "react-icons/fa";
import { te } from "date-fns/locale";
import { BiAlignJustify, BiBorderRadius } from "react-icons/bi";
import AvatarDetails from "../../../assets/icons/AvatarDetails";
import { useAuth } from "../../../hooks/useAuth";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  getLandsByUser,
  getUserByClientId,
  getUserById,
} from "../../../services/UsersService";
import { useDispatch } from "react-redux";
import {
  removeLand,
  selectClient,
} from "../../../features/userSlice/userSlice";
import { roles } from "../../../constants/roles";
import { formattedPrice } from "../../../utils";

const Items = ({ title, description, className }) => {
  return (
    <div className={`flex flex-col justify-start items-start ${className}`}>
      <p className="text-base" style={{ color: "#024E82" }}>
        {title}
      </p>
      <p className="text-sm">{description}</p>
    </div>
  );
};

const UserProcess = () => {
  // const { user } = useAuth().user;

  const navigate = useNavigate();
  const { id: clientId } = useParams();

  const [user, setUser] = useState(null);
  const [landId, setLandId] = useState(null);
  const [lands, setLands] = useState([]);
  const [selectedUserRole, setSelectedUserRole] = useState("");

  const dispatch = useDispatch();

  //const lands = useMemo(() => user?.client?.lands || [], [user?.client?.lands]);

  const landsList = useMemo(
    () => lands.map((l) => ({ value: l.id, label: l.identifying_label })),
    [lands]
  );

  const selectedLand = useMemo(() => {
    if (!landId) {
      return null;
    }

    return lands.find((l) => l.id === landId);
  }, [landId, lands]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const navigateToProgress = useCallback(
    (clientId) => {
      if (clientId) {
        getUserByClientId({ clientId: clientId }).then((response) => {
          const { data: theUser } = response?.data;
          dispatch(selectClient(theUser));
          navigate(`/admin/details/${user?.id}/${theUser?.id}`);
        });
      } else {
        getUserById({ id: user?.id }).then((response) => {
          const { data: theUser } = response?.data;
          const userRole = theUser?.roles?.[0]?.name?.toLowerCase();
          if (userRole === roles.CLIENT) {
            dispatch(selectClient(theUser));
            navigate(`/admin/details/${user?.id}/${theUser?.id}`);
          }
        });
      }
    },
    [dispatch, navigate, user?.id]
  );

  useEffect(() => {
    getUserById({ id: clientId }).then((response) => {
      const { data } = response?.data;
      setSelectedUserRole(data?.roles?.[0]?.name || "");
      setUser(data);
    });
  }, [clientId]);

  useEffect(() => {
    getLandsByUser({ user_id: clientId, role: selectedUserRole }).then(
      (response) => {
        setLands(response.data?.lands);
      }
    );
  }, [clientId, selectedUserRole]);

  useEffect(() => {
    dispatch(selectClient({}));
    dispatch(removeLand());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user) {
    return null;
  }

  return (
    <div className="p-5 DashboardSeller max-w-screen-xl">
      <div className="flex flex-row justify-start items-center w-full mt-10">
        <PrimaryButton
          className="mr-5 shadow-md"
          style={{
            right: 10,
            width: 50,
            justifyItems: "center",
            borderRadius: ".2rem",
          }}
          onClick={goBack}
        >
          <FaArrowLeft size={15} />
        </PrimaryButton>
        <h3 className="title text-2xl">Usuarios</h3>
      </div>

      <div
        className="bg-white rounded-xl mt-10"
        style={{ backgroundColor: "#F1F3F4" }}
      >
        <div className="flex items-center p-10 pb-0 pl-9">
          <div className="m-4 ml-0">
            <AvatarDetails />
          </div>
          <div className="w-full flex flex-col md:flex-row justify-between items-start">
            <div>
              <h1 className="userTitle">{`${user.name} ${user.last_name}`}</h1>
            </div>
            <div className="flex flex-col items-center text-primary mr-0 md:mr-40 pr-0 md:pr-10">
              <strong className="text-primary ">{`N ${user.id || ""}`}</strong>
              {/*<strong className="text-primary">{`ROLE ${user.id || ""}`}</strong>*/}
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-start items-center w-full p-10 pt-0 gap-5 md:gap-20">
          <div>
            <Items
              className="mt-8 mr-5"
              title="Código de país"
              description={user?.country_code}
            />
            <Items
              className="mt-8 mr-5"
              title="Código postal"
              description={user?.client?.zip_code}
            />
            <Items
              className="mt-8 mr-5"
              title="Correo electrónico"
              description={user?.email}
            />
          </div>
          <div>
            <Items
              className="mt-8 mr-5"
              title="Telefono"
              description={user?.phone}
            />
            <Items
              className="mt-8 mr-5"
              title="Dirección"
              description={user?.client?.full_address}
            />
            <div style={{ height: "5rem" }} />
          </div>
        </div>
      </div>

      <div className="rounded-xl" style={{ backgroundColor: "#F1F3F4" }}>
        <div className="flex flex-col md:flex-row justify-start items-center w-full mt-10 pl-0 md:pl-10 pt-10">
          <h3 className="title text-2xl mr-0 md:mr-10">Terrenos</h3>
          <div className="w-1/5" style={{ minWidth: "10rem" }}>
            <Selector
              name="land"
              options={landsList}
              defaultValue={{ value: 0, label: "Seleccionar" }}
              onChange={(e) => setLandId(e.value)}
              isNewVersion
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-start items-center md:items-end w-full p-10 gap-5 ">
          <div
            className="bg-gray rounded-md"
            style={{ minWidth: "15rem", height: "15rem" }}
          >
            <p></p>
          </div>
          <div className="show-button">
            <PrimaryButton
              disabled={landsList.length > 0 && !selectedLand}
              onClick={() => navigateToProgress(selectedLand?.client_id)}
            >
              Progreso
            </PrimaryButton>
          </div>

          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Items
                    className="mt-0"
                    title="Nombre"
                    description={selectedLand?.identifying_label || ""}
                  />
                </td>
                <td>
                  <Items
                    className="mt-0"
                    title="Medidas"
                    description={selectedLand?.surface || ""}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Items
                    className="mt-8 mr-5"
                    title="Adelanto"
                    description={formattedPrice(selectedLand?.advance)}
                  />
                </td>
                <td>
                  <Items
                    className="mt-8 mr-5"
                    title="Ubicación"
                    description={selectedLand?.location || ""}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Items
                    className="mt-8 mr-5"
                    title="Interés"
                    description={selectedLand?.advance ? "%0":""}
                  />
                </td>
                <td>
                  <Items
                    className="mt-8 mr-5"
                    title="Descripción"
                    description={selectedLand?.description || ""}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          {/*<div className="flex flex-col md:flex-row justify-start items-center w-full">
            <div>
              <Items
                title="Nombre"
                description={selectedLand?.identifying_label || ""}
              />
              <Items
                title="Adelanto"
                description={selectedLand?.advance || ""}
              />
            </div>
            <div>
              <Items title="Interés" description="" />
              <Items
                title="Medidas"
                description={selectedLand?.surface || ""}
              />
            </div>
            <div>
              <Items
                title="Ubicación"
                description={selectedLand?.location || ""}
              />
              <Items
                title="Descripción"
                description={selectedLand?.description || ""}
              />
            </div>
          </div>*/}
        </div>
        <div className=" flex flex-col md:flex-row justify-start items-end w-full p-10 gap-5 progress-button">
          <PrimaryButton
            disabled={landsList.length > 0 && !selectedLand}
            onClick={() => navigateToProgress(selectedLand?.client_id)}
          >
            Progreso
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default UserProcess;
