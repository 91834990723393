import { getFillByLot } from "./Manzana2Div1";

export const Manzana15Div1 = ({ lands, landSelector, selected }) => {
  const findLandById = (id) => {
    return lands.filter((l) => l.id === id)[0];
  };

  const findLandByLot = (lot) => {
    return lands.filter((l) => l.lote === lot)[0];
  };

  const getFill = (id) => {
    const land = lands.filter((l) => l.id === id)[0];
    const disabledColor = "rgba(223, 250, 255, 0.48)";
    const normalColor = "#0096B3";
    const selectedColor = "#DFFAFF";

    if (!land) {
      return disabledColor; // disabled
    }

    if (selected) {
      if (id === selected.id) {
        return selectedColor;
      }
    }

    if (land.status !== "on_sale") {
      return disabledColor; // disabled
    } else {
      return normalColor; // normal color
    }
  };

  if (!lands) {
    return <></>;
  }

  return (
    <svg
      width="1219"
      height="941"
      viewBox="0 0 1219 941"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1219" height="941" fill="url(#pattern0_1882_2025)" />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("4"))}
        d="M442.543 685.172L490.116 673.919L459.424 559.846L412.874 571.611L442.543 685.172Z"
        fill={getFillByLot(4, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("3"))}
        d="M395.994 698.471L442.543 685.171L412.874 571.609L366.324 583.886L395.994 698.471Z"
        fill={getFillByLot(3, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("2"))}
        d="M319.775 596.166L366.325 583.889L395.994 698.473L348.421 710.238L319.775 596.166Z"
        fill={getFillByLot(2, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("1"))}
        d="M292.663 726.096L348.421 710.239L319.774 596.166L283.455 605.885L292.663 726.096Z"
        fill={getFillByLot(1, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("5"))}
        d="M459.424 559.845L506.486 546.545L536.155 661.641L489.605 673.918L459.424 559.845Z"
        fill={getFillByLot(5, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("6"))}
        d="M536.155 661.642L583.216 649.877L551.501 533.758L506.486 546.546L536.155 661.642Z"
        fill={getFillByLot(6, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("7"))}
        d="M551.501 533.755L604.189 520.967L623.116 622.763L627.209 638.62L583.216 649.874L551.501 533.755Z"
        fill={getFillByLot(7, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("8"))}
        d="M604.189 520.455L613.397 573.655L721.843 539.382L708.031 493.344L604.189 520.455Z"
        fill={getFillByLot(8, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("9"))}
        d="M613.397 573.144L623.116 622.252L736.677 587.467L721.843 539.383L613.397 573.144Z"
        fill={getFillByLot(9, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("10"))}
        d="M635.393 668.292L749.977 634.53L736.677 587.469L623.116 622.253L635.393 668.292Z"
        fill={getFillByLot(10, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <defs>
        <pattern
          id="pattern0_1882_2025"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1882_2025"
            transform="matrix(0.00143884 0 0 0.00186485 -2.80112 -1.57167)"
          />
        </pattern>
        <image
          id="image0_1882_2025"
          width="4096"
          height="2304"
          href="/mz1.png"
        />
      </defs>
    </svg>
  );
};
