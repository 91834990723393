import ReactEcharts from "echarts-for-react";

const defaultData = {
  column1: {
    title: "column1",
    value: 0,
  },
  column2: {
    title: "column1",
    value: 0,
  },
};

const SvgChart = ({ title, data = defaultData }) => {
  const option = {
    title: {
      text: title,
      show: true,
      top: 10,
      left: 10,
      textStyle: {
        fontSize: 16,
        fontWeight: "400",
        color: "#4C4C4C",
      },
    },
    grid: { top: 70, right: 20, bottom: 40, left: 20 },
    tooltip: {},
    legend: {
      show: false,
      data: [""],
    },
    xAxis: {
      data: [data.column1.title, data.column2.title],
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#9DAAB9",
          width: 0.5,
        },
      },
      axisLabel: {
        show: true,
        interval: 0,
        rotate: 0,
        fontSize: 10,
        fontWeight: "bold",
        color: "#9DAAB9",
        formatter: function (value) {
          return value.length > 30 ? value.split(" ").join("\n") : value;
        },
      },
    },
    yAxis: {
      splitLine: {
        show: false,
      },
      axisLabel: {
        show: false,
      },
    },
    series: [
      {
        type: "bar",
        data: [data.column1.value, data.column2.value],
        itemStyle: {
          color: function (params) {
            const colors = ["#D9D96B", "#84B722"];
            return colors[params.dataIndex];
          },
        },
        label: {},
      },
    ],
  };

  return (
    <ReactEcharts
      option={option}
      style={{
        height: "100%",
        width: "100%",
        borderRadius: 12,
        backgroundColor: "#FFF",
      }}
      opts={{ renderer: "svg" }}
    />
  );
};

export default SvgChart;
