import api from "../api/axios";
import { endpoints } from "../api/endpoints";

export const uploadFiles = (client, land, files, documentType) => {

  try {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('contracts[]', file);
    });

    formData.append('client_id', client)
    formData.append('land_id', land)
    formData.append('document_type', documentType)

    return api.post('api/files', formData);
  } catch (error) {
      return error.message;
  }
};

export const getFiles = (params) => {
  try {
    return api.get(endpoints.files, {params})
  } catch (error) {
    return error;
  }
}