import React from 'react'
import "./RegisterDelete.css"
import { useTranslation } from 'react-i18next';
import TrashIcon from '../../../../../assets/icons/TrashIcon';
import { PrimaryButton } from '../../../../../components/primary/PrimaryButton';

export default function RegisterDelete(
  {onCancel, onConfirm, userName = ""}
) {

  const { t } = useTranslation();

  return (
    <div className='RegisterDelete p-12'>
      <div
        className='flex flex-col justify-center items-center w-full'
      >
        <div className='mt-8'>
        <TrashIcon size='120'/>
        </div>
        <div className='mt-2 text-2xl text-center'>
          {`${t('registerDeletePage.description')} ${userName}?` }
        </div>
        <div className='flex justify-center p-4 w-full align-bottom'>
            <PrimaryButton className='mr-10' onClick={onCancel}>
            { t('global.cancel') }
            </PrimaryButton>
            <PrimaryButton onClick={onConfirm}>
            { t('global.confirm') }
            </PrimaryButton>
        </div>
      </div>
    </div>
  )
}
