import { useCallback } from "react";

export const Manzana1Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const getFill = useCallback((id, selected2) => {
        const land = lands.find(l => l.id === id);
        const disabledColor = "#6F8789";
        const normalColor = "#0096B3";
        const selectedColor = "#5AE5F4";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }, [lands, selected])

    if (!lands) {
        return <></>
    }

    return (
        <svg preserveAspectRatio="none" style={{width: '100%', height: '100vh'}} viewBox="0 0 1220 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1220" height="941" fill="url(#pattern0)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(1))} d="M499.5 125L471 159L495.865 225.63L527.014 213.608L499.5 125Z" fill={getFill(1)} fillOpacity="0.64"/>
        <path d="M496.156 224.982L471.57 159.098L499.294 126.024L526.398 213.31L496.156 224.982Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(2))} d="M495.865 225.631L441.487 246.535L428 213.5L471 159L495.865 225.631Z" fill={getFill(2)} fillOpacity="0.64"/>
        <path d="M470.842 160.007L495.223 225.342L441.765 245.893L428.573 213.581L470.842 160.007Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(4))} d="M547.353 280.111L527.015 213.607L484.305 230.071L508.389 294.95L547.353 280.111Z" fill={getFill(4)} fillOpacity="0.64"/>
        <path d="M508.682 294.304L484.945 230.36L526.693 214.267L546.738 279.811L508.682 294.304Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(3))} d="M441.487 246.535L484.304 230.072L508.389 294.951L466.535 310.982L441.487 246.535Z" fill={getFill(3)} fillOpacity="0.64"/>
        <path d="M466.821 310.337L442.135 246.822L484.012 230.72L507.748 294.661L466.821 310.337Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(6))} d="M466.535 310.981L491.583 379.326L531.724 363.188L505.82 296.034L466.535 310.981Z" fill={getFill(6)} fillOpacity="0.64"/>
        <path d="M491.875 378.67L467.175 311.272L505.532 296.678L531.08 362.908L491.875 378.67Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(5))} d="M547.353 280.112L568.333 348.457L531.725 363.188L505.82 296.034L547.353 280.112Z" fill={getFill(5)} fillOpacity="0.64"/>
        <path d="M567.72 348.165L532.008 362.535L506.467 296.321L547.032 280.77L567.72 348.165Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(7))} d="M491.582 379.327L504.213 414.745L579.785 385.717L568.331 348.458L491.582 379.327Z" fill={getFill(7)} fillOpacity="0.64"/>
        <path d="M492.214 379.612L568.013 349.125L579.17 385.418L504.512 414.095L492.214 379.612Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(8))} d="M504.215 414.745L516.739 450.813L591.133 422.868L579.787 385.717L504.215 414.745Z" fill={getFill(8)} fillOpacity="0.64"/>
        <path d="M504.846 415.038L579.465 386.376L590.518 422.565L517.043 450.165L504.846 415.038Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(9))} d="M516.738 450.813L529.583 488.289L602.693 460.561L591.133 422.868L516.738 450.813Z" fill={getFill(9)} fillOpacity="0.64"/>
        <path d="M517.369 451.11L590.811 423.523L602.078 460.26L529.889 487.639L517.369 451.11Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(10))} d="M529.583 488.289L542.428 526.524L614.146 498.038L602.693 460.561L529.583 488.289Z" fill={getFill(10)} fillOpacity="0.64"/>
        <path d="M530.21 488.586L602.371 461.218L613.534 497.743L542.734 525.864L530.21 488.586Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(11))} d="M542.429 526.524L554.953 564.001L626.35 537.789L614.147 498.038L542.429 526.524Z" fill={getFill(11)} fillOpacity="0.64"/>
        <path d="M543.053 526.814L613.828 498.703L625.733 537.483L555.264 563.354L543.053 526.814Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(12))} d="M567.69 603.101L554.952 564L626.349 537.788L638.017 576.131L567.69 603.101Z" fill={getFill(12)} fillOpacity="0.64"/>
        <path d="M637.403 575.831L568.003 602.446L555.577 564.303L626.025 538.44L637.403 575.831Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(13))} d="M567.689 603.102L580.535 642.853L650.433 616.641L638.016 576.132L567.689 603.102Z" fill={getFill(13)} fillOpacity="0.64"/>
        <path d="M568.311 603.399L637.695 576.79L649.817 616.338L580.849 642.201L568.311 603.399Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(14))} d="M580.534 642.852L593.272 682.711L662.743 656.499L650.433 616.64L580.534 642.852Z" fill={getFill(14)} fillOpacity="0.64"/>
        <path d="M581.155 643.153L650.112 617.295L662.126 656.198L593.588 682.058L581.155 643.153Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(15))} d="M593.273 682.711L605.797 722.462L674.84 696.034L662.744 656.5L593.273 682.711Z" fill={getFill(15)} fillOpacity="0.64"/>
        <path d="M593.892 683.012L662.422 657.155L674.225 695.734L606.115 721.805L593.892 683.012Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(16))} d="M605.797 722.462L618.214 762.213L687.149 736.218L674.839 696.034L605.797 722.462Z" fill={getFill(16)} fillOpacity="0.64"/>
        <path d="M606.414 722.761L674.518 696.692L686.533 735.916L618.533 761.558L606.414 722.761Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(17))} d="M618.214 762.213L630.631 802.614L699.459 776.51L687.149 736.218L618.214 762.213Z" fill={getFill(17)} fillOpacity="0.64"/>
        <path d="M618.83 762.515L686.827 736.874L698.844 776.209L630.952 801.957L618.83 762.515Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(18))} d="M630.632 802.615L642.514 841.933L711.663 816.154L699.46 776.511L630.632 802.615Z" fill={getFill(18)} fillOpacity="0.64"/>
        <path d="M631.245 802.917L699.139 777.168L711.046 815.85L642.838 841.278L631.245 802.917Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(19))} d="M670.665 831.425L711.662 816.153L732 882.766L690.254 895.872L670.665 831.425Z" fill={getFill(19)} fillOpacity="0.64"/>
        <path d="M690.585 895.243L671.28 831.73L711.339 816.807L731.377 882.437L690.585 895.243Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(20))} d="M642.512 841.932C642.512 841.932 650.861 888.073 609.329 921L690.253 895.763L670.664 831.317L642.512 841.932Z" fill={getFill(20)} fillOpacity="0.64"/>
        <path d="M670.341 831.973L689.63 895.434L611.657 919.751C631.06 903.539 638.966 884.441 641.986 869.215C643.546 861.346 643.802 854.512 643.668 849.642C643.6 847.207 643.435 845.262 643.287 843.923C643.213 843.254 643.142 842.736 643.09 842.385C643.084 842.339 643.077 842.296 643.071 842.255L670.341 831.973Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1546_2" transform="matrix(0.00149433 0 0 0.00193592 -3.83914 -1.82401)"/>
        </pattern>
        <image id="image0_1546_2" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}