import { getFillByLot } from "./Manzana2Div1";

export const Manzana36Div1 = ({ lands, landSelector, selected }) => {
  const findLandById = (id) => {
    return lands.filter((l) => l.id === id)[0];
  };

  const findLandByLot = (lot) => {
    return lands.filter((l) => l.lote === lot)[0];
  };

  const getFill = (id) => {
    const land = lands.filter((l) => l.id === id)[0];
    const disabledColor = "rgba(223, 250, 255, 0.48)";
    const normalColor = "#0096B3";
    const selectedColor = "#DFFAFF";

    if (!land) {
      return disabledColor; // disabled
    }

    if (selected) {
      if (id === selected.id) {
        return selectedColor;
      }
    }

    if (land.status !== "on_sale") {
      return disabledColor; // disabled
    } else {
      return normalColor; // normal color
    }
  };

  if (!lands) {
    return <></>;
  }

  return (
    <svg
      width="1219"
      height="942"
      viewBox="0 0 1219 942"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1219" height="942" fill="url(#pattern0_1874_2423)" />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("9"))}
        d="M487.538 552.535L532.627 542.094L544.967 593.827L499.404 603.794L487.538 552.535Z"
        fill={getFillByLot("9", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("3"))}
        d="M384.07 574.364L397.834 571.991L410.174 623.25L338.032 639.387L384.07 574.364Z"
        fill={getFillByLot("3", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("1"))}
        d="M269.687 715.802L352.27 694.444L340.879 638.439L338.506 639.389L269.687 715.802Z"
        fill={getFillByLot("1", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("2"))}
        d="M395.46 684.954L352.27 694.446L340.879 638.916L382.646 629.424L395.46 684.954Z"
        fill={getFillByLot("2", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("4"))}
        d="M439.602 674.988L395.463 684.955L382.648 629.425L426.788 619.933L439.602 674.988Z"
        fill={getFillByLot("4", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("6"))}
        d="M483.264 665.492L439.599 674.985L426.784 619.929L470.449 609.487L483.264 665.492Z"
        fill={getFillByLot("6", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("8"))}
        d="M525.505 655.055L483.264 665.496L470.449 609.491L512.216 600.948L525.505 655.055Z"
        fill={getFillByLot("8", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("10"))}
        d="M567.749 645.562L525.508 655.054L512.218 600.947L555.408 591.93L567.749 645.562Z"
        fill={getFillByLot("10", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("12"))}
        d="M611.886 635.591L567.746 645.558L555.406 591.926L598.122 581.01L611.886 635.591Z"
        fill={getFillByLot("12", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("14"))}
        d="M654.131 627.048L611.89 635.591L598.126 581.01L641.791 571.518L654.131 627.048Z"
        fill={getFillByLot("14", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("16"))}
        d="M697.793 617.56L654.129 627.052L641.314 571.047L685.453 562.504L697.793 617.56Z"
        fill={getFillByLot("16", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("18"))}
        d="M741.929 606.644L697.79 617.56L685.45 562.504L724.843 553.012L741.929 606.644Z"
        fill={getFillByLot("18", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("17"))}
        d="M666.471 511.715L708.712 501.748L724.849 553.007L678.811 563.923L666.471 511.715Z"
        fill={getFillByLot("17", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("15"))}
        d="M621.858 521.687L666.472 511.72L678.812 563.928L634.198 572.471L621.858 521.687Z"
        fill={getFillByLot("15", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("13"))}
        d="M589.582 583.387L634.196 572.945L621.856 521.687L576.768 531.653L589.582 583.387Z"
        fill={getFillByLot("13", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("11"))}
        d="M532.628 542.094L576.768 532.127L589.583 583.386L544.968 594.302L532.628 542.094Z"
        fill={getFillByLot("11", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("7"))}
        d="M443.873 562.504L487.538 553.012L499.403 603.321L455.738 612.814L443.873 562.504Z"
        fill={getFillByLot("7", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("5"))}
        d="M410.171 623.254L455.734 612.812L443.869 562.978L397.831 571.995L410.171 623.254Z"
        fill={getFillByLot("5", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <defs>
        <pattern
          id="pattern0_1874_2423"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1874_2423"
            transform="matrix(0.00133499 0 0 0.00172842 -3.11363 -0.80032)"
          />
        </pattern>
        <image
          id="image0_1874_2423"
          width="4096"
          height="2304"
          href="/mz1.png"
        />
      </defs>
    </svg>
  );
};
