import React, { useCallback, useEffect, useState } from 'react';
import "./DashboardClient.css";
import UserDetails from '../../../register/components/UserDetails';
import { useAuth } from '../../../../../hooks/useAuth';
import { t } from 'i18next';
import { Selector } from '../../../../../components/selector/Selector';
import { useForm } from 'react-hook-form';
import { CompleteProfile } from '../../../register/components/complete/CompleteProfile';
import SuccessIcon from '../../../../../assets/icons/Success';

export default function DashboardClient() {

  const [first, setFirst] = useState(true);
  const [isPaymentRequest, setIsPaymentRequest] = useState(false);

  const auth = useAuth();
  const {updateMe} = useAuth();

  const {user} = auth.user;
  const {control, watch} = useForm({
    defaultValues: {
      land: {
        value: user?.client?.lands[0]?.id,
        label: user?.client?.lands[0]?.identifying_label
      }
    }
  })

  const landSelected = watch('land');
  
  const clientHasLands = () => {
    return user.client.lands.length > 0;
  }

  const landsToOptions = () => {
    if (clientHasLands) {
      return user.client.lands.map(l => ({value: l.id, label: l.identifying_label}));
    } else {
      return []
    }
  }

  const refreshUserInfo = useCallback(async () => {
    if (first) {
      setFirst(false);
      await updateMe();
    }
  }, [first, updateMe]);

  useEffect(() => {
    refreshUserInfo();
  }, [refreshUserInfo]);

  if (user.profile_completed === 0) {
    return (
      <>
      <CompleteProfile
        firstTime={true}
      />
      </>
    )
  }/* else if (user.client.lands.length <= 0) {
    return (<>
    <SelectCategory/>
    </>)
  }*/

  if (!user) {
    return null;
  }

  if (isPaymentRequest) {
    return (
      <div className="flex w-full h-full justify-center items-center" style={{height: '100vh'}}>
        <div
          className="flex flex-col justify-center items-center bg-white p-2 rounded-md shadow-md"
          style={{ width: "34rem", height: "50%" }}
        >
          <SuccessIcon size="100" />
          <label
            className="mt-5"
            style={{ color: "#4E4E4E", fontSize: "46px" }}
          >
            !Enviado¡
          </label>
          <label
            className="mt-5"
            style={{ color: "#203459", fontSize: "18px" }}
          >
            El link le fue enviado al cliente exitosamente
          </label>
        </div>
      </div>
    );
  }

  return (
    <div className="p-5 max-w-screen-xl">
      <div className="flex flex-row justify-start items-center w-full mt-10">
        <h2 className="userTitle text-2xl">{t("clientDashboard.title")}</h2>
        <div className="w-1/5 ml-10">
          <Selector
            name="land"
            options={landsToOptions()}
            defaultValue={{
              value: user?.client?.lands?.[0]?.id,
              label: user?.client?.lands?.[0]?.identifying_label,
            }}
            control={control}
            isNewVersion
          />
        </div>
      </div>

      <div
        className="bg-white rounded-lg mt-8"
        style={{ backgroundColor: "#F1F3F4" }}
      >
        {/*<UserDetails user={user} land={landSelected}/>*/}
        {clientHasLands ? (
          <UserDetails user={user} land={landSelected} setIsPaymentRequest={setIsPaymentRequest}/>
        ) : (
          "Cliente no cuenta con terrenos"
        )}
      </div>
    </div>
  );
}
