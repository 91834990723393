/**
 * Return a capitalized version of a given string.
 * @param {string} value string to capitalize
 * @returns {string} capitalized string
 */
export const capitalized = (value) => {
  if (!value) {
    return "";
  }

  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const formattedPrice = (price) => {
  if (!price) {
    return "";
  }
  return new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: "MXN",
  }).format(price);
};
