import { t } from "i18next";
import Card from "../../../../../components/card/Card";
import { PrimaryButton } from "../../../../../components/primary/PrimaryButton";
import { useCallback, useEffect, useState } from "react";
import { getPaymentMethod } from "../../../../../services/PaymentService";
import { capitalized } from "../../../../../utils";

export const ShowCard = ({ toggle, setToggle, user }) => {
  const [card, setCard] = useState(null);

  const getCard = useCallback(async () => {
    // retrieve cards
    try {
      const response = await getPaymentMethod();
      setCard(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getCard();
  }, [toggle]);

  if (!card) {
    return (
      <>
        <div
          className="mt-10 mb-5"
          style={{
            height: "4px",
            width: "100%",
            background: "linear-gradient(90deg, #3fcde2, #eefdff)",
            WebkitBackground: "linear-gradient(90deg, #3fcde2, #eefdff)",
            opacity: ".5",
          }}
        />
        <div className="mt-2">
          <div className="mb-2 no-card">{t("showCard.noPaymentMethod")}</div>
          <PrimaryButton
            className="px-5"
            style={{ minWidth: "250px" }}
            onClick={(e) => {
              e.preventDefault();
              setToggle(true);
            }}
          >
            {t("showCard.addPaymentMethod")}
          </PrimaryButton>
        </div>
      </>
    );
  }

  return (
    <div className="mt-10">
      <Card className={!card ? "flex justify-center p-5 mt-2" : "mt-2"}>
        <div
          className="grid grid-cols-4 p-3 gap-20 payment-method-header rounded-t-md"
          style={{ backgroundColor: "#D2EFF4" }}
        >
          <div>{t("showCard.card")}</div>
          <div>{t("showCard.type")}</div>
          <div>{t("showCard.holder")}</div>
          <div></div>
        </div>
        <div className="grid grid-cols-4 p-3 pt-2 gap-20">
          <div className="text-primary text-sm">{card?.last4}</div>
          <div className="text-sm">{capitalized(card?.brand)}</div>
          <div className="text-sm">
            {capitalized(user?.name || "") +
              " " +
              capitalized(user?.last_name || "")}
          </div>
          <div
            className="cursor-pointer update-password"
            onClick={() => setToggle(true)}
          >
            {t("showCard.updatePaymentMethod")}
          </div>
        </div>
      </Card>
    </div>
  );
};
