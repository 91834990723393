export const Download = ({disabled = false, color = "#34B9D2"}) => {
    if (disabled) {
        color="#BFBFBF"
    }
    return (
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.171 7.74881C12.0849 7.55863 11.8958 7.4375 11.6876 7.4375H9.56256V0.53125C9.56256 0.238 9.32456 0 9.03131 0H6.90632C6.61307 0 6.37507 0.238 6.37507 0.53125V7.4375H4.25007C4.04182 7.4375 3.85269 7.55969 3.76663 7.74881C3.6795 7.939 3.7135 8.16106 3.85057 8.31831L7.56932 12.5683C7.67025 12.6841 7.81581 12.75 7.96881 12.75C8.12181 12.75 8.26738 12.6831 8.36832 12.5683L12.0871 8.31831C12.2252 8.16212 12.2571 7.939 12.171 7.74881Z" fill={color}/>
        <path d="M13.8125 11.6875V14.875H2.125V11.6875H0V15.9375C0 16.5251 0.476 17 1.0625 17H14.875C15.4626 17 15.9375 16.5251 15.9375 15.9375V11.6875H13.8125Z" fill={color}/>
        </svg>
    )
}