import { getFillByLot } from "./Manzana2Div1";

export const Manzana10Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1218" height="941" viewBox="0 0 1218 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1218" height="941" fill="url(#pattern0_1862_4593)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('13'))}
            d="M643.604 523.764H682.189V617.26H643.604V523.764Z" 
            fill={getFillByLot('13', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('12'))}
            d="M605.016 523.764H643.601V617.26H605.016V523.764Z" 
            fill={getFillByLot('12', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('11'))}
            d="M566.43 523.764H605.016V617.26H566.43V523.764Z"
            fill={getFillByLot('11', selected, lands)}
            fillOpacity="0.64"
            stroke="#6D6859"
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('10'))}
            d="M527.845 523.764H566.431V617.26H527.845V523.764Z" 
            fill={getFillByLot('10', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('9'))}
            d="M489.258 523.764H527.844V617.26H489.258V523.764Z" 
            fill={getFillByLot('9', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('8'))}
            d="M450.671 523.764H489.257V617.26H450.671V523.764Z" 
            fill={getFillByLot('8', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('7'))}
            d="M412.087 523.764H450.673V617.26H412.087V523.764Z" 
            fill={getFillByLot('7', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('6'))}
            d="M373.5 523.764H412.086V617.26H373.5V523.764Z" 
            fill={getFillByLot('6', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('5'))}
            d="M334.913 523.764H373.499V617.26H334.913V523.764Z" 
            fill={getFillByLot('5', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('4'))}
            d="M296.329 523.764H334.915V617.26H296.329V523.764Z" 
            fill={getFillByLot('4', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('3'))}
            d="M257.745 523.764H296.331V617.26H257.745V523.764Z" 
            fill={getFillByLot('3', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M219.155 523.764H257.741V617.26H219.155V523.764Z" 
            fill={getFillByLot('2', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('1'))}
            d="M180.571 523.764H219.157V617.26H180.571V523.764Z" 
            fill={getFillByLot('1', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('15'))}
            d="M682.188 617.261H774.2L759.359 575.707H682.188V617.261Z" 
            fill={getFillByLot('15', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('14'))}
            d="M682.188 575.706V523.764H744.519L759.359 575.706H682.188Z" 
            fill={getFillByLot('14', selected, lands)}
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <defs>
        <pattern id="pattern0_1862_4593" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1862_4593" transform="matrix(0.00113392 0 0 0.00146844 -2.94715 -2.32104)"/>
        </pattern>
        <image id="image0_1862_4593" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}