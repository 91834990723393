import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import zihuaLogo from "../../../../assets/images/zihua-logo.svg";
import { useAuth } from "../../../../hooks/useAuth";
import { roles } from "../../../../constants/roles";
import Icon from "../../../Icon";
import './sideMenu.css';
import { useTheme } from "../../../../Providers/ThemeProvider";

const iconSize = 27;

export default function SideMenu() {
  const {currentTheme} = useTheme();

  const [optionSelected, setOptionSelected] = useState("");
  const [role, setRole] = useState("");

  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleOptionSelected = useCallback((option) => {
    setOptionSelected(option);
  }, []);

  const navigateOptionSelected = useCallback(
    (option, path) => {
      setOptionSelected(option);
      navigate(path);
    },
    [navigate]
  );

  useEffect(() => {
    setOptionSelected(
      location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
    );
    const userRole = user.user.roles[0].name.toLowerCase();
    setRole(userRole);
  }, [location, user]);

  return (
    <header className="main-head relative">
      <div className="flex justify-center w-full mt-4">
        <img src={zihuaLogo} alt="Zihua Logo" className="w-6 lg:w-16 mb-4" />
      </div>
      <nav className="head-nav w-15 lg:w-40">
        <ul className="">
          <li className="flex mt-2">
            <a
              href="/admin/home"
              className="cursor-pointer"
              onClick={() => handleOptionSelected("home")}
              style={{ alignItems: "flex-end" }}
            >
              <Icon
                className={`mr-2 ${
                  optionSelected === "home"
                    ? "text-primary"
                    : "text-menu"
                }`}
                style={{
                   marginBottom: "0.2rem",
                   color:  optionSelected === "home" ? currentTheme.secondary : currentTheme.disabled
                   }}
                icon="home"
                size={iconSize}
                onClick={() => navigateOptionSelected("home", "home")}
              />
              <div
                className={`hidden lg:block ${
                  optionSelected === "home"
                    ? "text-primary"
                    : "text-menu"
                }`}
                style={{
                  color:  optionSelected === "home" ? currentTheme.secondary : currentTheme.disabled
                }}
              >
                {t("adminSections.home")}
              </div>
            </a>
          </li>

          {(role === roles.SUPER_ADMIN ||
            role === roles.ADMIN ||
            role === roles.COORDINATOR ||
            role === roles.ORGANIZATION ||
            role === roles.SELLER) && (
            <>
              {/*<li className="flex mt-2">
                <a
                  href="catalogs"
                  onClick={() => handleOptionSelected("catalogs")}
                  className="cursor-pointer"
                >
                  <Icon
                    icon="iand"
                    className={`mr-2 mb-2 ${
                      optionSelected === "catalogs"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    size={iconSize}
                    onClick={() =>
                      navigateOptionSelected("catalogs", "/admin/catalogs")
                    }
                  />
                  <div
                    className={`hidden lg:block ${
                      optionSelected === "catalogs"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    style={{ alignItems: "flex-end" }}
                  >
                    {t("adminSections.plots")}
                  </div>
                </a>
              </li>*/}
              <li className="flex mt-2">
                <a
                  href="/admin/land-by-status"
                  onClick={() => handleOptionSelected("land-by-status")}
                  className="cursor-pointer"
                >
                  <Icon
                    icon="iand"
                    className={`mr-2 mb-2 ${
                      optionSelected === "land-by-status"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    style={{ color: optionSelected === "land-by-status" ? currentTheme.secondary : currentTheme.disabled }}
                    size={iconSize}
                    onClick={() =>
                      navigateOptionSelected(
                        "land-by-status",
                        "/admin/land-by-status"
                      )
                    }
                  />
                  <div
                    className={`hidden lg:block ${
                      optionSelected === "land-by-status"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    style={{ 
                      alignItems: "flex-end",
                      color: optionSelected === "land-by-status" ? currentTheme.secondary : currentTheme.disabled
                     }}
                  >
                    {t("adminSections.plots")}
                  </div>
                </a>
              </li>
              {/* TODO: Add new option for sales */}
              <li className="flex mt-2">
                <a
                  href="/admin/sales"
                  onClick={() => handleOptionSelected("sales")}
                  className="cursor-pointer"
                >
                  <Icon
                    icon="bar-chart"
                    className={`mr-2 mb-2 ${
                      optionSelected === "sales"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    style={{ color: optionSelected === "sales" ? currentTheme.secondary : currentTheme.disabled }}
                    size={iconSize}
                    onClick={() =>
                      navigateOptionSelected("sales", "/admin/sales")
                    }
                  />
                  <div
                    className={`hidden lg:block ${
                      optionSelected === "sales"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    style={{ 
                      alignItems: "flex-end",
                      color: optionSelected === "sales" ? currentTheme.secondary : currentTheme.disabled
                    }}
                  >
                    {t("adminSections.sales")}
                  </div>
                </a>
              </li>
              {/*
             // TODO: Pending to remove
             <li className='flex'>
                <a
                  href="amortizations"
                  onClick={() => handleOptionSelected('amortizations')}
                  className="cursor-pointer"
                >
                  <Calculator
                    size={iconSize}
                    className={"mr-2"}
                    onClick={() => navigateOptionSelected('amortizations', '/admin/amortizations')}
                    disabled={optionSelected !== 'amortizations'}
                  />
                  <div className={`hidden lg:block ${optionSelected === 'amortizations' ? 'text-primary' : 'text-menu'}`}>{t('adminSections.amortizations')}</div>
                </a>
              </li
              <li className='flex'>
                <a href="contact" onClick={() => handleOptionSelected('contact')} className="cursor-pointer">
                  <FaComment
                    size={iconSize}
                    className={"mr-2"}
                    onClick={() => navigateOptionSelected('contact', '/admin/contact')}
                    disabled={optionSelected !== 'contact'}
                    color={optionSelected === 'contact' ? '#0095b3' : '#939598'}
                  />
                  <div className={`hidden lg:block ${optionSelected === 'contact' ? 'text-primary' : 'text-menu'}`}>{t('adminSections.contact')}</div>
                </a>
              </li>
              >*/}
            </>
          )}

          {role !== roles.CLIENT && (
            <>
              <li className="flex mt-2">
                <a
                  href="/admin/users"
                  className="cursor-pointer "
                  onClick={() => handleOptionSelected("users")}
                  style={{ alignItems: "flex-end" }}
                >
                  <Icon
                    icon="users"
                    className={`mr-2 ${
                      optionSelected === "users"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    size={iconSize}
                    style={{ color: optionSelected === "users" ? currentTheme.secondary : currentTheme.disabled }}
                    onClick={() => navigateOptionSelected("users", "users")}
                  />
                  <div
                    className={`hidden lg:block ${
                      optionSelected === "users"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    style={{ color: optionSelected === "users" ? currentTheme.secondary : currentTheme.disabled }}
                  >
                    {t("adminSections.users")}
                  </div>
                </a>
              </li>
            </>
          )}
          {(role === roles.SUPER_ADMIN ||
            role === roles.ORGANIZATION ||
            role === roles.SELLER) && (
            <li className="flex mt-2">
              <a
                href="/admin/notifications"
                onClick={() => handleOptionSelected("notifications")}
                className="cursor-pointer"
              >
                <Icon
                  icon="notification-bell"
                  className={`mr-2 mb-1 ${
                    optionSelected === "notifications"
                      ? "text-primary"
                      : "text-menu"
                  }`}
                  style={{ color: optionSelected === "notifications" ? currentTheme.secondary : currentTheme.disabled }}
                  size={iconSize}
                  onClick={() =>
                    navigateOptionSelected(
                      "notifications",
                      "/admin/notifications"
                    )
                  }
                />
                <div
                  className={`hidden lg:block ${
                    optionSelected === "notifications"
                      ? "text-primary"
                      : "text-menu"
                  }`}
                  style={{ 
                    alignItems: "flex-end",
                    color: optionSelected === "notifications" ? currentTheme.secondary : currentTheme.disabled
                  }}
                >
                  {t("adminSections.notifications")}
                </div>
              </a>
            </li>
          )}
          {role === roles.CLIENT && (
            <>
              {/* <li className='flex'>
                <a
                  href="credits"
                  onClick={() => handleOptionSelected('credits')}
                  className={'cursor-pointer'}
                >
                  <PiMathOperationsFill size={iconSize} className={`mr-2 ${optionSelected === 'credits' ? 'text-primary' : 'text-menu'}`}/>
                  <div className={`${optionSelected === 'credits' ? 'text-primary' : 'text-menu'}`}>
                    {t('adminSections.credits')}
                  </div>
                </a>
              </li> */}
              <li className="flex mt-2">
                <a
                  href="/admin/land-by-user"
                  onClick={() => handleOptionSelected("land-by-user")}
                  className="cursor-pointer"
                >
                  <Icon
                    icon="iand"
                    className={`mr-2 mb-2 ${
                      optionSelected === "land-by-user"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    size={iconSize}
                    style={{ color: optionSelected === "land-by-user" ? currentTheme.secondary : currentTheme.disabled }}
                    onClick={() =>
                      navigateOptionSelected(
                        "land-by-user",
                        "/admin/land-by-user"
                      )
                    }
                  />
                  <div
                    className={`hidden lg:block ${
                      optionSelected === "land-by-user"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    style={{ 
                      alignItems: "flex-end",
                      color: optionSelected === "land-by-user" ? currentTheme.secondary : currentTheme.disabled
                    }}
                  >
                    {t("adminSections.plots")}
                  </div>
                </a>
              </li>
              <li className="flex mt-2">
                <a
                  href="/admin/user-payments"
                  onClick={() => handleOptionSelected("user-payments")}
                  className="cursor-pointer"
                >
                  <Icon
                    icon="payment"
                    className={`mr-2 mb-2 ${
                      optionSelected === "user-payments"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    size={iconSize}
                    style={{ color: optionSelected === "user-payments" ? currentTheme.secondary : currentTheme.disabled }}
                    onClick={() =>
                      navigateOptionSelected(
                        "user-payments",
                        "/admin/user-payments"
                      )
                    }
                  />
                  <div
                    className={`hidden lg:block ${
                      optionSelected === "user-payments"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    style={{ alignItems: "flex-end", color: optionSelected === "user-payments" ? currentTheme.secondary : currentTheme.disabled }}
                  >
                    Pagos
                  </div>
                </a>
              </li>
              <li className="flex mt-2">
                <a
                  href="/admin/documents-by-user"
                  onClick={() => handleOptionSelected("documents-by-user")}
                  className="cursor-pointer"
                >
                  <Icon
                    icon="document"
                    className={`mr-2 mb-2 ${
                      optionSelected === "documents-by-user"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    style={{ color: optionSelected === "documents-by-user" ? currentTheme.secondary : currentTheme.disabled }}
                    size={iconSize}
                    onClick={() =>
                      navigateOptionSelected(
                        "documents-by-user",
                        "/admin/documents-by-user"
                      )
                    }
                  />
                  <div
                    className={`hidden lg:block ${
                      optionSelected === "documents-by-user"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    style={{ 
                      alignItems: "flex-end",
                      color: optionSelected === "documents-by-user" ? currentTheme.secondary : currentTheme.disabled
                    }}
                  >
                    Documentos
                  </div>
                </a>
              </li>
              <li className="flex mt-2">
              <a
                href="/admin/notifications"
                onClick={() => handleOptionSelected("notifications")}
                className="cursor-pointer"
              >
                <Icon
                  icon="notification-bell"
                  className={`mr-2 mb-1 ${
                    optionSelected === "notifications"
                      ? "text-primary"
                      : "text-menu"
                  }`}
                  size={iconSize}
                  style={{ color: optionSelected === "notifications" ? currentTheme.secondary : currentTheme.disabled }}
                  onClick={() =>
                    navigateOptionSelected(
                      "notifications",
                      "/admin/notifications"
                    )
                  }
                />
                <div
                  className={`hidden lg:block ${
                    optionSelected === "notifications"
                      ? "text-primary"
                      : "text-menu"
                  }`}
                  style={{ 
                    alignItems: "flex-end",
                    color: optionSelected === "notifications" ? currentTheme.secondary : currentTheme.disabled
                  }}
                >
                  {t("adminSections.notifications")}
                </div>
              </a>
            </li>
              {/*<li className="flex">
                <a
                  href="profile"
                  onClick={() => handleOptionSelected("profile")}
                  className={"cursor-pointer"}
                >
                  <Avatar2
                    size={iconSize}
                    className={`mr-2 ${
                      optionSelected === "profile"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                    disabled={optionSelected !== "profile"}
                  />
                  <div
                    className={`hidden lg:block ${
                      optionSelected === "profile"
                        ? "text-primary"
                        : "text-menu"
                    }`}
                  >
                    {t("adminSections.profile")}
                  </div>
                </a>
              </li>*/}
            </>
          )}
          {/* TODO: Add new option for config */}
          <li className="flex mt-2">
            <a
              href="/admin/Configuracion"
              onClick={() => {}}
              className="cursor-pointer"
            >
              <Icon
                icon="settings"
                className={`mr-2 mb-2 ${
                  optionSelected === "ventas"
                    ? "text-primary"
                    : "text-menu"
                }`}
                size={iconSize}
                style={{ color: optionSelected === "ventas" ? currentTheme.secondary : currentTheme.disabled }}
                onClick={() => {}}
              />
              <div
                className={`hidden lg:block ${
                  optionSelected === "config"
                    ? "text-primary"
                    : "text-menu"
                }`}
                style={{ 
                  alignItems: "flex-end",
                  color: optionSelected === "config" ? currentTheme.secondary : currentTheme.disabled
                }}
              >
                {t("adminSections.settings")}
              </div>
            </a>
          </li>

          {/*
           // TODO: Pending to remove
            (role === roles.ORGANIZATION || role === roles.SUPER_ADMIN || role === roles.ADMIN) &&
            <>
              <li className='flex'>
                <a href="/admin/contacts-organization" className="cursor-pointer" onClick={() => handleOptionSelected('contactsOrganization')}>
                  <FaUsers
                    size={iconSize}
                    className={`mr-2 ${optionSelected === 'contactsOrganization' ? 'text-primary' : 'text-menu'}`}
                    onClick={() => navigateOptionSelected('contactsOrganization', 'contacts-organization')}
                  />
                  <div className={`hidden lg:block ${optionSelected === 'contactsOrganization' ? 'text-primary' : 'text-menu'}`}>
                    {t('adminSections.registers')}
                  </div>
                </a>
              </li>
            </>
          */}
        </ul>
      </nav>
    </header>
  );
}
