import './App.css';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/public/login/Login';
import Home from './pages/public/home/Home';
import HomeLayout from './components/homeLayout/HomeLayout';
import ProtectedLayout from './components/protectedLayout/ProtectedLayout';
import PaymentsPage from './pages/logged/paymentsPage/PaymentsPage';
import Register from './pages/logged/register/Register';
import { AuthProvider } from './hooks/useAuth';
import DashboardPage from './pages/logged/dashboardPage/DashboardPage';
import PageNotFound from './components/pageNotFound/PageNotFound';
import Catalogs from './pages/logged/catalogs/Catalogs';
import RegisterList from './pages/logged/register/RegisterList';
import Profile from './pages/logged/Profile';
import PaymentSuccess from './pages/public/payments/Success';
import PaymentCancel from './pages/public/payments/Cancel';
import Amortizations from './pages/logged/amortizations/Amortizations';
import Credits from './pages/logged/credits/Credits';
import PaymentMethod from './pages/logged/paymentMethod/PaymentMethod';
import ForgotPassword from './pages/public/login/components/forgotPassword/ForgotPassword';
import ResetPassword from './pages/public/login/components/resetPassword/ResetPassword';
import SelectLand from './pages/logged/sale/components/SelectLand';
import ClientDetails from './pages/logged/dashboardPage/components/clientDetails/ClientDetails';
import SelectCategory from './pages/logged/sale/components/SelectCategory';
import { NotificationsDashboard } from './pages/logged/notifications/Notifications';
import { PendingApproval } from './pages/logged/sale/components/pendingApproval/PendingApproval';
import Contact from './pages/logged/Contact/Contact';
import NotificationList from './pages/logged/notifications/notificationList/NotificationList';
import RegisterPublic from './pages/public/registerPublic/RegisterPublic';
import ContactOrganization from './pages/logged/ContactOrganization/ContactOrganization';
import Lands from './pages/logged/lands';
import Sales from './pages/logged/sales/Sales';
import UserSites from './pages/logged/UserSites';
import { ThemeProvider } from './Providers/ThemeProvider';
import UserProcess from './pages/logged/UserProcess';
import { DocumentUploadSuccess } from './pages/logged/sale/components/DocumentUploadSuccess';
import { DocumentUploadError } from './pages/logged/sale/components/DocumentUploadError';

function App() {
  
  return (
    <AuthProvider>
      <ThemeProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path='public-register' element={<RegisterPublic />} />
        <Route element={<HomeLayout />}>
          <Route path="/" element={<Home />} />
        </Route>
        <Route path="/admin" element={<ProtectedLayout />}>
          <Route path="payment-success" element={<PaymentSuccess/>} />
          <Route path="payment-cancel" element={<PaymentCancel/>} />
          <Route path="profile" element={<Profile/>}/>
          <Route path='home' element={<DashboardPage />} />
          <Route path="register" element={<Register />} />
          <Route path="payments" element={<PaymentsPage />} />
          <Route path="catalogs" element={<Catalogs />} />
          <Route path="land-by-status" element={<Lands />} />
          <Route path="land-by-user" element={<UserSites/>} />
          <Route path="user-payments" element={<div>User payments</div>} />
          <Route path="user-process/:id" element={<UserProcess />} />
          <Route path="documents-by-user" element={<div>Documents by user</div>} />
          <Route path="users" element={<RegisterList />} />
          <Route path="sales" element={<Sales />} />
          <Route path="amortizations" element={<Amortizations />} />
          <Route path="credits" element={<Credits />} />
          <Route path="payment-method" element={<PaymentMethod />} />
          <Route path="lands/:section/:group" element={<SelectLand/>} />
          <Route path="categories" element={<SelectCategory/>} />
          <Route path="details/categories" element={<SelectCategory/>} />
          <Route path="details/:id/categories" element={<SelectCategory/>} />
          <Route path="details/:id/:userId/categories" element={<SelectCategory/>} />
          <Route path="details" element={<ClientDetails/>} />
          <Route path="details/:id" element={<ClientDetails/>} />
          <Route path="details/:id/:userId" element={<ClientDetails/>} />
          <Route path="notifications" element={<NotificationsDashboard/>} />
          <Route path="upload-success" element={<PendingApproval />} />
          <Route path="document-upload-success" element={<DocumentUploadSuccess />} />
          <Route path="document-upload-error" element={<DocumentUploadError />} />
          <Route path="contact" element={<Contact />} />
          <Route path="notification-list" element={<NotificationList />} />
          <Route path="contacts-organization" element={<ContactOrganization />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;
