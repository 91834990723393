import React, { useEffect, useMemo, useState } from "react";
import "./ClientDetails.css";
import UserDetails from "../../../register/components/UserDetails";
import Card from "../../../../../components/card/Card";
import { useAuth } from "../../../../../hooks/useAuth";
import { roles } from "../../../../../constants/roles";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLandsFromUser,
  selectClient,
} from "../../../../../features/userSlice/userSlice";
import { PrimaryButton } from "../../../../../components/primary/PrimaryButton";
import { FaArrowLeft } from "react-icons/fa";
import SuccessIcon from "../../../../../assets/icons/Success";
import { useNavigate, useParams } from "react-router-dom";
import { getUserById } from "../../../../../services/UsersService";

export default function ClientDetails() {
  const [first, setFirst] = useState(true);
  const [isPaymentRequest, setIsPaymentRequest] = useState(false);
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();
  const userAuth = useAuth();
  const { lands } = useSelector((state) => state.user);
  const userSelected = useSelector((state) => state.user.client);
  const { id: clientId, userId } = useParams();
  const navigate = useNavigate();
  console.log("userId", userId);
  //let user = null;
  const defaultValues = useMemo(
    () => ({
      land: {
        value: lands[0]?.id,
        label: lands[0]?.identifying_label,
      },
    }),
    [lands]
  );

  // TODO refactor user selected
  /*if (userAuth.user.user.roles[0].name.toLowerCase() === roles.CLIENT) {
    //user = userAuth.user.user;
    setUser(userAuth.user.user);
  } else {
    //user = userSelected;
    setUser(userSelected);
  }*/
  /*const { control, watch } = useForm({
    defaultValues,
    mode: "onChange",
  });*/
  const [landSelected, setLandSelected] = useState({
    value: lands[0]?.id,
    label: lands[0]?.identifying_label,
  });
  //const landSelected = watch('land');

  useEffect(() => {
    if (userAuth?.user?.user?.roles?.[0].name.toLowerCase() === roles.CLIENT) {
      //user = userAuth.user.user;
      setUser(userAuth?.user?.user);
    } else {
      //user = userSelected;
      setUser(userSelected);
    }
  }, [lands, userAuth?.user?.user, userSelected]);

  // dispatch(selectClient(theUser));
  useEffect(() => {
    setTimeout(() => {
      //const userId = clientId;
      getUserById({ id: userId })
        .then((response) => {
          dispatch(selectClient(response.data.data));
          const lands = response.data.data.client.lands;
          if (lands.length > 0) {
            setLandSelected({
              value: lands[0]?.id,
              label: lands[0]?.identifying_label,
            });
            //landSelected.value = lands[0]?.id;
            //landSelected.label = lands[0]?.identifying_label;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }, 1200);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // fetch lands
    let params = null;
    if (userAuth.user.user.roles[0].name.toLowerCase() !== roles.CLIENT) {
      params = { user: userSelected.id };
    }
    if (userAuth.user.user.roles[0].name.toLowerCase() !== roles.SUPER_ADMIN) {
      params = { user: userSelected.id };
    }
    if (first) {
      dispatch(fetchLandsFromUser(params));
      setFirst(false);
    }
  }, [
    defaultValues,
    dispatch,
    first,
    landSelected,
    lands,
    userAuth.user.user.roles,
    userSelected.id,
  ]);

  /*const clientHasLands = () => {
    return lands.length > 0;
  };

  const landsToOptions = () => {
    if (lands.length > 0) {
      return lands.map((l) => ({ value: l.id, label: l.identifying_label }));
    } else {
      return [];
    }
  };*/

  if (isPaymentRequest) {
    return (
      <div className="flex w-full h-full justify-center items-center">
        <div
          className="flex flex-col justify-center items-center bg-white p-2 rounded-md shadow-md"
          style={{ width: "34rem", height: "50%" }}
        >
          <SuccessIcon size="100" />
          <label
            className="mt-5"
            style={{ color: "#4E4E4E", fontSize: "46px" }}
          >
            !Enviado¡
          </label>
          <label
            className="mt-5"
            style={{ color: "#203459", fontSize: "18px" }}
          >
            El link le fue enviado al cliente exitosamente
          </label>
        </div>
      </div>
    );
  }

  if (!user) {
    return;
  }

  return (
    <div className="p-5 max-w-screen-xl">
      <div className="flex justify-start">
        <PrimaryButton
          className="mb-5 mr-5"
          style={{ width: "50px", textAlign: "-webkit-center" }}
          onClick={() => {
            navigate("/admin/users");
          }}
        >
          <FaArrowLeft size={15} />
        </PrimaryButton>
        <h2 className="mb-10 userTitle">Usuario</h2>
      </div>
      {/*![roles.SUPER_ADMIN].includes(
        userAuth?.user?.user?.roles?.[0]?.name?.toLowerCase()
      ) && (
        <div className="w-1/6">
          <Selector
            name="land"
            options={landsToOptions()}
            defaultValue={{
              land: {
                value: userSelected?.lands?.[0]?.id,
                label: userSelected?.lands?.[0]?.identifying_label,
              },
            }}
            control={control}
          />
        </div>
      )*/}

      {/*    <Card className='mt-10 gray-card'>

        <UserDetails user={user} land={landSelected}/>
      </Card>*/}
      <Card className="mt-10 gray-card">
        <UserDetails
          user={user}
          land={landSelected}
          setIsPaymentRequest={setIsPaymentRequest}
          isPaymentRequest={isPaymentRequest}
        />
      </Card>
      {/*(clientHasLands && landSelected.value) ||
      userAuth.user.user.roles[0].name.toLowerCase() === roles.SUPER_ADMIN ? (
        <Card className="mt-10 gray-card">
          <UserDetails
            user={user}
            land={landSelected}
            setIsPaymentRequest={setIsPaymentRequest}
            isPaymentRequest={isPaymentRequest}
          />
        </Card>
      ) : clientHasLands ? (
        "Seleccione el terreno"
      ) : (
        "Cliente no cuenta con terrenos"
      )*/}
    </div>
  );
}
