import { useCallback, useEffect, useState } from "react";
import { SectionMap } from "./sections/SectionMap";
import { General } from "./main/General";
import { Manzana1Div1 } from "./manzanas/Manzana1Div1";
import { getLotLands } from "../../../../services/LotLandsService";
import { toast } from "react-toastify";
import { Manzana1Div2 } from "./manzanas/Manzana1Div2";
import Loading from "../../../../components/loading/Loading";
import { useForm } from "react-hook-form";
import { ModalDetails } from "../components/modal/ModalDetails";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../hooks/useAuth";
import { assignLand, saveProgress } from "../../../../features/userSlice/paymentSlice";
import { useNavigate, useParams } from "react-router-dom";
import { selectClient } from "../../../../features/userSlice/userSlice";
import LandDetails from "../components/LandDetails";
import { Manzana10Div1 } from "./manzanas/Manzana10Div1";
import { Manzana2Div1 } from "./manzanas/Manzana2Div1";
import { Manzana9Div1 } from "./manzanas/Manzana9Div1";
import { Manzana5Div1 } from "./manzanas/Manzana5Div1";
import { Manzana6Div1 } from "./manzanas/Manzana6Div1";
import { Manzana7Div1 } from "./manzanas/Manzana7Div1";
import { Manzana8Div1 } from "./manzanas/Manzana8Div1";
import { Manzana11Div1 } from "./manzanas/Manzana11Div1";
import { Manzana12Div1 } from "./manzanas/Manzana12Div1";
import { Manzana15Div1 } from "./manzanas/Manzana15Div1";
import { Manzana14Div1 } from "./manzanas/Manzana14Div1";
import { Manzana13Div1 } from "./manzanas/Manzana13Div1";
import { Manzana20Div1 } from "./manzanas/Manzana20Div1";
import { Manzana3Div1 } from "./manzanas/Manzana3Div1";
import { Manzana16Div1 } from "./manzanas/Manzana16Div1";
import { Manzana17Div1 } from "./manzanas/Manzana17Div1";
import { Manzana18Div1 } from "./manzanas/Manzana18Div1";
import { Manzana23Div1 } from "./manzanas/Manzana23Div1";
import { Manzana24Div1 } from "./manzanas/Manzana24Div1";
import { Manzana25Div1 } from "./manzanas/Manzana25Div1";
import { Manzana26Div1 } from "./manzanas/Manzana26Div1";
import { Manzana22Div1 } from "./manzanas/Manzana22Div1";
import { Manzana21Div1 } from "./manzanas/Manzana21Div1";
import { Manzana19Div1 } from "./manzanas/Manzana19Div1";
import { Manzana31Div1 } from "./manzanas/Manzana31Div1";
import { Manzana33Div1 } from "./manzanas/Manzana33Div1";
import { Manzana34Div1 } from "./manzanas/Manzana34Div1";
import { Manzana27Div1 } from "./manzanas/Manzana27Div1";
import { Manzana28Div1 } from "./manzanas/Manzana28Div1";
import { Manzana38Div1 } from "./manzanas/Manzana38Div1";
import { Manzana37Div1 } from "./manzanas/Manzana37Div1";
import { Manzana36Div1 } from "./manzanas/Manzana36Div1";
import { Manzana29Div1 } from "./manzanas/Manzana29Div1";
import { Manzana32Div1 } from "./manzanas/Manzana32Div1";
import { Manzana35Div1 } from "./manzanas/Manzana35Div1";
import { Manzana30Div1 } from "./manzanas/Manzana30Div1";
import { Manzana4Div1 } from "./manzanas/Manzana4Div1";
import { Manzana35Div3 } from "./manzanas/Manzana35Div3";
import { Manzana35Div2 } from "./manzanas/Manzana35Div2";
import { Manzana15Div2 } from "./manzanas/Manzana15Div2";
import { Manzana15Div3 } from "./manzanas/Manzana15Div3";
import { PrimaryButton } from "../../../../components/primary/PrimaryButton";

export const GeneralMap = (
    { isPublic = false, onClusterSelected }
) => {

    const [mapSelected, setMapSelected] = useState({section: null, group: null, land: null, manzana: null});
    const [loading, setLoading] = useState(false);
    // lands catalog
    const [lands, setLands] = useState([]);
    const [landSelected, setLandSelected] = useState(null);
    const [first, setFirst] = useState(true);

    const {user: authUser} = useAuth();
    const {client: user} = useSelector(state => state.user); // user selected
    const [isVisible, setIsVisible] = useState(false);
    const [position, setPosition] = useState({top: 100, left: 150});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {userId} = useParams();

    const {
        handleSubmit,
        watch,
        setValue
    } = useForm({
        mode: 'onSubmit',
        defaultValues: {
            periods: 120
        }
    });

    const landWatcher = watch('land');
    console.debug('land selected', landWatcher);

    const fetchLands = useCallback(async () => {
        try {
            const group = mapSelected.group;
            const manzana = mapSelected.manzana;
            const section = mapSelected.section;
            const response = await getLotLands({pagination: false, section, manzana });
            setLands(response.data.data);
            setLoading(false);
        } catch (error) {
            toast.error(error.message)
        }
    }, [mapSelected.group, mapSelected.section]);

    useEffect(() => {
        console.log('window.innerWidth: ', window.innerWidth);
        if (window.innerWidth < 1000) {
            if (window.innerWidth < 700) {
                setPosition(
                    {
                        top: 100,
                        left: 480
                    }
                );
            } else {
                setPosition(
                    {
                        top: 100,
                        left: 450
                    }
                );
            }
        }
        if (mapSelected.group && mapSelected.section) {
            fetchLands();
        }
    }, [fetchLands, first, mapSelected])

    const sectionHandler = (sectionId) => {
        // onClusterSelected(true);
        return setMapSelected({section: sectionId, group: null, land: null})
    }

    const groupHandler = (sectionId, manzanaId, groupId) => {
        console.log('manzanaId: ', manzanaId);
        setLoading(true);
        setMapSelected({...mapSelected, section: sectionId, group: groupId, manzana: manzanaId})
    }

    const handleBackManzana = useCallback((sectionId) => {
        setLoading(true);
        setMapSelected({section: 1, group: null, manzana: null})
        setLoading(false);
    });

    const handleBackSection = useCallback(() => {
        setLoading(true);
        setMapSelected({section: null, group: null, manzana: null});
        setLoading(false);
    }, []);

    const landHandler = (e, land) => {
        e.preventDefault();
        // const rect = e.target.getBoundingClientRect();
        setValue('land', land);
        console.log('----land---', land)
        // setPosition({
        //           top: rect.top + window.scrollY,
        //           left: rect.left + window.scrollX + rect.width / 2,
        //         });
        setIsVisible(true);
        setLandSelected(land);
    }

// submit
    const submit = (data) => {
console.log('submit data', data)
console.log('submit user', user)
        if (isPublic) {
            window.location.href = '#contacto';
            return null;
        }

        // convert years to months
        const monthsPeriod = data.periods;
        let client = null;
        let userPayload = null;

        client = user.client.id;
        userPayload = user.id;

        const payload = {
            client_id: client,
            land_id: data.land.id,
            periods: monthsPeriod,
            interest: 0
        }

        dispatch(assignLand({land: payload.land_id, params: payload}))
        dispatch(selectClient(user));

        dispatch(saveProgress({
            user: userPayload,
            payload: {data: payload, step: 3, name: 'sales', land: payload.land_id}
        })).then(response => {
            // set user selected
            if (userId) {
                navigate(`/admin/details/${user?.id}/${userId}`);
            } else {
                navigate(`/admin/details/${user?.id}`);
            }
        });
    }

    if (loading) {
        return <Loading/>
    }

    if (!mapSelected.section) {
        return <General onClickHandler={sectionHandler}/>
    }

    if (mapSelected.section === 1 && !mapSelected.group) {
        return <SectionMap 
            onClickHandler={groupHandler}
            onBack={handleBackSection}
            />
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 1) {
        return (
            <>
            <div className="relative">
                <div>
                    <div 
                        className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                    >
                        <PrimaryButton
                            onClick={handleBackManzana}
                        >
                            REGRESAR
                        </PrimaryButton>
                    </div>
                </div>

                <Manzana1Div1 landSelector={landHandler} lands={lands} selected={landSelected}/>
                <div className="modal-container">
                {isVisible && (
                    <div className="modal-details-container" style={{ top: position.top, right: '100px' }}>
                    <ModalDetails
                        title='Detalles:'
                        onCloseModal={() => setIsVisible(false)}
                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                        land={landWatcher}
                        submit={handleSubmit(submit)}
                    >
                        <LandDetails land={landWatcher}/>
                    </ModalDetails>
                    </div>
                )}
                </div>

            </div>
            
            </>
        )
    }

    if (mapSelected.group === 2 && mapSelected.manzana === 1) {
        return <>
            <div className="relative">
                <div>
                    <div 
                        className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                    >
                        <PrimaryButton
                            onClick={handleBackManzana}
                        >
                            REGRESAR
                        </PrimaryButton>
                    </div>
                </div>
                <Manzana1Div2 landSelector={landHandler} lands={lands} selected={landSelected}/>
                <div className="modal-container">
                    {isVisible && (
                        <div className="modal-details-container" style={{ top: position.top, right: '100px' }}>
                        <ModalDetails
                            title='Detalles:'
                            onCloseModal={() => setIsVisible(false)}
                            type={window.innerWidth < 1000 ? 'medium' : 'small'}
                            land={landWatcher}
                            submit={handleSubmit(submit)}
                        >
                            <LandDetails land={landWatcher}/>
                        </ModalDetails>
                        </div>
                    )}
                </div>
            </div>
            
        </>
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 2) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana2Div1 
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, right: '100px' }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                    
                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 3) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana3Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 4) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana4Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                
                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 5) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana5Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 6) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana6Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 7) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana7Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                
                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 8) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>
                
                    <Manzana8Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                
                </div> 
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 9) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana9Div1 
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                
                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 10) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana10Div1 
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 11) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana11Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 12) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana12Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 13) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana13Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 14) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana14Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 15) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana15Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 2 && mapSelected.manzana === 15) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana15Div2
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 3 && mapSelected.manzana === 15) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana15Div3
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                
                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 16) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana16Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 17) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana17Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                
                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 18) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana18Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 19) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana19Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 20) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana20Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 21) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana21Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 22) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana22Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 23) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana23Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 24) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana24Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 25) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana25Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 26) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana26Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 27) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana27Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 28) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>
                    
                    <Manzana28Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                
                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 29) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana29Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>
                    
                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 30) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana30Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 31) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana31Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 32) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana32Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 33) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana33Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 34) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana34Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 35) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana35Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 2 && mapSelected.manzana === 35) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana35Div2
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 3 && mapSelected.manzana === 35) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana35Div3
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 36) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana36Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 37) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana37Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }

    if (mapSelected.group === 1 && mapSelected.manzana === 38) {
        return (
            <>
                <div className="relative">
                    <div>
                        <div 
                            className="absolute top-20 right-[100px] md:top-1/4 lg:top-10 z-5"
                        >
                            <PrimaryButton
                                onClick={handleBackManzana}
                            >
                                REGRESAR
                            </PrimaryButton>
                        </div>
                    </div>

                    <Manzana38Div1
                        landSelector={landHandler} 
                        lands={lands} 
                        selected={landSelected}
                    />
                    <div className="modal-container">
                        {
                            isVisible && (
                                <div className="modal-details-container" style={{ top: position.top, left: position.left }}>
                                    <ModalDetails
                                        title='Detalles:'
                                        onCloseModal={() => setIsVisible(false)}
                                        type={window.innerWidth < 1000 ? 'medium' : 'small'}
                                        land={landWatcher}
                                        submit={handleSubmit(submit)}
                                    >
                                        <LandDetails land={landWatcher}/>
                                    </ModalDetails>
                                </div>
                            )
                        }
                    </div>

                </div>
            
            </>
        )
    }
}