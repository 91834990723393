import { t } from "i18next";
import './Notifications.css';
import { getNotifications } from "../../../services/NotificationService";
import { useEffect, useState } from "react";
import AvatarDetails from "../../../assets/icons/AvatarDetails";
import { FaEllipsisH } from "react-icons/fa";

export const NotificationsDashboard = () => {

    const [notifications, setNotifications] = useState([]);
    const [selected, setSelected] = useState(null);

    const fetchData = async () => {
        const response = await getNotifications({status: 'unread'});
        setNotifications(response.data.data);
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="p-10">
            {/* Table header */}
            <div className="title-hero pb-5">
                {t('notifications.title')}
            </div>
            {/* Card of notifications */}
            <div className={selected ? "flex" : ""}>
            <div className={selected ? "bg-[#EEE] mt-5 w-1/5 background-hero" : "bg-[#EEE] mt-5 background-hero"}>
                <div className="flex items-center p-2 ml-2 header-text" style={{height: "5rem"}}>
                    {t('notifications.header')}
                </div>
                {/* Content */}
                <div className="bg-[#F1F3F4] rounded-md border-[#CCD2DD] border-r border-l">
                    {notifications?.map((notification, i) =>( 
                        <div
                            onClick={() => {
                                setSelected(notification);
                            }}
                            className={selected?.id === notification?.id ? `w-full flex align-baseline gap-7 p-2 cursor-pointer border-[#CCD2DD] border-b bg-blue-gradient ${ i !== notifications.length - 1 ? "rounded-md" : ""}` : `w-full flex align-baseline gap-7 p-2 cursor-pointer border-[#CCD2DD] border-b hover:bg-blue-gradient ${ i === notifications.length - 1 ? "rounded-md" : ""}`}
                        >
                            <AvatarDetails/>
                            <div className="flex items-center w-2/5 title-name">
                                {`${notification.from.name} ${notification.from.last_name} - ${notification.subject}`}
                            </div>
                            <div className="flex items-center justify-end w-full">
                                <FaEllipsisH className="text-primary " />
                            </div>
                        </div>)
                    )}
                </div>
            </div>
            {selected && (
            <div className="w-3/5 mt-5 rounded-md">
                {/* Notification header */}
                <div className="flex items-center p-2 pl-5 bg-blue-gradient gap-7" style={{height: "5rem"}}>
                    <AvatarDetails/>
                    <div className="flex items-center title-name">
                    {`${selected?.from?.name} - ${selected.subject}`}
                    </div>
                </div>
                <div className="bg-[#F1F3F4] h-full p-5">
                {selected.message}
                </div>
            </div>
            )}
            </div>
        </div>
    )
}