export const Manzana1Div2 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const getFill = (id) => {
        const land = lands.find(l => l.id === id);
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#5AE5F4";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg preserveAspectRatio="none" style={{width: '100%', height: '100vh'}} viewBox="0 0 1237 942" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1237" height="942" fill="url(#pattern0)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(22))} d="M445.099 145.828L497.827 129.508L509.844 168.46L456.936 184.238L445.099 145.828Z" fill={getFill(22)} fillOpacity="0.64"/>
        <path d="M457.268 183.618L445.724 146.158L497.497 130.134L509.217 168.125L457.268 183.618Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(21))} d="M560.061 153.402L509.844 168.46L497.827 129.508L547.865 114L560.061 153.402Z" fill={getFill(21)} fillOpacity="0.64"/>
        <path d="M498.452 129.838L547.536 114.626L559.434 153.068L510.175 167.838L498.452 129.838Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(23))} d="M571.81 191.632L560.062 153.402L509.845 168.46L521.951 207.501L571.81 191.632Z" fill={getFill(23)} fillOpacity="0.64"/>
        <path d="M510.472 168.794L559.73 154.024L571.186 191.306L522.279 206.872L510.472 168.794Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(24))} d="M456.937 184.239L468.505 221.386L521.233 205.427L509.844 168.46L456.937 184.239Z" fill={getFill(24)} fillOpacity="0.64"/>
        <path d="M468.834 220.764L457.564 184.573L509.512 169.08L520.607 205.094L468.834 220.764Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(25))} d="M571.809 191.632L583.736 230.403L533.967 246.723L521.95 207.501L571.809 191.632Z" fill={getFill(25)} fillOpacity="0.64"/>
        <path d="M522.573 207.827L571.48 192.261L583.114 230.081L534.295 246.089L522.573 207.827Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(26))} d="M468.503 221.386L480.43 259.797L532.889 243.206L521.231 205.427L468.503 221.386Z" fill={getFill(26)} fillOpacity="0.64"/>
        <path d="M480.758 259.168L469.13 221.719L520.9 206.05L532.265 242.879L480.758 259.168Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(27))} d="M583.735 230.403L595.572 268.542L545.803 284.952L533.966 246.723L583.735 230.403Z" fill={getFill(27)} fillOpacity="0.64"/>
        <path d="M534.589 247.044L583.408 231.036L594.949 268.221L546.13 284.318L534.589 247.044Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(28))} d="M480.432 259.796L492.179 297.936L544.369 280.444L532.891 243.206L480.432 259.796Z" fill={getFill(28)} fillOpacity="0.64"/>
        <path d="M481.056 260.123L532.562 243.835L543.748 280.125L492.506 297.299L481.056 260.123Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(29))} d="M595.573 268.542L607.589 307.764L557.641 323.543L545.804 284.952L595.573 268.542Z" fill={getFill(29)} fillOpacity="0.64"/>
        <path d="M546.425 285.274L595.245 269.177L606.966 307.437L557.971 322.914L546.425 285.274Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(30))} d="M492.177 297.936L503.924 335.715L556.294 319.125L544.367 280.444L492.177 297.936Z" fill={getFill(30)} fillOpacity="0.64"/>
        <path d="M555.67 318.798L504.252 335.087L492.799 298.255L544.04 281.081L555.67 318.798Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(31))} d="M607.588 307.764L619.694 346.805L569.656 362.494L557.64 323.543L607.588 307.764Z" fill={getFill(31)} fillOpacity="0.64"/>
        <path d="M558.264 323.87L607.26 308.392L619.069 346.477L569.986 361.866L558.264 323.87Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(32))} d="M503.925 335.716L515.403 372.863L567.863 356.544L556.295 319.125L503.925 335.716Z" fill={getFill(32)} fillOpacity="0.64"/>
        <path d="M504.549 336.042L555.966 319.754L567.238 356.214L515.733 372.237L504.549 336.042Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(33))} d="M619.695 346.806L631.352 384.765L581.493 400.634L569.656 362.495L619.695 346.806Z" fill={getFill(33)} fillOpacity="0.64"/>
        <path d="M570.282 362.823L619.364 347.434L630.729 384.439L581.822 400.005L570.282 362.823Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(34))} d="M515.403 372.863L527.33 411.453L579.341 393.691L567.863 356.543L515.403 372.863Z" fill={getFill(34)} fillOpacity="0.64"/>
        <path d="M516.028 373.192L567.533 357.169L578.72 393.375L527.656 410.814L516.028 373.192Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(35))} d="M631.351 384.765L643.368 423.806L593.419 439.495L581.492 400.634L631.351 384.765Z" fill={getFill(35)} fillOpacity="0.64"/>
        <path d="M642.743 423.478L593.749 438.867L582.115 400.96L631.022 385.394L642.743 423.478Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(36))} d="M527.329 411.454L539.076 449.233L591.357 432.733L579.34 393.692L527.329 411.454Z" fill={getFill(36)} fillOpacity="0.64"/>
        <path d="M527.951 411.77L579.014 394.332L590.733 432.406L539.405 448.605L527.951 411.77Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(37))} d="M643.369 423.806L655.206 462.216L605.347 478.085L593.42 439.495L643.369 423.806Z" fill={getFill(37)} fillOpacity="0.64"/>
        <path d="M594.045 439.823L643.039 424.434L654.582 461.89L605.675 477.456L594.045 439.823Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(38))} d="M539.077 449.233L550.735 487.012L602.925 470.151L591.357 432.733L539.077 449.233Z" fill={getFill(38)} fillOpacity="0.64"/>
        <path d="M602.302 469.827L551.063 486.38L539.701 449.56L591.028 433.361L602.302 469.827Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(39))} d="M655.205 462.217L667.311 501.258L617.362 517.127L605.346 478.086L655.205 462.217Z" fill={getFill(39)} fillOpacity="0.64"/>
        <path d="M605.969 478.412L654.876 462.846L666.686 500.932L617.692 516.498L605.969 478.412Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(40))} d="M550.734 487.012L562.392 524.611L614.583 508.111L602.925 470.151L550.734 487.012Z" fill={getFill(40)} fillOpacity="0.64"/>
        <path d="M613.959 507.783L562.721 523.982L551.358 487.336L602.596 470.783L613.959 507.783Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(41))} d="M667.312 501.258L679.059 539.488L629.2 555.357L617.363 517.127L667.312 501.258Z" fill={getFill(41)} fillOpacity="0.64"/>
        <path d="M678.436 539.162L629.529 554.728L617.988 517.454L666.982 501.888L678.436 539.162Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(42))} d="M562.394 524.61L574.141 562.57L626.78 547.422L614.584 508.11L562.394 524.61Z" fill={getFill(42)} fillOpacity="0.64"/>
        <path d="M574.474 561.953L563.018 524.937L614.255 508.738L626.151 547.083L574.474 561.953Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(43))} d="M679.059 539.488L691.165 578.8L641.127 594.128L629.2 555.357L679.059 539.488Z" fill={getFill(43)} fillOpacity="0.64"/>
        <path d="M641.458 593.503L629.824 555.683L678.73 540.117L690.54 578.468L641.458 593.503Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(44))} d="M574.14 562.57L585.887 600.349L638.077 584.3L626.779 547.423L574.14 562.57Z" fill={getFill(44)} fillOpacity="0.64"/>
        <path d="M586.216 599.725L574.769 562.91L626.444 548.039L637.453 583.969L586.216 599.725Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(45))} d="M691.165 578.8L702.913 616.939L653.054 632.899L641.127 594.128L691.165 578.8Z" fill={getFill(45)} fillOpacity="0.64"/>
        <path d="M641.752 594.459L690.834 579.424L702.289 616.614L653.383 632.268L641.752 594.459Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(46))} d="M585.888 600.348L597.635 638.218L649.826 622.258L638.078 584.299L585.888 600.348Z" fill={getFill(46)} fillOpacity="0.64"/>
        <path d="M597.965 637.594L586.514 600.679L637.748 584.924L649.2 621.927L597.965 637.594Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(48))} d="M702.912 616.939L715.018 656.161L665.159 672.03L653.053 632.898L702.912 616.939Z" fill={getFill(48)} fillOpacity="0.64"/>
        <path d="M653.677 633.224L702.583 617.569L714.394 655.835L665.487 671.4L653.677 633.224Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(47))} d="M597.634 638.218L609.65 677.079L661.482 659.948L649.824 622.259L597.634 638.218Z" fill={getFill(47)} fillOpacity="0.64"/>
        <path d="M609.977 676.445L598.26 638.55L649.494 622.883L660.859 659.627L609.977 676.445Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(50))} d="M715.017 656.161L726.765 694.12L677.085 710.53L665.158 672.03L715.017 656.161Z" fill={getFill(50)} fillOpacity="0.64"/>
        <path d="M665.783 672.356L714.689 656.79L726.142 693.799L677.412 709.896L665.783 672.356Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(49))} d="M609.651 677.079L621.219 714.407L673.231 698.178L661.483 659.948L609.651 677.079Z" fill={getFill(49)} fillOpacity="0.64"/>
        <path d="M672.606 697.849L621.549 713.781L610.274 677.4L661.155 660.583L672.606 697.849Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(52))} d="M726.767 694.121L738.783 733.343L688.834 748.671L677.087 710.531L726.767 694.121Z" fill={getFill(52)} fillOpacity="0.64"/>
        <path d="M677.709 710.852L726.438 694.756L738.158 733.011L689.165 748.046L677.709 710.852Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(51))} d="M621.221 714.408L632.968 752.458L684.89 736.048L673.232 698.178L621.221 714.408Z" fill={getFill(51)} fillOpacity="0.64"/>
        <path d="M621.846 714.737L672.902 698.805L684.266 735.72L633.297 751.829L621.846 714.737Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(54))} d="M738.782 733.342L750.708 771.933L700.76 787.531L688.833 748.67L738.782 733.342Z" fill={getFill(54)} fillOpacity="0.64"/>
        <path d="M689.458 749.002L738.451 733.967L750.083 771.604L701.09 786.904L689.458 749.002Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(53))} d="M632.967 752.457L644.714 790.417L696.546 773.917L684.888 736.047L632.967 752.457Z" fill={getFill(53)} fillOpacity="0.64"/>
        <path d="M633.591 752.784L684.559 736.676L695.922 773.59L645.043 789.788L633.591 752.784Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(56))} d="M750.709 771.932L762.636 810.613L712.777 826.302L700.761 787.531L750.709 771.932Z" fill={getFill(56)} fillOpacity="0.64"/>
        <path d="M701.386 787.859L750.379 772.559L762.012 810.285L713.106 825.674L701.386 787.859Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(55))} d="M644.714 790.417L656.372 827.925L707.934 812.147L696.546 773.917L644.714 790.417Z" fill={getFill(55)} fillOpacity="0.64"/>
        <path d="M656.701 827.302L645.339 790.743L696.212 774.548L707.313 811.814L656.701 827.302Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(58))} d="M762.635 810.613L774.652 849.384L724.703 864.892L712.776 826.302L762.635 810.613Z" fill={getFill(58)} fillOpacity="0.64"/>
        <path d="M713.401 826.629L762.306 811.241L774.026 849.055L725.033 864.266L713.401 826.629Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(57))} d="M656.371 827.926L668.118 865.885L719.771 849.475L707.934 812.147L656.371 827.926Z" fill={getFill(57)} fillOpacity="0.64"/>
        <path d="M668.447 865.256L656.997 828.257L707.607 812.77L719.143 849.15L668.447 865.256Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(60))} d="M774.652 849.384L789 895.999H734.298L724.703 864.892L774.652 849.384Z" fill={getFill(60)} fillOpacity="0.64"/>
        <path d="M725.328 865.221L774.321 850.01L788.323 895.499H734.667L725.328 865.221Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandById(59))} d="M668.119 865.885L677.445 896H734.299L719.772 849.475L668.119 865.885Z" fill={getFill(59)} fillOpacity="0.64"/>
        <path d="M677.814 895.5L668.744 866.211L719.444 850.104L733.619 895.5H677.814Z" stroke="#6D6859" strokeOpacity="0.8"/>
        <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1546_3" transform="matrix(0.00123481 0 0 0.0016215 -3.22785 -2.196)"/>
        </pattern>
        <image id="image0_1546_3" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}