import React, { useCallback, useEffect, useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import Loading from "../../../../../components/loading/Loading";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import Icon from "../../../../../components/Icon";
import { getSellersTop } from "../../../../../services/dashboardService";

const Header = () => {
  const { t } = useTranslation();

  return (
    <div className="gradient-background header">
      <p className="text-white font-medium">{t("sellersTable.title")}</p>
      <Icon icon="star-badge" size={40} color="white" />
    </div>
  );
};

const SellersTable = ({ role, random, onScroll }) => {
  const [loading, setLoading] = useState(false);

  const [sellersTop, setShowSellersTop] = useState([]);

  const { t } = useTranslation();

  const handleArrowClick = useCallback(
    (sellerSelected, show) => {
      setShowSellersTop(
        sellersTop.map((seller) => {
          if (seller.seller_id === sellerSelected.seller_id) {
            return {
              ...seller,
              show: show,
            };
          }
          return seller;
        })
      );
    },
    [sellersTop]
  );

  useEffect(() => {
    setLoading(true);
    getSellersTop()
      .then((response) => {
        const data = response.data.map((seller) => {
          return {
            ...seller,
            show: false,
          };
        });
        setShowSellersTop(data);
      })
      .catch((error) => {
        setShowSellersTop([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="sellers-table">
      {loading && <Loading />}

      {/* Vista para móvil */}
      <div className="block lg:hidden">
        <Header />
        {_.map(sellersTop, (seller, index) => (
          <div key={index} className="card-info">
            <div className="flex justify-between px-4">
              <div className="text-sm">
                {seller.name} {seller.last_name}
              </div>
              <div>
                {!seller.show && (
                  <BiSolidDownArrow
                    onClick={() => handleArrowClick(seller, true)}
                  />
                )}
                {seller.show && (
                  <BiSolidUpArrow
                    onClick={() => handleArrowClick(seller, false)}
                  />
                )}
              </div>
            </div>
            {seller.show && (
              <div>
                <div className="grid grid-cols-2 gap-4  my-2">
                  <div className="field-title">ID</div>
                  <div className="text-left field-value">
                    {seller.seller_id}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4  my-2">
                  <div className="field-title">
                    {t("sellersTable.soldLands")}
                  </div>
                  <div className="text-left field-value">
                    {seller.lands_count}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-4  my-2">
                  <div className="field-title">{t("sellersTable.client")}</div>
                  <div className="text-left field-value"></div>
                </div>
              </div>
            )}
          </div>
        ))}
        <div className="mt-1">&nbsp;</div>
      </div>

      {/* Vista para web */}
      <div className="hidden lg:block">
        <div className="table-section shadow rounded-lg">
          <Header />
          <table className="w-full table-auto" style={{ padding: "0 20px" }}>
            <thead className="text-black bg-header">
              <tr>
                <td className="p-2">ID</td>
                <td className="p-2">{t("sellersTable.name")}</td>
                <td className="p-2">{t("sellersTable.soldLands")}</td>
                <td className="p-2">{t("sellersTable.agency")}</td>
              </tr>
            </thead>
            <tbody className="text-cell rounded-lg">
              {_.map(sellersTop, (seller, index) => (
                <tr
                  className={`border-b border-white text-sm ${
                    index % 2 === 0 ? "bg-white" : "bg-header"
                  }`}
                  key={seller?.seller_id}
                >
                  <td className="p-2 text-primary">{seller?.seller_id}</td>
                  <td className="p-2 text-black">
                    {seller.name} {seller.last_name}
                  </td>
                  <td className="p-2 text-black">{seller.lands_count}</td>
                  <td className="p-2">{!!seller?.organization_user_id ? seller?.name : ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SellersTable;
