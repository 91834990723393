import React from "react";
import "./LineChart.css";
import ReactEcharts from "echarts-for-react";
import { graphic } from "echarts";

const defaultColor = [
  { offset: 0, color: "rgba(252, 227, 138, 0.8)" },
  { offset: 0.5, color: "rgba(255, 147, 47, 0.9)" },
  { offset: 0.6, color: "rgba(255, 147, 47, 0.9)" },
  { offset: 1, color: "rgba(255, 147, 47, 0.9)" },
];

export default function LineChart({
  title,
  bgColor1,
  bgColor2,
  chartData = {},
  linearGradientColor = defaultColor,
}) {
  const options = {
    title: {
      text: title,
      show: true,
      top: 10,
      left: 10,
      textStyle: {
        fontSize: 16,
        fontWeight: "400",
        color: "#4C4C4C",
      },
    },
    grid: { top: 70, right: 20, bottom: 40, left: 40 },
    xAxis: {
      type: "category",
      data: chartData?.labels,
      axisLine: {
        lineStyle: { color: "#ddd" },
      },
      axisLabel: {
        color: "#888",
        fontSize: 6.5,
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        lineStyle: { color: "#eee" },
      },
      axisLabel: {
        color: "#888",
        fontSize: 6.5,
      },
    },
    series: [
      {
        data: chartData?.values,
        type: "line",
        smooth: true,
        symbol: "circle",
        smoothMonotone: "x",
        symbolSize: function (value) {
          return value === 0 ? 0 : 5;
        },
        itemStyle: {
          color: "#C68A52",
          borderWidth: 0,
          borderColor: "#fff",
        },
        lineStyle: {
          color: "#C68A52",
          width: 0,
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 1, 0, linearGradientColor),
        },
      },
    ],
    tooltip: {
      trigger: "axis",
      backgroundColor: "rgba(255, 255, 255, 0.9)",
      borderColor: "#ddd",
      borderWidth: 1,
      textStyle: {
        color: "#333",
      },
    },
  };

  return (
    <ReactEcharts
      option={options}
      style={{
        height: "100%",
        width: "100%",
        borderRadius: 12,
        backgroundColor: "#FFF",
        marginRight: 20,
      }}
    />
  );
}
