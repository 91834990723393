import { get } from "lodash";
import { getFillByLot } from "./Manzana2Div1";

export const Manzana35Div2 = ({ lands, landSelector, selected }) => {
  const findLandById = (id) => {
    return lands.filter((l) => l.id === id)[0];
  };

  const findLandByLot = (lot) => {
    return lands.filter((l) => l.lote === lot)[0];
  };

  const getFill = (id) => {
    const land = lands.filter((l) => l.id === id)[0];
    const disabledColor = "rgba(223, 250, 255, 0.48)";
    const normalColor = "#0096B3";
    const selectedColor = "#DFFAFF";

    if (!land) {
      return disabledColor; // disabled
    }

    if (selected) {
      if (id === selected.id) {
        return selectedColor;
      }
    }

    if (land.status !== "on_sale") {
      return disabledColor; // disabled
    } else {
      return normalColor; // normal color
    }
  };

  if (!lands) {
    return <></>;
  }

  return (
    <svg
      width="1219"
      height="941"
      viewBox="0 0 1219 941"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1219" height="941" fill="url(#pattern0_1882_3775)" />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("21"))}
        d="M686.718 399.351L678.158 439.066H611.738L620.64 399.351H686.718Z"
        fill={getFillByLot(21, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("18"))}
        d="M652.48 526.711L639.812 556.155L567.229 511.647L582.636 483.572L652.48 526.711Z"
        fill={getFillByLot(18, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("19"))}
        d="M663.778 494.185L652.48 527.053L582.979 483.914L600.782 453.785L663.778 494.185Z"
        fill={getFillByLot(19, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("20"))}
        d="M611.396 438.723L600.782 453.787L663.778 494.529L678.158 438.723H611.396Z"
        fill={getFillByLot(20, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("17"))}
        d="M566.888 511.305L550.454 537.667L627.83 583.203L639.813 555.813L566.888 511.305Z"
        fill={getFillByLot(17, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("15"))}
        d="M600.099 634.558L585.377 659.551L508.001 612.304L522.381 587.311L600.099 634.558Z"
        fill={getFillByLot(15, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("15"))}
        d="M614.478 609.565L600.098 634.9L522.38 587.653L537.444 561.633L614.478 609.565Z"
        fill={getFillByLot(15, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("16"))}
        d="M550.453 537.668L537.443 561.634L614.477 609.566L627.487 583.203L550.453 537.668Z"
        fill={getFillByLot(16, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("13"))}
        d="M585.377 659.209L568.258 682.832L496.36 636.612L508.001 612.304L585.377 659.209Z"
        fill={getFillByLot(13, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("10"))}
        d="M529.911 731.107L506.972 757.127L445.346 718.439L462.122 689.68L529.911 731.107Z"
        fill={getFillByLot(10, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("11"))}
        d="M479.242 664.002L462.123 690.022L529.912 731.107L550.455 707.141L479.242 664.002Z"
        fill={getFillByLot(11, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("12"))}
        d="M568.257 682.833L550.453 707.142L479.24 663.661L496.359 636.613L568.257 682.833Z"
        fill={getFillByLot(12, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <defs>
        <pattern
          id="pattern0_1882_3775"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1882_3775"
            transform="matrix(0.00096301 0 0 0.00124814 -1.97992 -0.304235)"
          />
        </pattern>
        <image
          id="image0_1882_3775"
          width="4096"
          height="2304"
          href="/mz1.png"
        />
      </defs>
    </svg>
  );
};
