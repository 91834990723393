export const ProgressBar = ({progress = '25'}) => {
    return (
        <>
        <div className="w-full rounded-md bg-neutral-200" style={{borderRadius: 2}}>
        <div
            className="bg-primary p-0.5 h-5 text-center text-xs font-medium leading-none text-primary-100 rounded-s-md bg-[#2BBFDC]"
            style={{width: `${progress}%`, borderRadius: 2, backgroundColor: '#2BBFDC'}}>
        </div>
        </div>
        </>
    )
}