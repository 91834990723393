import { getFillByLot } from "./Manzana2Div1";

export const Manzana9Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1218" height="941" viewBox="0 0 1218 941" fill="none" xmlns="http://www.w3.org/2000/svg" >
        <rect width="1218" height="941" fill="url(#pattern0_1862_4594)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('8'))}
            d="M635.297 573.008H691.153L673.698 517.152H635.297V573.008Z" 
            fill={getFillByLot('8', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('7'))}
            d="M615.583 517.153H673.082L657.475 461.297H615.583V517.153Z" 
            fill={getFillByLot('7', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('9'))}
            d="M584.369 573.008H635.297V517.152H584.369V573.008Z" 
            fill={getFillByLot('9', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('6'))}
            d="M564.655 517.153H615.583V461.297H564.655V517.153Z" 
            fill={getFillByLot('6', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('5'))}
            d="M513.727 517.153H564.654V461.297H513.727V517.153Z" 
            fill={getFillByLot('5', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('4'))}
            d="M462.799 517.153H513.726V461.297H462.799V517.153Z" 
            fill={getFillByLot('4', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('3'))}
            d="M411.872 517.153H462.8V461.297H411.872V517.153Z" 
            fill={getFillByLot('3', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M360.944 517.153H411.872V461.297H360.944V517.153Z" 
            fill={getFillByLot('2', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('10'))}
            d="M536.725 573.008H584.366V517.152H536.725V573.008Z" 
            fill={getFillByLot('10', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('11'))}
            d="M487.44 573.008H536.725V517.152H487.44V573.008Z" 
            fill={getFillByLot('11', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('12'))}
            d="M438.158 573.008H487.443V517.152H438.158V573.008Z" 
            fill={getFillByLot('12', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('13'))}
            d="M388.873 573.008H438.158V517.152H388.873V573.008Z" 
            fill={getFillByLot('13', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('1'))}
            d="M300.16 461.297H360.945V517.153H329.731L300.16 461.297Z" 
            fill={getFillByLot('1', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('14'))}
            d="M351.908 573.008L329.729 517.152H388.871V573.008H351.908Z" 
            fill={getFillByLot('14', selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <defs>
        <pattern id="pattern0_1862_4594" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1862_4594" transform="matrix(0.00136126 0 0 0.00176285 -3.568 -2.81432)"/>
        </pattern>
        <image id="image0_1862_4594" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}