import React, { createContext, useContext, useEffect, useState } from "react";
import { theme, themeRoles } from "../utils/theme";
import { useAuth } from "../hooks/useAuth";
import { roles } from "../constants/roles";

const roleAdapter = (role) => {
  switch (role) {
    case roles.SUPER_ADMIN:
      return themeRoles.SUPER_ADMINISTRATOR;
    case roles.ADMIN:
      return themeRoles.ADMINISTRATOR;
    case roles.SELLER:
      return themeRoles.SELLER;
    case roles.CLIENT:
      return themeRoles.CLIENT;
    case roles.COORDINATOR:
      return themeRoles.COORDINATOR;
    case roles.ORGANIZATION:
      return themeRoles.ORGANIZATION;
    default:
      return themeRoles.CLIENT;
  }
};

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { user } = useAuth();

  const [currentTheme, setCurrentTheme] = useState(theme.client);
  const updateTheme = (role) => {
    setCurrentTheme(theme[roleAdapter(role || roles.CLIENT)]);
  };

  useEffect(() => {
    const activeRole = user?.user?.roles
      ? user.user.roles?.[0]?.name?.toLowerCase()
      : "";
    if (!activeRole) {
      return;
    }

    updateTheme(activeRole);
  }, [user]);

  return (
    <ThemeContext.Provider value={{ currentTheme, updateTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
