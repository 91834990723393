import Card from "../../../../../components/card/Card";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../../../components/primary/PrimaryButton";
import ErrorIcon from "../../../../../assets/icons/Error";
import { useCallback } from "react";

export const DocumentUploadError = () => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <div className="flex flex-col justify-center w-full items-center">
      <Card className="p-10 mb-10 sm:p-4 md:p-20 w-[300px] md:w-[600px] gl:w-[600px] mt-20">
        <div className="flex justify-center p-10 md:p-0">
          <ErrorIcon />
        </div>
        <div className="flex flex-col justify-center mt-2 w-full text-center">
          <h1 className="text-[#4E4E4E] text-[14px] md:text-[38px]">
            Error de Carga
          </h1>
          <h2 className="text-[#203459] text-[13px] md:text-[16px]">
            Tu documento no ha sido aprobado, revisa el proceso
          </h2>
          <p
            onClick={handleGoBack}
            className="text-[13px] md:text-[16px] underline text-[#0096B3] mt-1"
            style={{ pointerEvents: "none" }}
          >
            Intentalo de nuevo
          </p>
        </div>
      </Card>
      <div className="flex justify-center">
        <PrimaryButton
          className={"cursor-pointer shadow-md"}
          onClick={handleGoBack}
        >
          Regresar
        </PrimaryButton>
      </div>
    </div>
  );
};
