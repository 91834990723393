import api from "../api/axios";
import { endpoints } from "../api/endpoints";

export const getSalesByStatus = async (params) => {
  try {
    return await api.get(endpoints.salesBySection, { params });
  } catch (error) {
    return error.message;
  }
};

export const getAllCluster = async (params) => {
  try {
    return await api.get(endpoints.allCluster, { params });
  } catch (error) {
    return error.message;
  }
};
