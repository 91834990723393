import { getFillByLot } from "./Manzana2Div1";

export const Manzana12Div1 = ({ lands, landSelector, selected }) => {
  const findLandById = (id) => {
    return lands.filter((l) => l.id === id)[0];
  };

  const findLandByLot = (lot) => {
    return lands.filter((l) => l.lote === lot)[0];
  };

  const getFill = (id) => {
    const land = lands.filter((l) => l.id === id)[0];
    const disabledColor = "rgba(223, 250, 255, 0.48)";
    const normalColor = "#0096B3";
    const selectedColor = "#DFFAFF";

    if (!land) {
      return disabledColor; // disabled
    }

    if (selected) {
      if (id === selected.id) {
        return selectedColor;
      }
    }

    if (land.status !== "on_sale") {
      return disabledColor; // disabled
    } else {
      return normalColor; // normal color
    }
  };

  if (!lands) {
    return <></>;
  }

  return (
    <svg
      width="1219"
      height="941"
      viewBox="0 0 1219 941"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1219" height="941" fill="url(#pattern0_1874_4775)" />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("8"))}
        d="M573.338 672.667C578.574 670.485 600.973 652.122 611.518 643.214L565.156 591.943L529.704 619.215L573.338 672.667Z"
        fill={getFillByLot(8, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("5"))}
        d="M648.061 601.757C659.515 590.303 670.241 568.304 675.332 556.486L614.79 527.033C610.426 539.251 598.063 556.305 592.427 563.031L648.061 601.757Z"
        fill={getFillByLot(5, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("9"))}
        d="M611.516 643.209C623.734 635.791 640.97 612.847 648.06 602.302L592.426 563.031C584.245 574.485 572.246 585.394 565.155 591.394L611.516 643.209Z"
        fill={getFillByLot(9, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("7"))}
        d="M485.522 567.939C495.122 566.194 514.975 549.394 523.702 541.213L569.518 587.029C558.609 600.119 540.61 611.573 529.702 619.209L485.522 567.939Z"
        fill={getFillByLot(7, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("8"))}
        d="M569.518 587.03C583.918 576.122 597.881 556.486 605.517 545.578L549.883 507.943C544.647 520.597 530.248 535.033 523.702 540.669L569.518 587.03Z"
        fill={getFillByLot(8, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("3"))}
        d="M605.518 545.578C613.699 533.579 623.517 507.944 629.517 489.944L566.247 471.945C564.502 479.799 554.611 499.399 549.884 507.944L605.518 545.578Z"
        fill={getFillByLot(3, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("4"))}
        d="M675.332 556.486C685.695 540.668 689.513 522.124 694.422 505.215L629.516 489.943C627.334 499.543 618.789 518.669 614.789 527.032L675.332 556.486Z"
        fill={getFillByLot(4, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("2"))}
        d="M694.421 505.217C698.348 495.618 700.785 470.674 701.512 459.401L636.061 441.402C635.515 455.583 632.061 478.855 629.515 489.945L694.421 505.217Z"
        fill={getFillByLot(2, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("1"))}
        d="M586.974 429.408C589.592 429.408 620.79 437.408 636.062 441.408C634.317 468.461 630.972 485.042 629.517 489.951L566.248 471.952C571.702 458.861 567.884 446.316 570.611 438.68C573.338 431.044 583.701 429.408 586.974 429.408Z"
        fill={getFillByLot(1, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <defs>
        <pattern
          id="pattern0_1874_4775"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1874_4775"
            transform="matrix(0.00153416 0 0 0.0019884 -3.80415 -2.95802)"
          />
        </pattern>
        <image
          id="image0_1874_4775"
          width="4096"
          height="2304"
          href="/mz1.png"
        />
      </defs>
    </svg>
  );
};
