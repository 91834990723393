import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './DashboardCoordinator.css'
import { useTranslation } from 'react-i18next';
import LineChart from '../../../../../components/charts/lineChart/LineChart';
import { roles } from '../../../../../constants/roles';
import Modal from '../../../../../components/modal/Modal';
import Register from '../../../register/Register';
import { Selector } from '../../../../../components/selector/Selector';
import { useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../../../components/primary/PrimaryButton';
import { getClientsChart, getGroupeLands, getLandsSales, getOrganizationSales, getSalesChart, getSellerSignUp } from '../../../../../services/dashboardService';
import CircleChart from '../../../../../components/charts/CircleChart';
import { LandStatus, sumValues } from '../dashboardSuperAdmin/DashboardSuperAdmin';
import SvgChart from '../../../../../components/charts/SvgChart';
import SellersTable from '../SellersTable';
import { capitalized } from '../../../../../utils';
import { useDispatch } from 'react-redux';
import { selectClient } from '../../../../../features/userSlice/userSlice';
import { useNavigate } from 'react-router-dom';

const heightRegistrationForm = (type) => {
  if(type === 'cliente') {
    return '700px'
  }
  if(type === 'organizacion') {
    return '735px'

  }
  if (type === "vendedor") {
    return "615px";
  }

  return '615px';
}

export default function DashboardCoordinator() {

  const [random, setRandom] = useState(0);
  const [type, setType] = useState('');
  const [chartValues, setChartValues] = useState({
    totalSales: [],
    orgSales: [],
    clients: []
  });

  const [lands, setLands] = useState({
    [LandStatus.AVAILABLE]: {
      value: 0,
    },
    [LandStatus.UNAVAILABLE]: {
      value: 0,
    },
    [LandStatus.SEPARATED]: {
      value: 0,
    },
    [LandStatus.SOLD]: {
      value: 0,
    },
  });

  const [landSales, setLandSales] = useState({
    sold: 0,
    inProgress: 0,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const ref = useRef(null);
  const currentDate = new Date();

  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, index) => {
      const monthValue = index + 1;
      return {
        label: capitalized(new Date(2023, index, 1).toLocaleString(i18n.language === 'es' ? i18n.language : 'default', { month: 'long' })),
        value: monthValue,
      };
    });
  }, []);

  const {
    control,
    handleSubmit,
    watch
  } = useForm({defaultValues: {month: currentDate.getMonth() + 1}});

  const selectedMonth = watch('month');

  const landsToOptions = useMemo(() => {
    return [
      {
        value: lands.available.value,
        name: t("landChart.available"),
        itemStyle: { color: "#43E1FA", bottom: 10 },
      },
      {
        value: lands.unavailable.value,
        name: t("landChart.unavailable"),
        itemStyle: { color: "#5FA8B4" },
      },

      {
        value: lands.separated.value,
        name: t("landChart.reserved"),
        itemStyle: { color: "#C2EAF0" },
      },

      {
        value: lands.sold.value,
        name: t("landChart.sold"),
        itemStyle: { color: "#3CCAE0" },
      },
    ];
  }, [lands.available.value, lands.separated.value, lands.sold.value, lands.unavailable.value, t]);


  const submit = useCallback(async (data) => {
    // Handle form submission here
    try {
      const params = {month: data.month?.value ? data.month?.value : data.month};

      // fetch data
      const respTotalSales = await getSalesChart(params);
      const respOrganizationSales = await getOrganizationSales(params);
      const respClients = await getClientsChart(params);
      const respSellers = await getSellerSignUp(params);

      setChartValues({
        ...chartValues,
        totalSales: respTotalSales.data,
        orgSales: respOrganizationSales.data,
        clients: respClients.data,
        sellers: respSellers.data
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  /**
   * Handle add user to show form
   */
  const handleAdd = useCallback((theType) => {
    setType('cliente');
  }, []);

   /**
   * Handle close modal and reload users list
   */
   const handleCloseModal = useCallback(() => {
    setType('');
  }, []);

  /**
   * Handle success in form
   */
  const handleSuccess = useCallback(
    (user) => {
      setType("");
      dispatch(selectClient(user));

      setTimeout(() => {
        handleCloseModal();
        navigate(`/admin/user-process/${user.id}`);
      }, 1000);
    },
    [dispatch, handleCloseModal, navigate]
  );

  useEffect(() => {
    const month = selectedMonth?.value || selectedMonth;

    if (!month) {
      return;
    }
    getGroupeLands({
      month: month,
    }).then((response) => {
      setLands({
        available: {
          value: sumValues(response?.data?.available?.values),
        },
        unavailable: {
          value: sumValues(response?.data?.unavailable?.values),
        },
        separated: {
          value: sumValues(response?.data?.reserved?.values),
        },
        sold: {
          value: sumValues(response?.data?.sold?.values),
        },
      });
    });
  }, [selectedMonth]);

  useEffect(() => {
    const month = selectedMonth?.value || selectedMonth;

    if (!month) {
      return;
    }

    getLandsSales({
      month: month,
    }).then((response) => {
      const {
        data: { sold, inPaymentProcess },
      } = response;
      setLandSales({
        sold: Number(sold),
        inProgress: Number(inPaymentProcess),
      });
    });
  }, [selectedMonth]);

  useEffect(() => {
    setRandom(Math.random());
  }, []);

  useEffect(() => {
    if (selectedMonth) {
      handleSubmit(submit)();
    }
  }, [selectedMonth, handleSubmit, submit]);

  return (
    <div className='p-5 DashboardCoordinator max-w-screen-xl' ref={ref}>
      <h3 className='mt-8 title'>
        { t('dashboardPage.coordinator.title') }
      </h3>

      <div className="pl-0 pr-4 md:pr-2 mt-5 lg:pl-4 lg:pr-4">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-3 lg:justify-between mt-10 mb-10">
          <div className="w-full lg:w-1/6">
            <form>
              <Selector
                label=""
                name={"month"}
                defaultValue={
                  months.filter(
                    (r) => r.value === currentDate.getMonth() + 1
                  )[0]
                }
                control={control}
                options={months}
                isNewVersion
              />
            </form>
          </div>
          <PrimaryButton className="shadow" onClick={handleAdd}>
            {t("dashboardPage.superAdmin.newAdmin")}
          </PrimaryButton>
        </div>
        <div className="chart-container">
          <div className="mt-4 lg:mt-0 chart">
            <CircleChart data={landsToOptions} title={t("landChart.lands")} />
          </div>
          <div className="mt-4 lg:mt-0 chart">
            <SvgChart
              title={t("landChart.totalPayments")}
              data={{
                column1: {
                  title: t("landChart.paymentsInProgress"),
                  value: landSales.inProgress,
                },
                column2: {
                  title: t("landChart.completeLandPayments"),
                  value: landSales.sold,
                },
              }}
            />
          </div>
          <div className="mt-4 lg:mt-0 chart">
            <LineChart
              title={t("landChart.totalSales")}
              bgColor1={"rgba(146, 224, 235, 1)"}
              bgColor2={"rgba(34, 159, 176, 1)"}
              chartData={chartValues?.totalSales}
            />
          </div>
          <div className="mt-4 lg:mt-10 chart">
            <LineChart
              title={"Ventas por organización"}
              bgColor1={"rgba(146, 224, 235, 1)"}
              bgColor2={"rgba(34, 159, 176, 1)"}
              chartData={chartValues.orgSales}
            />
          </div>
          <div className="mt-4 lg:mt-10 chart">
            <LineChart
              title={"Vendedores registrados"}
              bgColor1={"rgba(146, 224, 235, 1)"}
              bgColor2={"rgba(34, 159, 176, 1)"}
              chartData={chartValues.sellers}
            />
          </div>
          <div className="mt-4 lg:mt-10 chart">
            <LineChart
              title={"Clientes registrados"}
              bgColor1={"rgba(146, 224, 235, 1)"}
              bgColor2={"rgba(34, 159, 176, 1)"}
              chartData={chartValues.clients}
            />
          </div>
        </div>
        <div className="table-container">
          <SellersTable
            role={roles.ADMIN}
            random={random}
            onScroll={scrollToTop}
          />
        </div>
      </div>
      <div className='pl-4 mt-5'>
        {/*<div className='flex items-center justify-start gap-3'>
          <div className='w-1/5'>*/}
          {/* Month selector */}
          {/*<form>
            <Selector label='' name={'month'} defaultValue={months.filter(r => r.value === currentDate.getMonth() + 1)[0]} control={control} options={months} />
          </form>
          </div>*/}
          {/* Buttons */}
          {/*<div className='flex gap-2'>
            <PrimaryButton
              onClick={() => handleAdd('seller')}
            >
              { t('dashboardPage.admin.newSeller') }
            </PrimaryButton>
            <PrimaryButton
              onClick={() => handleAdd('organization')}
            >
              { t('dashboardPage.admin.newOrganization') }
            </PrimaryButton>
            <PrimaryButton
              onClick={() => handleAdd('cliente')}
            >
              { t('dashboardPage.seller.newCustomer') }
            </PrimaryButton>
          </div>
        </div>*/}
        
       {/*<div className='flex flex-col justify-between gap-4 pr-8 mt-5 lg:flex-row'>
          <div>
            <LineChart
              title={<div className='subtitle'>Ventas totales</div>}
              bgColor1={'rgba(146, 224, 235, 1)'}
              bgColor2={'rgba(34, 159, 176, 1)'}
              chartData={chartValues.totalSales}
            />
          </div>
          <div>
            <LineChart
              title={<div className='subtitle'>Ventas por organización</div>}
              bgColor1={'rgba(252, 227, 138, 0.69)'}
              bgColor2={'rgba(255, 147, 47, 0.8)'}
              chartData={chartValues.orgSales}
            />
          </div>
          <div>
            <LineChart
              title={<div className='subtitle'>Clientes registrados</div>}
              bgColor1={'rgba(204, 192, 82, 0.33)'}
              bgColor2={'rgba(223, 180, 68, 0.49)'}
              chartData={chartValues.clients}
            />
          </div>
        </div>*/}
        {/*<div className='flex flex-col justify-between gap-4 pr-8 mt-5 lg:flex-row'>
          <div>
            <LineChart
              title={<div className='subtitle'>Vendedores registrados</div>}
              bgColor1={'rgba(222, 252, 138, 0.69)'}
              bgColor2={'rgba(127, 200, 53, 0.8)'}
              chartData={chartValues.sellers}
            />
          </div>
        </div>*/}
        {/*<div className='pr-4'>
          <UsersTable
            role={roles.CLIENT}
            random={random}
            onScroll={scrollToTop}
          />
        </div>*/}
      </div>
     {
        type !== '' &&
        <Modal
          onCloseModal={handleCloseModal}
          type="medium"
          title={t('registerPage.add')}
          style={{width: '600px', minHeight: heightRegistrationForm(type)}}
        >
          <Register
            onSuccess={handleSuccess}
            type={type}
            setType={setType}
          />
        </Modal>
      }
    </div>
  )
}
