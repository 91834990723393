import api from '../api/axios'
import { endpoints } from '../api/endpoints'

export const saveUser = async (data) => {
    // try {
        return await api.post(endpoints.user, data);
    // } catch (error) {
    //     return error.message;
    // }
};

export const updateProfile = async (data) => {
    try {
        return await api.put(endpoints.profile, data);
    } catch (error) {
        return error.message;
    }
};

export const updatePassword = async (data) => {
    // try {
        return await api.put(endpoints.updatePassword, data);
    // } catch (error) {
    //     return error.message;
    // }
};

export const updateUser = async (id, data) => {
    try {
        return await api.put(endpoints.user + `/${id}`, data);
    } catch (error) {
        return error?.message;
    }
};

export const getUsers = async (page, download = undefined) => {
    try {
        let theEndpoint = endpoints.user + `?page=${page}`;
        if (download) {
            theEndpoint += `&download=${download}`;
            return await api.get(theEndpoint, { responseType: 'blob' });
        }
        return await api.get(theEndpoint);
    } catch (error) {
        return error.message;
    }
};

export const deleteUser = async (id) => {
    try {
        return await api.delete(endpoints.user + `/${id}`);
    } catch (error) {
        return error.message;
    }
};

export const getUsersByRole = async (page, role, download = undefined) => {
    try {
        let theEndpoint = endpoints.userByRole + `/${role}?page=${page}`;
        if (download) {
            theEndpoint += `&download=${download}`;
            return await api.get(theEndpoint, { responseType: 'blob' });
        }
        return await api.get(theEndpoint);
    } catch (error) {
        return error.message;
    }
};


export const sendMessageNotification = async (payload) => {
    try {
        return await api.post(endpoints.notification, payload);
    } catch (error) {
        return error.message;
    }
}

export const saveFile = async (payload) => {
    try {
        return await api.post(endpoints.uploadLogo, payload);
    } catch (error) {
        return error.message;
    }
}

export const saveUserPublic = async (payload) => {
    return await api.post(endpoints.saveUserPublic, payload);
}

export const getOrganizationsPublic = async () => {
    return await api.get(endpoints.organizationsPublic);
}

export const getUserById = async (params) => {
    try {
        return await api.get(endpoints.userById,{params});
    } catch (error) {
        return error.message;
    }
};

export const getLandsByUser = async (params) => {
    try {
        return await api.get(endpoints.landsByUser,{params});
    } catch (error) {
        return error.message;
    }
};

export const getClustersBySales = async (params) => {
    try {
        return await api.get(endpoints.clustersBySales,{params});
    } catch (error) {
        return error.message;
    }
};
//user-by-client-id

export const getUserByClientId = async (params) => {
    try {
        return await api.get(endpoints.userByClientId,{params});
    } catch (error) {
        return error.message;
    }
};