import {useStripe, useElements, Elements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';

import {loadStripe} from '@stripe/stripe-js';
import { createPayment, savePaymentMethod, updatePaymentMenthod } from '../../../services/PaymentService';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../hooks/useAuth';
import { toast } from "react-toastify";
import './Payment.css';
import { PrimaryButton } from '../../../components/primary/PrimaryButton';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);


export const CheckoutForm = ({edit = false}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    try {
        const {paymentMethod, error} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement),
          });

        if (error) {
          toast.error('Algo salió mal');
          return;
        }
        const token = paymentMethod.id;
        let response = null;
        if (edit) {
          response = await updatePaymentMenthod(token);
        } else {
          response = await savePaymentMethod({
            stripe_id: paymentMethod.id,
            last4: paymentMethod.card.last4,
            country: paymentMethod.card.country,
            brand: paymentMethod.card.brand
          });
        }


        if (response.data.status === "success") {
          toast.success(response.data.message);
        }
    } catch (error) {
      console.debug(error.message)
        setErrorMessage('Un error ocurrió con su proveedor')
    }

   
  };

  return (
    <div className='gap-5 p-5 mt-10'>
      <form onSubmit={handleSubmit}>
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-2'>
          <div className=''>
            <label className='label-form'>{t('paymentMethodCard.cardNumber')}</label>
            <div className='input-form card-input text-sm md:text-base'>
              <CardNumberElement/>
            </div>
          </div>
          <div className=''>
            <label className='mb-3 label-form'>{t('paymentMethodCard.expiredAt')}</label>
            <div className='input-form'>
              <CardExpiryElement/>
            </div>
          </div>
        </div>
        <div className='grid grid-cols-2 gap-2 mt-3'>
          <div className=''>
              <label className='label-form'>{t('paymentMethodCard.cvc')}</label>
              <div className='input-form cvc-input'>
                <CardCvcElement/>
              </div>
          </div>
        </div>
        <div style={{ marginTop: '10px', color: 'red' }}>{errorMessage}</div>
        <div className='flex justify-center' style={{ bottom: '50px', position: 'absolute', left: '0', right: '0' }}>
          <PrimaryButton className={'mt-10 shadow-md'}>{t('completeProfile.submitCard')}</PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export const Payment = () => {

    const [secret, setSecret] = useState(null);
    const { i18n } = useTranslation();
    const authData = useAuth();
    const {user} = authData?.user;

    const getSecret = useCallback(async () => {
      const response = await createPayment({client: user?.client?.id});
      setSecret(response.data)
  }, [user?.client?.id]);

    useEffect(() => {
        getSecret();
    }, [getSecret])
    
    if (!secret) {
        return <></>
    }

    const options = {
        // passing the client secret obtained from the server
        clientSecret: secret,
        locale: i18n.language
      };
    
      return (
        <Elements key={secret} stripe={stripePromise} options={options}>
            <CheckoutForm secret={secret}/>
        </Elements>
      );
}