import React, { useCallback, useEffect, useState } from "react";
import "./NavBar.css";
import { useAuth } from "../../hooks/useAuth";
import LangButtons from "../langButtons/LangButtons";
import { useTranslation } from "react-i18next";
import { FaRightFromBracket } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import Avatar from "../../assets/icons/Avatar";
import ReactSearchBox from "react-search-box";
import Icon from "../Icon";
import { useTheme } from "../../Providers/ThemeProvider";

const data = [
  {
    key: "john",
    value: "John Doe",
  },
  {
    key: "jane",
    value: "Jane Doe",
  },
  {
    key: "mary",
    value: "Mary Phillips",
  },
  {
    key: "robert",
    value: "Robert",
  },
  {
    key: "karius",
    value: "Karius",
  },
];
export default function NavBar() {
  const {currentTheme} = useTheme();
  const [avatarName, setAvatarName] = useState("");
  const [showSubMenu, setShowSubMenu] = useState(false);

  const { user, logout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getAvatarName = useCallback(() => {
    if (user && user.user) {
      setAvatarName(user.user.name[0] + user.user.last_name[0]);
    }
  }, [user]);

  const handleSubMenuToggle = useCallback(() => {
    setShowSubMenu(!showSubMenu);
  }, [showSubMenu]);

  const goToProfile = useCallback(() => {
    navigate("/admin/profile");
    setShowSubMenu(false);
  }, [navigate]);

  const goToNotifications = useCallback(() => {
    navigate("/admin/notifications");
    setShowSubMenu(false);
  }, [navigate]);

  useEffect(() => {
    getAvatarName();
  }, [getAvatarName]);

  return (
    <div className="NavBar flex justify-between" style={{ backgroundColor: currentTheme.primary }}>
      <div
        style={{
          display: "flex",
          marginLeft: "25%",
          borderRadius: "26px",
          backgroundColor: "#fff",
          padding: "7px 10px 0px 10px",
          alignSelf: "center",
          textAlign: "start",
          //position: "absolute",
          top: "0.1rem",
          height: "43px",
          width: "20rem",
          marginRight: "1.5rem",
          zIndex: 10,
        }}
      >
        <ReactSearchBox
          leftIcon={<Icon icon="search" size={23} color={currentTheme.secondary} />}
          placeholder="Buscar"
          value="Doe"
          data={data}
          callback={(record) => console.log(record)}
          iconBoxSize="40px"
          inputBorderColor="#fff"
          inputHeight="30px"
          inputBackgroundColor="#fff"
          dropdownBorderColor="#fff"
        />
      </div>
      <div className="flex justify-end">
        <div className="cursor-pointer avatar" onClick={handleSubMenuToggle}>
          <span>{avatarName.toUpperCase()}</span>
        </div>
      </div>
      <div className="z-50 submenu">
        {showSubMenu && (
          <div className="w-40 lg:w-56 h-auto p-3 lg:p-8 bg-white rounded-b-2xl">
            <div className="flex justify-end">
              <LangButtons />
            </div>
            <div
              className="flex justify-between gap-2 mt-4 align-center cursor-pointer"
              onClick={goToProfile}
            >
              <Avatar size="90" />
              <div className="text-xs lg:text-sm font-medium self-center">
                {user.user.name} {user.user.last_name}
              </div>
            </div>

            {/*
                (
                  user.user.roles[0].name.toLowerCase() === roles.SUPER_ADMIN
                  || user.user.roles[0].name.toLowerCase() === roles.SELLER
                  || user.user.roles[0].name.toLowerCase() === roles.COORDINATOR
                  || user.user.roles[0].name.toLowerCase() === roles.ORGANIZATION
                ) && (
                  <div className='flex justify-between gap-2 mt-4'>
                  <div className='cursor-pointer text-xs lg:text-base' onClick={goToNotifications}>
                    {t('global.notifications')}
                  </div>
                  <div className='cursor-pointer' onClick={goToNotifications}>
                    <FaBell className='text-primary'/>
                  </div>
                </div>
                )
              */}
            {/*<div className='flex justify-between gap-2 mt-4'>
              <div className='cursor-pointer text-xs lg:text-base' onClick={goToProfile}>
                { t('global.profile') }
              </div>
              <div>
                <FaCog className='cursor-pointer text-primary' onClick={goToProfile}/>
              </div>
            </div>*/}
            <div className="flex justify-between gap-2 mt-4 items-center">
              <div
                className="cursor-pointer text-xs lg:text-base"
                onClick={logout}
              >
                {t("global.exit")}
              </div>
                <FaRightFromBracket
                  className="cursor-pointer text-primary"
                  onClick={logout}
                />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
