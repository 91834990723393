import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { PrimaryButton } from '../../../../components/primary/PrimaryButton';
import { Modal } from "flowbite-react";
const Dropzone = ({ onFilesChange, renderComponent, disabled }) => {
    const [acceptedFiles, setAcceptedFiles] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const loadFile = useRef(false);

    const onDrop = useCallback((files) => {
        // Store the accepted files in state but don't submit immediately
        setAcceptedFiles(files);
      }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            // 'image/jpeg': [],
            // 'image/jpg': [],
            // 'image/png': [],
            'application/pdf': []
        }, // Set accepted file type to PDF
        multiple: false, // Allow multiple file uploads
        maxFiles: 1,
        mazSize: 1000000, // 1Mb
        disabled: disabled
    });

    // Expose a function to clear the files
    const clearFiles = () => {
        loadFile.current = false;
        setAcceptedFiles([]);
    };

    const handleCloseModal = useCallback(() => {
        setOpenModal(false);
    }, []);

    const handleOpenModal = useCallback(() => {
        setOpenModal(true);
    }, []);

    useEffect(() => {
        if (acceptedFiles?.length > 0 && !loadFile.current) {
            loadFile.current = true
            handleOpenModal();
        }
    },[acceptedFiles?.length, handleOpenModal]);

  return (
    <>
      <Modal show={openModal} size="md" onClose={handleCloseModal} popup>
        <div className="w-full flex-col flex justify-center items-center p-4">
          <p className="text-lg mb-4">Confirmar el envio?</p>
          <div className="flex flex-row gap-4">
            <PrimaryButton
              onClick={() => {
                clearFiles();
                handleCloseModal();
              }}
            >
              Cancelar
            </PrimaryButton>
            <PrimaryButton
              onClick={() => {
                onFilesChange(acceptedFiles).then(() => {
                  clearFiles();
                  loadFile.current = false;
                });
                handleCloseModal();
              }}
            >
              Enviar
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      <div
        {...getRootProps()}
        style={{
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <input accept=".pdf" {...getInputProps()} />
          <div className="justify-center w-full mt-0 text-center">
            <div className="flex justify-start p-0">
              {/*<p style={{ margin: 0 }}>
                <Icon icon="home" size={20} />
              </p>*/}
              {renderComponent?.()}
            </div>
            {/*<div className='w-full'>
                    <PrimaryButton>
                        {t('userDetails.selectFile')}
                    </PrimaryButton>
                </div>*/}
          </div>

        {/*<div
          className={
            acceptedFiles.length >= 5
              ? "grid grid-cols-5 gap-4"
              : `grid grid-cols-${acceptedFiles.length} m-2`
          }
        >*/}
          {/*acceptedFiles.length > 0 &&
            acceptedFiles.map((f, i) => (
              <div key={i} className="text-center">
                <div className="flex justify-center">
                  <DocumentFile />
                </div>
                {f.name}
              </div>
            ))*/}
        {/*</div>*/}
      </div>
      {/*acceptedFiles.length > 0 && (
        <div className="flex justify-center mt-2">
          <PrimaryButton outline onClick={() => clearFiles()}>
            {t("userDetails.clear")}
          </PrimaryButton>
        </div>
      )*/}
       {/*<div className="mt-2">
       *acceptedFiles.length > 0 && (
          <div className="flex justify-center mt-2">
            <PrimaryButton onClick={() => onFilesChange(acceptedFiles)}>
              {t("userDetails.sendFiles")}
            </PrimaryButton>
          </div>
        )
      </div>*/}
    </>
  );
};

export default Dropzone;
