import { getFillByLot } from "./Manzana2Div1";

export const Manzana18Div1 = ({ lands, landSelector, selected }) => {
  const findLandById = (id) => {
    return lands.filter((l) => l.id === id)[0];
  };

  const findLandByLot = (lot) => {
    return lands.filter((l) => l.lote === lot)[0];
  };

  const getFill = (id) => {
    const land = lands.filter((l) => l.id === id)[0];
    const disabledColor = "rgba(223, 250, 255, 0.48)";
    const normalColor = "#0096B3";
    const selectedColor = "#DFFAFF";

    if (!land) {
      return disabledColor; // disabled
    }

    if (selected) {
      if (id === selected.id) {
        return selectedColor;
      }
    }

    if (land.status !== "on_sale") {
      return disabledColor; // disabled
    } else {
      return normalColor; // normal color
    }
  };

  if (!lands) {
    return <></>;
  }

  return (
    <svg
      width="1218"
      height="941"
      viewBox="0 0 1218 941"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1218" height="941" fill="url(#pattern0_1878_6539)" />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("3"))}
        d="M487.001 506.731L530.227 493.111L554.505 574.827L511.871 587.854L487.001 506.731Z"
        fill={getFillByLot(3, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("2"))}
        d="M444.371 518.572L487.005 506.729L512.467 587.853L469.24 600.88L444.371 518.572Z"
        fill={getFillByLot(2, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("1"))}
        d="M400.546 532.188L444.365 518.568L469.235 600.284L426.008 615.087L400.546 532.188Z"
        fill={getFillByLot(1, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("9"))}
        d="M494.696 683.184L536.146 669.565L511.276 588.441L468.642 600.876L494.696 683.184Z"
        fill={getFillByLot(9, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("10"))}
        d="M452.066 696.212L495.292 683.185L468.646 600.878L426.011 615.089L452.066 696.212Z"
        fill={getFillByLot(10, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("5"))}
        d="M616.091 465.869L639.776 549.361L596.55 561.796L572.272 480.08L616.091 465.869Z"
        fill={getFillByLot(5, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("4"))}
        d="M572.271 480.082L596.548 561.797L554.506 574.824L530.229 493.109L572.271 480.082Z"
        fill={getFillByLot(4, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("8"))}
        d="M536.154 669.565L580.564 655.946L555.694 574.23L511.284 588.442L536.154 669.565Z"
        fill={getFillByLot(8, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("7"))}
        d="M580.562 655.949L623.788 642.33L598.326 561.207L555.692 574.234L580.562 655.949Z"
        fill={getFillByLot(7, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("6"))}
        d="M623.785 642.326L667.603 628.115L639.773 549.36L598.323 561.203L623.785 642.326Z"
        fill={getFillByLot(6, selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <defs>
        <pattern
          id="pattern0_1878_6539"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1878_6539"
            transform="matrix(0.00166692 0 0 0.00215869 -3.77004 -2.51257)"
          />
        </pattern>
        <image
          id="image0_1878_6539"
          width="4096"
          height="2304"
          href="/mz1.png"
        />
      </defs>
    </svg>
  );
};
