import { getFillByLot } from "./Manzana2Div1";

export const Manzana25Div1 = ({ lands, landSelector, selected }) => {
  const findLandById = (id) => {
    return lands.filter((l) => l.id === id)[0];
  };

  const findLandByLot = (lot) => {
    return lands.filter((l) => l.lote === lot)[0];
  };

  const getFill = (id) => {
    const land = lands.filter((l) => l.id === id)[0];
    const disabledColor = "rgba(223, 250, 255, 0.48)";
    const normalColor = "#0096B3";
    const selectedColor = "#DFFAFF";

    if (!land) {
      return disabledColor; // disabled
    }

    if (selected) {
      if (id === selected.id) {
        return selectedColor;
      }
    }

    if (land.status !== "on_sale") {
      return disabledColor; // disabled
    } else {
      return normalColor; // normal color
    }
  };

  if (!lands) {
    return <></>;
  }

  return (
    <svg
      width="1219"
      height="941"
      viewBox="0 0 1219 941"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1219" height="941" fill="url(#pattern0_1878_8194)" />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("3"))}
        d="M431.024 527.852L501.408 543.688L513.139 582.399L452.139 600.582L431.024 527.852Z"
        fill={getFillByLot("3", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("2"))}
        d="M363.572 576.532L379.409 625.215L452.139 599.994L438.062 552.484L363.572 576.532Z"
        fill={getFillByLot("2", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("1"))}
        d="M343.044 509.668L363.573 576.533L438.063 551.899L431.024 527.851L343.044 509.668Z"
        fill={getFillByLot("1", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("5"))}
        d="M447.446 687.976L491.437 674.485L467.975 595.303L423.398 609.966L447.446 687.976Z"
        fill={getFillByLot("5", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("4"))}
        d="M402.871 701.463L447.447 687.972L423.399 609.963L379.409 625.213L402.871 701.463Z"
        fill={getFillByLot("4", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("6"))}
        d="M491.435 674.482L537.185 660.991L513.137 582.396L467.974 595.299L491.435 674.482Z"
        fill={getFillByLot("6", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("8"))}
        d="M570.032 652.196L608.743 639.878L583.522 559.523C575.076 559.523 549.894 554.831 538.359 552.484L570.032 652.196Z"
        fill={getFillByLot("8", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("7"))}
        d="M537.188 660.995L570.034 652.197L538.361 552.486C528.976 551.078 509.621 546.034 501.409 543.688L537.188 660.995Z"
        fill={getFillByLot("7", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("10"))}
        d="M706.696 609.379L713.148 555.418C692.502 561.049 655.276 561.283 639.244 560.697L657.427 624.043L706.696 609.379Z"
        fill={getFillByLot("10", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("9"))}
        d="M608.159 639.88L657.428 624.043L639.245 560.697C621.415 560.697 594.669 560.111 583.524 559.524L608.159 639.88Z"
        fill={getFillByLot("9", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <defs>
        <pattern
          id="pattern0_1878_8194"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1878_8194"
            transform="matrix(0.00164979 0 0 0.00213827 -3.62717 -1.87097)"
          />
        </pattern>
        <image
          id="image0_1878_8194"
          width="4096"
          height="2304"
          href="/mz1.png"
        />
      </defs>
    </svg>
  );
};
