import React from "react";
import "./index.css";
import ReactEcharts from "echarts-for-react";

const option = (data = [], title = "") => ({
  title: {
    text: title,
    show: true,
    top: 10,
    left: 10,
    textStyle: {
      fontSize: 16,
      fontWeight: "400",
      color: "#4C4C4C",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: "{a} <br/>{b} : {c} ({d}%)",
  },
  series: [
    {
      name: "",
      type: "pie",
      radius: "65%",
      center: ["50%", "60%"],
      data: data,
      label: {
        fontSize: 10,
        fontWeight: "bold",
        color: "#8598AD",
        show: true,
        formatter: "{b}",
        rich: {
          text: {
            width: 1000,
          },
        },
      },
      avoidLabelOverlap: true,
      labelLine: {
        show: false,
        length: 10,
        length2: 10,
        smooth: false,
      },
      labelLayout: (params) => {
        if (params.labelRect) {
          const overlapOffset = params.labelRect.y > 0 ? -1 : 10;
          return {
            y: params.labelRect.y + overlapOffset,
          };
        }
        return {};
      },
      itemStyle: {
        emphasis: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
});

const CircleChart = ({ title, data }) => {
  return (
    <ReactEcharts
      option={option(data, title)}
      style={{
        height: "100%",
        width: "100%",
        borderRadius: 12,
        backgroundColor: "#FFF",
        marginRight: 20,
      }}
    />
  );
};

export default CircleChart;
