import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./index.css";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { ToastContainer } from "react-toastify";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { getLotLands } from "../../../services/LotLandsService";
import Pagination from "../../../components/pagination/Pagination";
import Loading from "../../../components/loading/Loading";
import { Selector } from "../../../components/selector/Selector";
import { useForm } from "react-hook-form";
import { formattedPrice } from "../../../utils";

const statusColor = {
  on_sale: "#5EC394",
  separated: "#FF4B55",
  sold: "#9A9A9A",
};

const capitalized = (value) => {
  if (!value) {
    return "";
  }

  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

const getStatusColor = (status) => {
  return statusColor[status];
};

const currentDate = new Date();

const Lands = () => {
  const { t, i18n } = useTranslation();
  const [meta, setMeta] = useState(null);
  const [lotLands, setLotLands] = useState([]);
  const [first, setFirst] = useState(true);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');

  const { control, watch, handleSubmit } = useForm({
    defaultValues: { month: currentDate.getMonth() + 1 },
  });
  const selectedMonth = watch("month");

  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, index) => {
      const monthValue = index + 1;
      return {
        label: capitalized(
          new Date(2023, index, 1).toLocaleString(
            i18n.language === "es" ? i18n.language : "default",
            { month: "long" }
          )
        ),
        value: monthValue,
      };
    });
  }, [i18n.language]);

  const downloadFile = useCallback((theResponse) => {
    const url = window.URL.createObjectURL(new Blob([theResponse]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `lands.xlsx`);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  }, []);

  /**
   * Get section list in the page selected
   */
  const getLotLandList = useCallback(
    async ({ page, download = undefined, status, month = 1 }) => {
      setLoading(true);
      const theLotLands = await getLotLands({
        page,
        download,
        status,
        month,
      });
      setLoading(false);
      if (!download) {
        if (theLotLands.data.data) {
          theLotLands.data.data.forEach((theLotLand) => {
            theLotLand.show = false;
          });
          setLotLands(theLotLands.data.data);
          setMeta(theLotLands.data.meta);
        }
      } else {
        downloadFile(theLotLands.data);
      }
    },
    [downloadFile]
  );

  /**
   * Handle page selected on pagination
   */
  const onPageSelected = useCallback(
    (pageSelected) => {
      if (meta && meta.current_page !== pageSelected) {
        getLotLandList({ page: pageSelected });
      }
    },
    [getLotLandList, meta]
  );

  const handleArrowClick = useCallback(
    (lotLandSelected, show) => {
      const theIndex = lotLands.findIndex(
        (theLotLand) => theLotLand.id === lotLandSelected.id
      );
      const currentLotLands = [...lotLands];
      currentLotLands[theIndex].show = show;
      setLotLands(currentLotLands);
    },
    [lotLands]
  );

  const submit = useCallback(async (data) => {
    // Handle form submission here
    try {
      const month = data.month?.value ? data.month?.value : data.month;

      getLotLandList({ page: 1, month: month , status: status});
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const month = selectedMonth?.value || selectedMonth;

    if (first) {
      setFirst(false);
      getLotLandList({ page: 1, month: month });
    }
  }, [first, getLotLandList, selectedMonth]);

  useEffect(() => {
    if (selectedMonth) {
      handleSubmit(submit)();
    }
  }, [selectedMonth, handleSubmit, submit]);

  return (
    <div className="container mt-10 max-w-screen-xl">
      <div className="LotLand">
        {loading && <Loading />}
        <div className="flex justify-between">
          <div className="w-full flex justify-start items-center">
            <p className="text-2xl" style={{ color: "#1E1E1E" }}>
              {t("filteredLand.lands")}
            </p>
            <div className="w-full lg:w-1/6 ml-10" style={{ minWidth: 150 }}>
              <form>
                <Selector
                  label=""
                  name={"month"}
                  defaultValue={
                    months.filter(
                      (r) => r.value === currentDate.getMonth() + 1
                    )[0]
                  }
                  control={control}
                  options={months}
                  isNewVersion
                />
              </form>
            </div>
            <div className="w-full lg:w-1/6 ml-10" style={{ minWidth: 150 }}>
              <Selector
                label=""
                name="status"
                defaultValue={[
                  {
                    value: "",
                    label: t("filteredLand.status"),
                  },
                ]}
                options={[
                  {
                    value: "unavailable",
                    label: t("filteredLand.unavailable"),
                  },
                  { value: "on_sale", label: t("filteredLand.available") },
                  { value: "sold", label: t("filteredLand.sold") },
                  { value: "separated", label: t("filteredLand.reserved") },
                ]}
                onChange={(value) => {
                  const month = selectedMonth?.value || selectedMonth;
                  getLotLandList({ page: 1, status: value?.value, month: month });
                  setStatus(value?.value);
                }}
                isNewVersion
              />
            </div>
          </div>
        </div>

        {/* Vista móvil */}
        <div className="block lg:hidden mt-20">
          {_.map(lotLands, (theLotLand, index) => (
            <div key={index} className="card-info bg-white p-2 rounded-md mb-2">
              <div className="flex justify-between px-4">
                <div className="text-sm">{theLotLand.identifying_label}</div>
                <div>
                  {!theLotLand.show && (
                    <BiSolidDownArrow
                      onClick={() => handleArrowClick(theLotLand, true)}
                    />
                  )}
                  {theLotLand.show && (
                    <BiSolidUpArrow
                      onClick={() => handleArrowClick(theLotLand, false)}
                    />
                  )}
                </div>
              </div>
              {theLotLand.show && (
                <div>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="field-title">
                      {" "}
                      {t("filteredLand.cluster")}
                    </div>
                    <div className="text-left field-value">
                      {theLotLand.section.id}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4  my-2">
                    <div className="field-title">{t("filteredLand.apple")}</div>
                    <div className="text-left field-value">
                      {theLotLand.manzana}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4  my-2">
                    <div className="field-title">{t("lotLandPage.status")}</div>
                    <div
                      className="text-left field-value"
                      style={{ color: getStatusColor(theLotLand.status) }}
                    >
                      {t(`lotLandFormPage.${theLotLand.status}`)}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4  my-2">
                    <div className="field-title">
                      {" "}
                      {t("filteredLand.surface")}
                    </div>
                    <div className="text-left field-value">
                      {theLotLand.surface}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4  my-2">
                    <div className="field-title">{t("filteredLand.price")}</div>
                    <div className="text-left field-value">
                      {formattedPrice(theLotLand?.price)}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4  my-2">
                    <div className="field-title">
                      {t("filteredLand.client")}
                    </div>
                    <div className="text-left field-value">
                      {capitalized(theLotLand?.client?.user?.name || "") +
                        " " +
                        capitalized(theLotLand?.client?.user?.last_name || "")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* Vista web */}
        <div className="hidden lg:block mt-10 overflow-hidden rounded-lg shadow-lg">
          <table className="w-full table-auto">
            <thead className="text-left bg-header">
              <tr>
                <th className="p-2 text-black">
                  {t("filteredLand.cluster")}
                </th>
                <th className="p-2 text-black-50">{t("filteredLand.apple")}</th>
                <th className="p-2 text-black-50">
                  {t("filteredLand.status")}
                </th>
                <th className="p-2 text-black-50">
                  {t("filteredLand.surface")}
                </th>
                <th className="p-2 text-black-50">{t("filteredLand.price")}</th>
                <th className="p-2 text-black-50">
                  {t("filteredLand.client")}
                </th>
              </tr>
            </thead>
            <tbody className="text-cell">
              {_.map(lotLands, (theLotLand, index) => (
                <tr
                  className={`border-b border-white text-sm ${
                    index % 2 === 0 ? "bg-white" : "bg-header"
                  }`}
                  key={index}
                >
                  <td className="p-2 text-primary">{theLotLand.section.id}</td>
                  <td className="p-2 text-black-50">{theLotLand.manzana}</td>
                  <td
                    className="p-2"
                    style={{ color: getStatusColor(theLotLand.status) }}
                  >
                    {t(`lotLandFormPage.${theLotLand.status}`)}
                  </td>
                  <td className="p-2 text-black-50">{theLotLand.surface}</td>
                  <td className="p-2 text-black-50">{formattedPrice(theLotLand?.price)}</td>
                  <td className="p-2 text-black-50">
                    {capitalized(theLotLand?.client?.user?.name || "") +
                      " " +
                      capitalized(theLotLand?.client?.user?.last_name || "")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-center w-full mt-4">
          <Pagination meta={meta} onPageSelected={onPageSelected} />
        </div>

        <ToastContainer
          position="top-right"
          theme="light"
          autoClose={2000}
          style={{ width: "500px" }}
        />
      </div>
    </div>
  );
};

export default Lands;
