import { getFillByLot } from "./Manzana2Div1";

export const Manzana13Div1 = ({ lands, landSelector, selected }) => {
  const findLandById = (id) => {
    return lands.filter((l) => l.id === id)[0];
  };

  const findLandByLot = (lot) => {
    return lands.filter((l) => l.lote === lot)[0];
  };

  const getFill = (id) => {
    const land = lands.filter((l) => l.id === id)[0];
    const disabledColor = "rgba(223, 250, 255, 0.48)";
    const normalColor = "#0096B3";
    const selectedColor = "#DFFAFF";

    if (!land) {
      return disabledColor; // disabled
    }

    if (selected) {
      if (id === selected.id) {
        return selectedColor;
      }
    }

    if (land.status !== "on_sale") {
      return disabledColor; // disabled
    } else {
      return normalColor; // normal color
    }
  };

  if (!lands) {
    return <></>;
  }

  return (
    <svg
      width="1440"
      height="941"
      viewBox="0 0 1440 941"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1440" height="941" fill="url(#pattern0_1874_4243)" />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("13"))}
        d="M737.03 630.044L807.466 660.154L794.024 693.49C788.11 703.706 770.904 697.791 769.291 696.716C768.001 695.855 739.719 669.474 725.739 656.39L737.03 630.044Z"
        fill={getFillByLot("13", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("12"))}
        d="M807.468 660.156L824.135 620.368L752.625 591.871L737.032 630.046L807.468 660.156Z"
        fill={getFillByLot("12", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("11"))}
        d="M675.737 601.547L686.49 575.201L749.398 601.01L725.741 656.39L675.737 601.547Z"
        fill={getFillByLot("11", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("9"))}
        d="M749.397 601.009L765.527 558.533L703.157 532.725L685.951 575.201L749.397 601.009Z"
        fill={getFillByLot("9", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("10"))}
        d="M824.134 620.368L839.727 580.58L768.216 551.546L752.623 591.872L824.134 620.368Z"
        fill={getFillByLot("10", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("7"))}
        d="M703.158 532.727L719.826 490.251L783.272 516.597L765.528 558.536L703.158 532.727Z"
        fill={getFillByLot("7", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("8"))}
        d="M839.727 580.579L855.32 540.791L784.885 512.832L768.217 551.545L839.727 580.579Z"
        fill={getFillByLot("8", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("4"))}
        d="M870.374 502.08L886.504 462.292L817.682 432.72L801.552 471.97L870.374 502.08Z"
        fill={getFillByLot("4", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("5"))}
        d="M719.825 490.25L737.031 447.773L800.477 474.12L783.271 516.596L719.825 490.25Z"
        fill={getFillByLot("5", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("6"))}
        d="M855.32 540.793L870.375 502.081L801.552 471.971L784.884 512.834L855.32 540.793Z"
        fill={getFillByLot("6", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("3"))}
        d="M737.032 447.772L753.162 406.371L817.683 432.717L800.478 474.118L737.032 447.772Z"
        fill={getFillByLot("3", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("1"))}
        d="M831.125 397.768C810.048 388.305 783.989 365.866 773.594 355.829L753.162 406.371L817.683 432.717L831.125 397.768Z"
        fill={getFillByLot("1", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("2"))}
        d="M886.504 462.291L817.682 432.718L831.124 397.77C863.384 412.824 888.655 415.513 894.032 414.437C898.333 413.577 903.352 416.23 905.323 417.664L886.504 462.291Z"
        fill={getFillByLot("2", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <defs>
        <pattern
          id="pattern0_1874_4243"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1874_4243"
            transform="matrix(0.00128025 0 0 0.00196014 -3.01041 -2.59799)"
          />
        </pattern>
        <image
          id="image0_1874_4243"
          width="4096"
          height="2304"
          href="/mz1.png"
        />
      </defs>
    </svg>
  );
};
