import React, { useEffect, useState } from "react";
import _ from "lodash";
import Icon from "../Icon";

export default function Pagination({ meta, onPageSelected }) {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const thePages = [];
    if (meta) {
      const totalPages = meta.last_page;
      const currentPage = meta.current_page;

      const maxVisiblePages = 6;
      let startPage = Math.max(
        1,
        currentPage - Math.floor(maxVisiblePages / 2)
      );
      let endPage = startPage + maxVisiblePages - 1;

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }

      for (let index = startPage; index <= endPage; index++) {
        thePages.push(index);
      }

      setPages(thePages);
    }
  }, [meta]);

  return (
    <div className="mt-4">
      <nav aria-label="Page navigation example">
        <ul className="inline-flex -space-x-px text-base h-10">
          {meta && meta.current_page > 1 && (
            <li>
              <button
                className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-primary text-2xl bg-transparent"
                onClick={() => onPageSelected(parseInt(meta.current_page) - 1)}
              >
                <Icon icon="left-arrow" size={20} />
              </button>
            </li>
          )}

          {_.map(pages, (page) => (
            <li key={page}>
              <button
                className={`flex items-center justify-center px-4 h-10 leading-tight ${
                  meta.current_page === page
                    ? "text-primary"
                    : "bg-transparent text-black"
                } `}
                onClick={() => onPageSelected(parseInt(page))}
              >
                {page}
              </button>
            </li>
          ))}

          {meta && meta.last_page > meta.current_page && (
            <li>
              <button
                href="#"
                className="flex items-center justify-center px-4 h-10 leading-tight text-primary text-2xl bg-transparent"
                onClick={() => onPageSelected(parseInt(meta.current_page) + 1)}
              >
                <Icon
                  icon="left-arrow"
                  size={20}
                  style={{ transform: "rotate(180deg)" }}
                />
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}
