import './UpdateFile.css';
import { Chain } from '../../../../../assets/icons/Chain';
import api from '../../../../../api/axios';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { CheckPrimary } from '../../../../../assets/icons/CheckPrimary';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Success = () => {
    return (
        <div className="p-10">
            <div className="flex justify-center mt-9">
                <CheckPrimary/>
            </div>
            <div className="flex justify-center success-text">
            {t('success.upload')}
            </div>
        </div>
    )
}

const accepted = [
    'application/pdf',
    'image/jpeg',
    'image/jpg',
    'image/png',
];

const maxSize = 1000000; // 1Mb

export const UpdateFile = ({file, onClose = () =>{}}) => {

    const [showSuccess, setShowSuccess] = useState(false);
    const navigate = useNavigate();

    const sendingFile = async (payload) => {
        return await api.post(`api/update-file/${file.id}`, payload);
    }

    const submit = async (e) => {
        e.preventDefault();
        const inputValue = e.target.files[0];

        if (!e.target.files[0]) {
            toast.error('Seleccione un archivo válido');
            return
        }

        if (e.target.files[0]) {
            if (!accepted.includes(e.target.files[0].type)) {
                toast.error('El archivo debe ser un PDF o imagen');
                return
            }

            if (e.target.files[0].size > maxSize) {
                toast.error('El archivo debe ser menor a 1Mb');
                return
            }
        }

        const formData = new FormData();
        formData.append('file', inputValue);
        const response = await sendingFile(formData);
        if (response.data.status === 'success') {
            // success message
            // setShowSuccess(true);
            navigate('/admin/document-upload-success');
        } else {
            navigate('/admin/document-upload-error');
        }
        onClose();
    }

    if (showSuccess) {
        return <Success/>
    }

    return (
        <div className="p-5 mt-10">
            <div className='mb-3 file-label'>{file.file_name}</div>
            <div className='flex gap-3'>
                <div className='text-center'><Chain/></div>
                <label htmlFor="fileInput" className="file-input uppercase">{t('profile.selectFile')}</label>
                <input type='file' id="fileInput" accept=".pdf, .png, .jpeg, .jpg" onChange={submit}/>
            </div>
        </div>
    )
}