import { getFillByLot } from "./Manzana2Div1";

export const Manzana30Div1 = ({ lands, landSelector, selected }) => {
  const findLandById = (id) => {
    return lands.filter((l) => l.id === id)[0];
  };

  const findLandByLot = (lot) => {
    return lands.filter((l) => l.lote === lot)[0];
  };

  const getFill = (id) => {
    const land = lands.filter((l) => l.id === id)[0];
    const disabledColor = "rgba(223, 250, 255, 0.48)";
    const normalColor = "#0096B3";
    const selectedColor = "#DFFAFF";

    if (!land) {
      return disabledColor; // disabled
    }

    if (selected) {
      if (id === selected.id) {
        return selectedColor;
      }
    }

    if (land.status !== "on_sale") {
      return disabledColor; // disabled
    } else {
      return normalColor; // normal color
    }
  };

  if (!lands) {
    return <></>;
  }

  return (
    <svg
      width="1218"
      height="941"
      viewBox="0 0 1218 941"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1218" height="941" fill="url(#pattern0_1874_1945)" />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("2"))}
        d="M595.639 514.191L645.986 502.443L671.16 577.965L620.812 590.831L595.639 514.191Z"
        fill={getFillByLot("2", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("3"))}
        d="M692.28 668.728L695.737 665.272C696.792 664.217 697.172 662.662 696.723 661.239L670.602 578.523L603.472 595.306L686.804 668.898C688.388 670.297 690.786 670.222 692.28 668.728Z"
        fill={getFillByLot("3", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("1"))}
        d="M603.477 595.306L620.819 590.831L595.645 514.75L512.292 533.211L559.283 564.538L603.477 595.306Z"
        fill={getFillByLot("1", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <defs>
        <pattern
          id="pattern0_1874_1945"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1874_1945"
            transform="matrix(0.0015748 0 0 0.0020394 -3.38106 -1.3468)"
          />
        </pattern>
        <image
          id="image0_1874_1945"
          width="4096"
          height="2304"
          href="/mz1.png"
        />
      </defs>
    </svg>
  );
};
