import { useEffect, useMemo, useState } from "react";
import { useAuth } from "../../../../../hooks/useAuth";
import { roles } from "../../../../../constants/roles";
import { getFiles } from "../../../../../services/FileService";
import { toast } from "react-toastify";
import { t } from "i18next";
import { Pending } from "../../../../../assets/icons/Pending";
import { Approved } from "../../../../../assets/icons/Approved";
import { Rejected } from "../../../../../assets/icons/Rejected";
import Card from "../../../../../components/card/Card";
import Modal from "../../../../../components/modal/Modal";
import { UpdateFile } from "../updateFile/UpdateFile";
import { Download } from "../../../../../assets/icons/Download";
import { ShowFile } from "../showFile/ShowFile";
import { UploadFilesComponent } from "./UploadFilesComponent";
import ArrowedDocumentIcon from "../../../../../assets/icons/ArrowedDocument";

export const PendingApproval = ({ client, land, setStepper, role }) => {
  // Check viewer is seller or client
  const { user } = useAuth().user;
  const [files, setFiles] = useState(null);
  const [toggleUpdateFile, setToggleUpdateFile] = useState(false);
  const [fileSelected, setFileSelected] = useState();
  const [showFile, setShowFile] = useState(false);

  const requestFile = useMemo(
    () => [
      {
        id: 1,
        name: "Acta de nacimiento.",
        file: "",
        status: "no enviado",
        documentType: "acta_nacimiento",
        meta: {},
      },
      {
        id: 2,
        name: "Identificación oficial (INE/IFE) o pasaporte.",
        file: "",
        status: "no enviado",
        documentType: "identificacion_oficial",
        meta: {},
      },
      {
        id: 3,
        name: "Comprobante de domicilio con una antiguedad maxima de 3 meses.",
        file: "",
        status: "no enviado",
        documentType: "comprobante_domicilio",
        meta: {},
      },
      {
        id: 4,
        name: "Curp.",
        file: "",
        status: "no enviado",
        documentType: "curp",
        meta: {},
      },
      {
        id: 5,
        name: "Cédula de Identificación Fiscal.",
        file: "",
        status: "no enviado",
        documentType: "identificacion_fiscal",
        meta: {},
      },
      {
        id: 6,
        name: "Avalúo del terreno con una antiguedad no mayor a 6 meses.",
        file: "",
        status: "no enviado",
        documentType: "comprobante_avaluo",
        meta: {},
      },
    ],
    []
  );

  const chargeFile = useMemo(() => {
    // requestFile, files
    return requestFile.map((r) => {
      const serverFile = files?.find(
        (f) => f?.document_type === r.documentType
      );
      if (serverFile?.document_type) {
        return {
          ...r,
          status: serverFile?.status || "no enviado",
          meta: serverFile,
        };
      }

      return r;
    });
  }, [files, requestFile]);

  const pendingAll = useMemo(
    () => chargeFile.every((c) => c.status === "pending"),
    [chargeFile]
  );

  const fetchFiles = async () => {
    try {
      let response;
      if (user.roles[0].name.toLowerCase() === roles.CLIENT) {
        response = await getFiles({ land_id: land.value });
      } else {
        // seller
        response = await getFiles({
          client_id: client.client.id,
          land_id: land.value,
        });
      }
      setFiles(response.data);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const actions = (file) => {
    if (file?.status === "pending" || file?.status === "rejected") {
      return (
        <div className="flex justify-center gap-2">
          <div
            className="cursor-pointer link"
            onClick={() => {
              setFileSelected(file);
              setToggleUpdateFile(true);
            }}
          >
            {t("completeProfile.update")}
          </div>
        </div>
      );
    }
  };

  const statusHandler = (file) => {
    const status = file.status;
    if (status === "pending") {
      return (
        <div className={"text-pending flex gap-1 items-center"}>
          <Pending disabled={status !== "pending"} size={13} />{" "}
          {t("fileStatus.pending")}
        </div>
      );
    }

    if (status === "approved") {
      return (
        <div className={"text-approved flex gap-1 items-center"}>
          <Approved disabled={status !== "approved"} size={13} />{" "}
          {t("fileStatus.approved")}
        </div>
      );
    }

    if (status === "rejected") {
      return (
        <div className={"text-rejected flex gap-1 items-center"}>
          <Rejected disabled={status !== "rejected"} size={13}/>{" "}
          {t("fileStatus.rejected")}
        </div>
      );
    }

    return <p style={{ fontSize: 12 }}>No enviado para revicion</p>;
  };

  useEffect(() => {
    fetchFiles();
  }, [toggleUpdateFile, showFile]);

  useEffect(() => {
    if (pendingAll && role === roles.CLIENT) {
      setStepper(5.1);      
    }
  }, [pendingAll, role, setStepper]);

  if (user.roles[0].name.toLowerCase() === roles.CLIENT) {
    return (
      <>
        <Card className="mt-5 rounded-sm">
          <table className="w-full">
            <thead className="bg-[#F8F9FB]">
              <tr>
                <td className="p-2">{t("completeProfile.document")}</td>
                <td className="p-2"></td>
                <td className="p-2"></td>
                <td className="p-2"></td>
              </tr>
            </thead>
            <tbody>
              {chargeFile?.map((f, i) => (
                <tr
                  key={i}
                  className={i % 2 === 0 ? "" : "bg-[#F8F9FB]"}
                  style={{ alignItems: "center" }}
                >
                  <td className="p-2 content-center" style={{ fontSize: 12 }}>
                    <UploadFilesComponent
                      land={land}
                      setStepper={setStepper}
                      client={client}
                      documentType={f.documentType}
                      renderComponent={() => (
                        <p
                          style={{
                            color: f?.meta?.document_type ? "#000" : "#058CBF",
                          }}
                        >
                          {f.name}
                        </p>
                      )}
                      disabled={f?.meta?.document_type}
                    />
                  </td>
                  <td className="p-2 content-center">
                    <ArrowedDocumentIcon
                      size={20}
                      disable={!f?.meta?.document_type}
                    />
                  </td>
                  <td
                    className="p-2 content-center"
                    style={{ color: !f?.meta?.document_type && "#BFBFBF" }}
                  >
                    {statusHandler(f?.meta)}
                  </td>
                  <td className="p-2 pr-5 content-center">
                    {actions(f?.meta)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card>
        {toggleUpdateFile && (
          <Modal
            type="small"
            onCloseModal={() => setToggleUpdateFile(false)}
            title="Actualizar documento"
            style={{ height: 280 }}
            iconColor="#F45D85"
          >
            <>
              <UpdateFile file={fileSelected} />
            </>
          </Modal>
        )}
      </>
    );
  }

  return user.roles[0].name.toLowerCase() === roles.CLIENT ? (
    <>
      <Card className="mt-5 rounded-sm">
        <table className="w-full">
          <thead className="bg-[#F8F9FB]">
            <tr>
              <td className="p-2">{t("completeProfile.document")}</td>
              <td className="p-2"></td>
              <td className="p-2"></td>
            </tr>
          </thead>
          <tbody>
            {files?.map((f, i) => (
              <tr
                key={i}
                className={i % 2 === 0 ? "" : "bg-[#F8F9FB]"}
                style={{ alignItems: "center" }}
              >
                <td className="p-2 content-center">
                  {f.file_name.length ? f.file_name : "Sin nombre"}
                </td>
                <td className="p-2 content-center">{statusHandler(f)}</td>
                <td className="p-2 pr-5 content-center">{actions(f)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
      {toggleUpdateFile && (
        <Modal
          type="small"
          onCloseModal={() => setToggleUpdateFile(false)}
          title="Actualizar documento"
          style={{ height: 280 }}
          iconColor="#F45D85"
        >
          <>
            <UpdateFile file={fileSelected} />
          </>
        </Modal>
      )}
    </>
  ) : (
    <div className="p-3 mt-5">
      {/* Table */}
      <Card>
        <table className="w-full">
          <thead className="bg-[#F8F9FB]">
            <tr>
              <td className="p-2">{t("completeProfile.document")}</td>
              <td></td>
              <td className="p-2">{t("completeProfile.status")}</td>
            </tr>
          </thead>
          <tbody>
            {chargeFile?.map((f, i) => (
              <tr key={i} className={i % 2 === 0 ? "" : "bg-[#F8F9FB]"}>
                <td className="p-2 content-center" style={{ fontSize: 12 }}>
                  {f?.name}
                </td>
                <td
                  className="w-1/5 cursor-pointer content-center"
                  onClick={() => {
                    setFileSelected(f?.meta);
                    setShowFile(true);
                  }}
                >
                  <Download disabled={!f?.meta?.document_type} />
                </td>
                <td className="content-center">
                  <div
                    className="flex gap-3 align-baseline"
                    style={{
                      color: f?.meta?.document_type ? "#000" : "#BFBFBF",
                    }}
                  >
                    {statusHandler(f?.meta)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
      {showFile && (
        <Modal type="large" onCloseModal={() => setShowFile(false)}>
          <>
            <ShowFile file={fileSelected} />
          </>
        </Modal>
      )}
    </div>
  );
};
