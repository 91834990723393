import Card from "../../../../../components/card/Card";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../../../components/primary/PrimaryButton";
import SuccessIcon from "../../../../../assets/icons/Success";

export const DocumentUploadSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col justify-center w-full items-center">
      <Card className="p-10 mb-10 sm:p-4 md:p-20 w-[300px] md:w-[600px] gl:w-[600px] mt-20">
        <div className="flex justify-center p-10 md:p-0">
          <SuccessIcon />
        </div>
        <div className="flex flex-col justify-center mt-2 w-full text-center">
          <h1 className="text-[#4E4E4E] text-[14px] md:text-[38px]">
            !Carga Exitosa¡
          </h1>
          <h2 className="text-[#203459] text-[13px] md:text-[16px]">
            Tus documentos han sido procesados exitosamente
          </h2>
        </div>
      </Card>
      <div className="flex justify-center">
        <PrimaryButton
          className={"cursor-pointer shadow-md"}
          onClick={() => navigate(-1)}
        >
          Continuar
        </PrimaryButton>
      </div>
    </div>
  );
};
