import { t } from "i18next";
import AvatarDetails from "../../../../assets/icons/AvatarDetails";
import Splat from "../../../../assets/icons/Splat";
import { ProgressBar } from "./ProgressBar";
import "./UserDetails.css";
import { useAuth } from "../../../../hooks/useAuth";
import { roles, rolesIds } from "../../../../constants/roles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProgress,
  saveProgress,
} from "../../../../features/userSlice/paymentSlice";
import { getAmortizationSchedule } from "../../../../services/AmortizationsService";
import Loading from "../../../../components/loading/Loading";
import format from "date-fns/format";
import _ from "lodash";
import { InitialPayment } from "./steps/InitialPayment";
import { PendingApproval } from "./steps/PendingApproval";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../../components/primary/PrimaryButton";
import Icon from "../../../../components/Icon";
import RoundLandIcon from "../../../../assets/icons/RoundLand";
import RegisteredUserIcon from "../../../../assets/icons/RegisteredUser";
import { capitalized } from "../../../../utils";
import TimeIcon from "../../../../assets/icons/TimeIcon";
import HomeIcon from "../../../../assets/icons/HomeIcon";
import RoundCheckIcon from "../../../../assets/icons/RoundCheck";

const PaymentTable = ({ client, land }) => {
  const [loading, setLoading] = useState(false);
  const [schedule, setSchedule] = useState([]);

  const navigate = useNavigate();

  const showSchedule = async () => {
    setLoading(true);
    const schedule = await getAmortizationSchedule(
      client.client.id,
      land.value,
      1
    );
    setSchedule(schedule.data.data);
    setLoading(false);
  };

  useEffect(() => {
    showSchedule();
  }, []);

  const getStatus = (status) => {
    if (status === "PENDING") {
      return t("status.pending");
    }

    if (status === "PAID") {
      return t("status.paid");
    }

    return "";
  };

  return (
    <>
      {loading && <Loading />}
      {schedule.length > 0 && (
        <div className="hidden lg:block mt-10 overflow-hidden rounded-lg shadow-lg">
          <table className="w-full" style={{ borderCollapse: "collapse" }}>
            <thead className="text-black bg-header">
              <tr className="text-left">
                <th className="p-2">ID</th>
                <th className="p-2">
                  {t("amortizationSchedulePage.paymentDate")}
                </th>
                <th className="p-2">{t("amortizationSchedulePage.amount")}</th>
                <th className="p-2">
                  {t("amortizationSchedulePage.paymentAmount")}
                </th>
                <th className="p-2">{t("amortizationSchedulePage.balance")}</th>
                <th className="p-2">{t("amortizationSchedulePage.status")}</th>
                <th>Comprobante</th>
              </tr>
            </thead>
            <tbody className="text-cell">
              {schedule &&
                _.map(schedule, (item, index) => (
                  <tr
                    className={`border-b border-white text-sm ${
                      index % 2 === 0 ? "bg-white" : "bg-header"
                    }`}
                    key={index}
                  >
                    <td className="p-2">{item?.period}</td>
                    <td className="p-2">
                      {format(new Date(item.payment_date), "dd/MM/yyyy")}
                    </td>
                    <td className="p-2">{item?.amount}</td>
                    <td className="p-2">{item?.payment_amount}</td>
                    <td className="p-2">{item?.balance}</td>
                    <td className="p-2">{getStatus(item?.status)}</td>
                    <td className="p-2">
                      <button className="text-primary mr-3" onClick={() => {}}>
                        <Icon icon={"down-arrow"} size={20} />
                      </button>
                      <button className="text-primary" onClick={() => {}}>
                        <Icon icon={"sms"} size={20} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

const Amortization = ({ client, land }) => {
  return (
    <>
      {/* Congrats icon */}
      <div className="flex items-center justify-center mt-10 mb-3">
        <div className="">
          <Splat label="¡Has completado 0 de 100 pagos!" />
        </div>
      </div>
      {/* Payment table */}
      <div>
        <PaymentTable client={client} land={land} />
      </div>
    </>
  );
};

const LandSelection = ({ onRedirect, role }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center w-full">
        <div className="mt-8 mb-3">
          <RegisteredUserIcon size="100" />
        </div>
        <label className="text-center text-[#1E5F6B]">
          Usuario registrado con éxito!
        </label>
      </div>
    );
  }

  if (role === roles.CLIENT) {
    return (
      <div className="flex flex-col justify-center items-center w-full">
        <div className="mt-3 mb-3">
          <HomeIcon size="100" />
        </div>
        <label className="text-center text-[#1E5F6B]">
          A la espera de
          <br /> la asignación de terreno
        </label>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-center items-center w-full">
      <div className="mt-8 mb-3">
        <RoundLandIcon size="100" />
      </div>
      <PrimaryButton onClick={onRedirect}>Seleccionar terreno</PrimaryButton>
    </div>
  );
};

const stepHandler = ({
  step,
  land,
  setStepper,
  client,
  navigate,
  isPaymentRequest,
  setIsPaymentRequest,
  onInitialPayment,
  role,
  onDocumentApproval,
  onDocumentApprovalClient,
  stepper,
}) => {
  switch (step) {
    case 2:
      return (
        <LandSelection
          onRedirect={() => {
            navigate("categories");
          }}
          role={role}
        />
      );
    case 3:
      return (
        <InitialPayment
          onSubmit={() => {
            setIsPaymentRequest(true);
            // ejecutar el envio de correo
            onInitialPayment();
            setTimeout(() => {
              setIsPaymentRequest(false);
            }, 5000);
          }}
          role={role}
        />
      );
    case 4: {
      if (role === roles.CLIENT) {
        /*return (
          <UploadFilesComponent
            land={land}
            setStepper={setStepper}
            client={client}
          />
        );*/
        return (
          <PendingApproval
            setStepper={setStepper}
            client={client}
            land={land}
            role={role}
          />
        );
      }

      return (
        <>
          <div className="flex items-center justify-center mt-10 mb-3">
            <div className="">
              <TimeIcon size="100" />
            </div>
          </div>

          <div className="justify-center w-full text-center">
            <p className="text-primary mb-2" style={{ color: "#1E5F6B" }}>
              Aprobación de
              <br /> documentos pendiente
            </p>
            <PrimaryButton disabled={stepper === 4} onClick={onDocumentApproval}>
              Siguiente
            </PrimaryButton>
          </div>
        </>
      );
    }
    case 5:
      return (
        <PendingApproval
          role={role}
          setStepper={setStepper}
          client={client}
          land={land}
        />
      );
    case 5.1:
      return (
        <>
          <div className="flex items-center justify-center mt-10 mb-3">
            <TimeIcon size="100" />
          </div>
          <div className="justify-center w-full text-center">
            <p className="text-primary mb-2" style={{ color: "#1E5F6B" }}>
              Aprobación pendiente
            </p>
            <p className="text-primary mb-2 text-[14px] text-[#203459]">
              Tus documentos están siendo procesados
            </p>
          </div>
        </>
      );
    case 5.2:
      return (
        <div>
          <div className="flex items-center justify-center flex-col mt-10 mb-3 text-center">
            <div
              className="p-10"
              style={{
                backgroundColor: "rgba(105, 178, 200, 0.2)",
                borderRadius: "50%",
              }}
            >
              <RoundCheckIcon size="50" />
            </div>
            <p className="text-primary mb-1 text-[18px] mt-5">¡Aprobado!</p>
            <p className="text-primary mb-2 text-[14px] text-[#203459]">
              Tus documentos fueron <br /> aprobados con éxito.
            </p>
            <PrimaryButton
              className="mt-5"
              onClick={() => {
                onDocumentApprovalClient();
              }}
            >
              Siguiente
            </PrimaryButton>
          </div>
        </div>
      );
    case 6:
      return (
        <InitialPayment
          onSubmit={() => {
            setIsPaymentRequest(true);
            // ejecutar el envio de correo
            setTimeout(() => {
              setIsPaymentRequest(false);
            }, 5000);
          }}
        />
      );
    case 7:
      return <Amortization client={client} land={land} />;
    default:
      break;
  }
};

const gap = 100 / 7 / 2; //
const defaultProgress = 100 / 7;

// const defaultStep = 3;

const UserDetails = ({ user, land, setIsPaymentRequest, isPaymentRequest }) => {
  const navigate = useNavigate();
  const userAuth = useAuth();
  const authUser = userAuth.user.user;
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.user);
  // console.debug('userSelected, ', client);
  const { latestProgress } = useSelector((state) => state.payment);
  //const [stepper, setStepper] = useState(latestProgress?.step);
  const [stepper, setStepper] = useState(
    !land?.value ? 2 : latestProgress?.step
  );
  const [tempStep, setTempStep] = useState(4);
  const [approvedDocumentStep, setApprovedDocumentStep] = useState(5.2);
  const [timer, setTimer] = useState(1000);
  const loadFirst = useRef(false);

  const adapterStep = useCallback(
    (step) => {
      const role = authUser.roles[0].name.toLowerCase();

      if (role !== roles.CLIENT && step === 5) {
        return tempStep;
      }

      if (role === roles.CLIENT && step === 5) {
        return 4;
      }

      if (role === roles.CLIENT && step === 6) {
        return approvedDocumentStep;
      }

      return step;

      /*if (role !== roles.CLIENT && step === 4 ) {
      return 3;
    }
    if (step === 9) {
      return 2.5;
    }
    if (step === 10) {
      return 3;
    }
    return step;*/
    },
    [authUser?.roles, tempStep, approvedDocumentStep]
  );

  const step = useMemo(
    () => adapterStep(stepper ?? latestProgress?.step),
    [adapterStep, latestProgress?.step, stepper]
  );

  const progress = useMemo(
    () => (step === 3 ? defaultProgress * step : defaultProgress * step - gap),
    [step]
  );

  const handleInitialPayment = useCallback(() => {
    const payload = {
      client_id: latestProgress?.data?.client_id,
      land_id: latestProgress?.data?.land_id,
      periods: latestProgress?.data?.periods,
      interest: latestProgress?.data?.interest,
    };

    dispatch(
      saveProgress({
        user: latestProgress?.user_id,
        payload: {
          data: payload,
          step: 4,
          name: "sales",
          land: payload?.land_id,
        },
        //payload: {data: payload, step: 3, name: 'sales', land: payload.land_id}
      })
    ).then((response) => {
      // set user selected
      //navigate(`/admin/details/${user?.id}`);
    });
  }, [
    dispatch,
    latestProgress?.data?.client_id,
    latestProgress?.data?.interest,
    latestProgress?.data?.land_id,
    latestProgress?.data?.periods,
    latestProgress?.user_id,
  ]);

  const handleDocumentApproval2 = useCallback(() => {
    const payload = {
      client_id: latestProgress?.data?.client_id,
      land_id: latestProgress?.data?.land_id,
      periods: latestProgress?.data?.periods,
      interest: latestProgress?.data?.interest,
    };
    console.log("payload", payload);

    dispatch(
      saveProgress({
        user: latestProgress?.user_id,
        payload: {
          data: payload,
          step: 3,
          name: "sales",
          land: payload?.land_id,
        },
        //payload: {data: payload, step: 3, name: 'sales', land: payload.land_id}
      })
    ).then((response) => {
      // set user selected
      //navigate(`/admin/details/${user?.id}`);
    });
  }, [
    dispatch,
    latestProgress?.data?.client_id,
    latestProgress?.data?.interest,
    latestProgress?.data?.land_id,
    latestProgress?.data?.periods,
    latestProgress?.user_id,
  ]);

  const handleDocumentApproval = useCallback(() => {
    setTempStep(5);
  }, []);

  const handleDocumentApprovalClient = useCallback(() => {
    setApprovedDocumentStep(6);
  }, []);

  useEffect(() => {
    setStepper(!land?.value ? 2 : latestProgress?.step);
  }, [land?.value, latestProgress?.step]);

  // fetch wizard data
  useEffect(() => {
    if (!land.value) {
      return;
    }
    let interval = setInterval(() => {
      dispatch(
        fetchProgress({
          user: user.id,
          params: { land_id: land.value, name: "sales" },
        })
      );
    }, timer); // 5000 ms = 5 segundos

    return () => {
      clearInterval(interval);
    };
  }, [land, user.id, timer]);

  useEffect(() => {
    let timeout = setTimeout(() => {
      console.log("setTimer");
      setTimer(5000);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <div className="p-10 pt-2">
        {/* Avatar section */}
        <div className="w-full">
          <div className="flex items-center">
            <div className="m-4 mt-10">
              <AvatarDetails />
            </div>
            <div className="w-full mt-10">
              <div>
                <h1 className="userTitle">{`${capitalized(
                  user.name || ""
                )} ${capitalized(user?.last_name || "")}`}</h1>
                <div>
                  <p className="text-primary" style={{ fontSize: "0.8rem" }}>
                    {user.email}
                  </p>
                </div>
                {authUser.roles[0].name.toLowerCase() !== roles.CLIENT && (
                  <div>
                    <p
                      className="text-primary"
                      style={{ fontSize: "0.8rem" }}
                    >{`(${user.country_code}) ${user.phone}`}</p>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end w-full mb-10">{`${t(
              "userDetails.land"
            )} ${land?.label || ""}`}</div>

            {/*(authUser.roles[0].name.toLowerCase() === roles.SELLER) && (
                    <div className='flex justify-end w-full'>{`${t('userDetails.land')} ${land?.label}`}</div>
                    )*/}
          </div>
        </div>
        {/* Progress bar */}
        <div className="w-full mt-10">
          <div>
            <ProgressBar progress={progress} />
          </div>
          {/* Labels */}
          <div className="grid grid-cols-8 gap-5 mt-5">
            <div className="progress-label text-end">
              <p className="w-[75px]" style={{ textAlign: "start" }}>
                {t("userDetails.userRegister")}
              </p>
            </div>
            <div className="progress-label text-end">
              <p className="w-[75px]" style={{ textAlign: "start" }}>
                {t("userDetails.selectLand")}
              </p>
            </div>
            <div className="progress-label text-end">
              <p className="w-[75px]" style={{ textAlign: "start" }}>
                Apartado
              </p>
            </div>
            <div className="progress-label text-end text-link">
              <p className="w-[75px]" style={{ textAlign: "start" }}>
                {t("userDetails.sendingDocuments")}
              </p>
            </div>
            <div className="progress-label text-end text-link">
              <p className="w-[85px]" style={{ textAlign: "start" }}>
                {t("userDetails.documentReception")}
              </p>
            </div>
            <div className="progress-label text-end">
              <p className="w-[95px]" style={{ textAlign: "start" }}>
                {t("userDetails.documentApproval")}
              </p>
            </div>
            <div className="progress-label text-end">
              <p className="w-[75px]" style={{ textAlign: "start" }}>
                {t("userDetails.initialPayment")}
              </p>
            </div>
            <div className="progress-label text-end">
              <p className="w-[75px]" style={{ textAlign: "start" }}>
                {t("userDetails.landPayment")}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-10">
          {stepHandler({
            step,
            land,
            setStepper,
            client: user,
            navigate,
            isPaymentRequest,
            setIsPaymentRequest: setIsPaymentRequest,
            onInitialPayment: handleInitialPayment,
            role: authUser?.roles?.[0]?.name?.toLowerCase(),
            onDocumentApproval: handleDocumentApproval,
            onDocumentApprovalClient: handleDocumentApprovalClient,
            stepper: stepper,
          })}
        </div>
      </div>
    </>
  );
};

export default UserDetails;
