import { getFillByLot } from "./Manzana2Div1";

export const Manzana35Div3 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1882_3971)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('33'))} d="M523.132 284.307L581.504 263.839L565.205 212.29L507.971 228.589L523.132 284.307Z" fill={getFillByLot(33, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('32'))} d="M539.429 334.341L598.18 316.147L581.503 263.84L523.131 284.308L539.429 334.341Z" fill={getFillByLot(32, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('30'))} d="M612.584 366.938L628.504 417.35L570.89 434.786L555.729 385.89L612.584 366.938Z" fill={getFillByLot(30, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('31'))} d="M539.049 334.341L555.726 386.269L612.582 366.938L597.8 315.768L539.049 334.341Z" fill={getFillByLot(31, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('29'))} d="M571.27 434.786L581.883 487.852L643.666 467.004L628.504 417.351L571.27 434.786Z" fill={getFillByLot(29, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('28'))} d="M581.505 487.093L590.602 532.956L658.449 513.246L643.667 467.004L581.505 487.093Z" fill={getFillByLot(28, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('27'))} d="M658.449 512.867L671.716 565.553L591.739 567.07L590.602 533.335L658.449 512.867Z" fill={getFillByLot(27, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('22'))} d="M590.602 759.241H663.756L669.441 718.305H590.602V759.241Z" fill={getFillByLot(22, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('23'))} d="M673.231 678.506L669.441 718.684H590.98L589.085 678.506H673.231Z" fill={getFillByLot(23, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('24'))} d="M589.085 640.982V678.886H673.231L673.989 640.982H589.085Z" fill={getFillByLot(24, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('25'))} d="M591.359 603.079L589.085 640.983H673.61V603.079H591.359Z" fill={getFillByLot(25, selected, lands)}fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('26'))} d="M591.736 566.691L591.357 603.079L673.609 602.7L671.713 565.175L591.736 566.691Z" fill={getFillByLot(26, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1882_3971" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1882_3971" transform="matrix(0.00106614 0 0 0.00138181 -2.27113 -0.000213044)"/>
        </pattern>
        <image id="image0_1882_3971" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}