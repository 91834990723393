import { getFillByLot } from "./Manzana2Div1";

export const Manzana4Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1868_749)"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('24'))} d="M591.846 707.533L612.094 671.753C615.422 673.972 622.079 675.636 624.853 676.468C627.626 677.3 628.181 679.519 627.904 680.351C627.682 681.017 618.196 703.927 613.481 715.299C603.939 713.746 595.082 709.475 591.846 707.533Z" fill={getFillByLot(24, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('25'))} d="M599.613 750.526L613.482 715.578C605.272 714.025 595.638 709.568 591.847 707.534L579.92 747.197C585.024 749.194 595.175 750.248 599.613 750.526Z" fill={getFillByLot(25, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('23'))} d="M556.067 736.656L578.256 699.767C581.862 702.263 587.686 705.869 591.847 707.81L579.92 747.196C568.382 743.646 559.21 738.69 556.067 736.656Z" fill={getFillByLot(23, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('21'))} d="M612.095 671.476C608.544 669.257 603.774 664.819 601.832 662.878L569.103 691.446C577.535 700.544 587.224 705.592 591.57 707.534L612.095 671.476Z" fill={getFillByLot(21, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('18'))} d="M521.673 702.818C517.236 696.161 512.983 685.252 511.411 680.629L553.293 667.315L559.118 678.965L521.673 702.818Z" fill={getFillByLot(18, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('20'))} d="M537.206 721.954C532.103 717.738 524.725 707.439 521.674 702.816L559.118 678.963L567.994 690.057L537.206 721.954Z" fill={getFillByLot(20, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('17'))} d="M595.731 651.784L591.293 636.252L548.024 651.507L554.403 669.536L595.731 651.784Z" fill={getFillByLot(17, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('16'))} d="M511.411 680.628C508.305 675.747 506.789 662.507 506.419 656.497L548.301 651.505L553.571 667.315L511.411 680.628Z" fill={getFillByLot(16, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('14'))} d="M548.578 652.615L546.082 629.039L504.755 641.243L506.419 656.498L548.578 652.615Z" fill={getFillByLot(14, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('10'))} d="M504.755 616.836V593.538L539.425 587.436L543.308 610.457L504.755 616.836Z" fill={getFillByLot(10, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('11'))} d="M578.811 595.756L572.431 574.121L539.147 585.493L542.753 607.96L578.811 595.756Z" fill={getFillByLot(11, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('12'))} d="M504.755 641.241V616.833L543.308 610.453L546.082 629.314L504.755 641.241Z" fill={getFillByLot(12, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('13'))} d="M585.191 616.559L578.811 595.757L542.754 607.961L545.805 629.318L585.191 616.559Z" fill={getFillByLot(13, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('15'))} d="M548.577 651.506L546.081 629.039L585.467 616.558L591.292 635.973L548.577 651.506Z" fill={getFillByLot(15, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('6'))} d="M504.755 566.911V536.955L530.827 531.963L534.987 562.196L504.755 566.911Z" fill={getFillByLot(6, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('7'))} d="M564.665 549.99L555.235 522.531L530.827 531.962L534.71 560.253L564.665 549.99Z" fill={getFillByLot(7, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('9'))} d="M572.431 574.12L564.665 549.989L534.71 560.252L539.147 585.492L572.431 574.12Z" fill={getFillByLot(9, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('8'))} d="M504.755 593.539V566.912L534.987 562.197L539.425 587.437L504.755 593.539Z" fill={getFillByLot(8, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('3'))} d="M504.755 502.56V480.371L537.484 469.276L543.586 489.801L504.755 502.56Z" fill={getFillByLot(3, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('4'))} d="M504.755 536.952V502.559L525.002 495.902L530.549 531.96L504.755 536.952Z" fill={getFillByLot(4, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('5'))} d="M555.235 522.533L543.586 489.804L525.002 495.906L530.55 531.963L555.235 522.533Z" fill={getFillByLot(5, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('1'))} d="M504.754 452.359L503.09 373.588L528.885 443.484L504.754 452.359Z" fill={getFillByLot(1, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('2'))} d="M537.484 469.28L528.885 443.485L504.755 452.361V480.375L537.484 469.28Z" fill={getFillByLot(2, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('19'))} d="M601.832 662.601C598.948 658.829 596.562 653.818 595.73 651.784L554.68 669.813C553.793 673.141 563.741 685.438 568.826 691.17L601.832 662.601Z" fill={getFillByLot(19, selected, lands)} fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <path className="cursor-pointer" onClick={(e) => landSelector(e, findLandByLot('22'))} d="M556.067 736.656C544.751 731.109 538.778 724.544 537.207 721.955L567.994 690.336C570.657 694.552 575.945 698.38 578.257 699.766L556.067 736.656Z" fill={getFillByLot(22, selected, lands)}fillOpacity="0.64" stroke="#6D6859" stroke-opacity="0.6"/>
        <defs>
        <pattern id="pattern0_1868_749" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1868_749" transform="matrix(0.000780162 0 0 0.00101115 -1.73026 -0.755072)"/>
        </pattern>
        <image id="image0_1868_749" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}