import { useCallback, useEffect, useState } from "react";
import Loading from "../../../components/loading/Loading";
import { Selector } from "../../../components/selector/Selector";
import { PrimaryButton } from "../../../components/primary/PrimaryButton";
import _, { set } from "lodash";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/pagination/Pagination";
import { ToastContainer } from "react-toastify";
import Modal from "../../../components/modal/Modal";
import Register from "../register/Register";
import {
  getAllCluster,
  getSalesByStatus,
} from "../../../services/SalesService";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectClient } from "../../../features/userSlice/userSlice";
import { getClustersBySales } from "../../../services/UsersService";

const heightRegistrationForm = (type) => {
  if (type === "cliente") {
    return "700px";
  }

  if (type === "organizacion") {
    return "730px";
  }

  if (type === "administrador") {
    return "660px";
  }
  if (type === "vendedor") {
    return "615px";
  }

  return "580px";
};

const getOptions = (items) => {
  if (!items?.length) return [];

  return items.map((item) => {
    return { value: item.id, label: item.name };
  });
};

const Sales = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [sales, setSales] = useState([]);
  const [type, setType] = useState("");
  const [clusters, setClusters] = useState([]);
  const [clusterSales, setClusterSales] = useState([]);

  const getUserList = useCallback((value) => {}, []);

  /**
   * Handle add user to show form
   */
  const handleAdd = useCallback(() => {
    setShowForm(true);
  }, []);

  /**
   * Handle close modal and reload users list
   */
  const handleCloseModal = useCallback(() => {
    getUserList(1);
    setShowForm(false);
    setUserToEdit(null);
  }, [getUserList]);

  const handleSuccess = useCallback(
    (user) => {
      dispatch(selectClient(user));

      setTimeout(() => {
        handleCloseModal();
        navigate(`/admin/user-process/${user.id}`);
      }, 1000);
    },
    [dispatch, handleCloseModal, navigate]
  );

  const handleArrowClick = useCallback(
    (salesSelected, show) => {
      const theIndex = clusterSales.findIndex(
        (theLotLand) => theLotLand.id === salesSelected.id
      );
      const currentLotLands = [...clusterSales];
      currentLotLands[theIndex].show = show;
      setSales(currentLotLands);
    },
    [clusterSales]
  );

  const handleClusterChange = useCallback((clusterId) => {
    getClustersBySales({ sectionId: clusterId }).then((response) => {
      const { data } = response?.data;
      setClusterSales(data.map(c => {
        return {
          ...c,
          show: false,
          id: c.id
        }
      }));
    });
  }, []);

  useEffect(() => {
    getSalesByStatus({
      sectionId: 1,
    })
      .then((response) => {
        setSales(
          response.data.map((sale, index) => ({
            ...sale,
            show: false,
            id: index,
          }))
        );
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getAllCluster()
      .then((response) => {
        const { data } = response?.data;
        setClusters(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="SalesList container mt-10 max-w-screen-xl">
      {loading && <Loading />}
      <div className="flex flex-col justify-center lg:flex-row lg:justify-start items-center mb-10">
        <div className="flex flex-row items-center mr-10">
          <h1 className="title mr-4">Ventas</h1>
          <div className="w-full lg:w-1/6 ml-10" style={{ width: "200px" }}>
            <Selector
              label=""
              name="cluster"
              defaultValue={[
                {
                  value: 0,
                  label: "Seleccionar",
                },
              ]}
              options={getOptions(clusters)}
              onChange={(value) => {
                handleClusterChange(value?.value);
              }}
              isNewVersion
            />
          </div>
        </div>
        <PrimaryButton
          className="shadow mt-4 lg:mt-0"
          onClick={() => {
            handleAdd();
          }}
        >
          Nueva venta
        </PrimaryButton>
      </div>

      <div className="w-full">
        {/* Vista movil */}
        <div className="block lg:hidden">
          {_.map(clusterSales, (info, index) => (
            <div key={index} className="card-info bg-white p-2 rounded-md">
              <div className="flex justify-between px-4">
                <div className="text-sm text-black-50">{info?.nameAdmin || info?.nameCoordinator || info?.nameOrganization}</div>
                <div>
                  {!info.show && (
                    <BiSolidDownArrow
                      onClick={() => handleArrowClick(info, true)}
                    />
                  )}
                  {info.show && (
                    <BiSolidUpArrow
                      onClick={() => handleArrowClick(info, false)}
                    />
                  )}
                </div>
              </div>
              {info.show && (
                <div>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="field-title text-black-50">Mes</div>
                    <div className="text-left field-value text-black-50">
                      {info?.full_date}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="field-title">Ventas</div>
                    <div className="text-left field-value text-black-50">
                      {info?.land_status === "paid" && info?.total_lands}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="field-title">En proceso</div>
                    <div className="text-left field-value text-black-50">
                      {info?.land_status === "separated" && info?.total_lands}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="field-title">Administrador</div>
                    <div className="text-left field-value text-black-50">
                      {info?.nameAdmin}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="field-title">Coordinador</div>
                    <div className="text-left field-value text-black-50">
                      {info?.nameCoordinator}
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="field-title">Organización</div>
                    <div className="text-left field-value text-black-50">
                      {info?.nameOrganization}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* vista web */}
        <div className="hidden lg:block overflow-hidden rounded-lg shadow-lg mt-5">
          <table className="w-full  table-auto">
            <thead className="text-black bg-header">
              <tr>
                <td className="p-2">Mes</td>
                <td className="p-2">Ventas</td>
                <td className="p-2">En proceso</td>
                <td className="p-2">Administrador</td>
                <td className="p-2">Coordinador</td>
                <td className="p-2">Organización</td>
              </tr>
            </thead>
            <tbody className="text-cell">
              {_.map(clusterSales, (info, index) => (
                <tr
                  className={`border-b border-white text-sm ${
                    index % 2 === 0 ? "bg-white" : "bg-header"
                  }`}
                  key={info.id}
                >
                  <td className="p-2 text-primary">{info.full_date}</td>
                  <td className="p-2 text-black-50">
                    {info?.land_status === "paid" && info?.counter}
                  </td>
                  <td className="p-2 text-black-50">
                    {info?.land_status === "separated" && info?.total_lands}
                  </td>
                  <td className="p-2 text-black-50">{info?.nameAdmin}</td>
                  <td className="p-2 text-black-50">
                    {info?.nameCoordinator}
                  </td>
                  <td className="p-2 text-black-50">
                    {info?.nameOrganization}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-center w-full mt-4 mb-4">
          <Pagination meta={meta} onPageSelected={() => {}} />
        </div>
      </div>

      {showForm && (
        <Modal
          onCloseModal={handleCloseModal}
          type="medium"
          title={t("registerPage.add")}
          style={{ width: "600px", minHeight: heightRegistrationForm(type) }}
        >
          <Register
            userToEdit={userToEdit}
            onSuccess={handleSuccess}
            type={type}
            setType={setType}
          />
        </Modal>
      )}

      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: "500px" }}
      />
    </div>
  );
};

export default Sales;
