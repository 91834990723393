import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Selector } from "../../../components/selector/Selector";
import { PrimaryButton } from "../../../components/primary/PrimaryButton";
import { useAuth } from "../../../hooks/useAuth";
import { getUserById } from "../../../services/UsersService";
import { useNavigate } from "react-router-dom";

const Items = ({ title, description, className }) => {
  return (
    <div className={`flex flex-col justify-start items-start mt-8 mr-5 ${className}`}>
      <p className="text-base" style={{ color: "#024E82" }}>
        {title}
      </p>
      <p className="text-sm">{description}</p>
    </div>
  );
};

const UserSites = () => {
  const { user } = useAuth().user;
  const navigate = useNavigate();
  // const [user, setUser] = useState(null);
  const [landId, setLandId] = useState(null);

  const lands = useMemo(() => user?.client?.lands || [], [user?.client?.lands]);

  const landsList = useMemo(
    () => lands.map((l) => ({ value: l.id, label: l.identifying_label })),
    [lands]
  );

  const selectedLand = useMemo(() => {
    if (!landId) {
      return null;
    }

    return lands.find((l) => l.id === landId);
  }, [landId, lands]);

  const navigateToProgress = useCallback(() => {
    navigate(`/admin/home`);
  }, [navigate]);

  if (!user) {
    return null;
  }

  return (
    <div className="p-5 DashboardSeller max-w-screen-xl">
      <div className="flex flex-row justify-start items-center w-full mt-10">
        <h3 className="title text-2xl mr-10">Terrenos</h3>
        <div style={{ minWidth: "9rem" }}>
          <Selector
            name="land"
            options={landsList}
            defaultValue={{ value: 0, label: "Seleccionar" }}
            onChange={(e) => setLandId(e.value)}
            isNewVersion
          />
        </div>
      </div>
      <div className="bg-white rounded-md">
        <div className="flex flex-row justify-start items-center w-full mt-10  p-10">
          <div className="w-80 h-80 bg-gray mr-10 rounded-md"><></></div>

          <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Items
                    className="mt-0"
                    title="Nombre"
                    description={selectedLand?.identifying_label || ""}
                  />
                </td>
                <td>
                  <Items
                    className="mt-0"
                    title="Medidas"
                    description={selectedLand?.surface || ""}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Items
                    title="Adelanto"
                    description={selectedLand?.advance || ""}
                  />
                </td>
                <td>
                  <Items
                    title="Ubicación"
                    description={selectedLand?.location || ""}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Items title="Interés" description="" />
                </td>
                <td style={{ maxWidth: "300px" }}>
                  <Items
                    title="Descripción"
                    description={selectedLand?.description || ""}
                  />
                </td>
              </tr>
            </tbody>
          </table>

          {/*<div className="flex flex-row justify-start items-center w-full">
            <div>
              <Items
                title="Nombre:"
                description={selectedLand?.identifying_label || ""}
              />
              <Items
                title="Adelanto:"
                description={selectedLand?.advance || ""}
              />
              <Items title="Interés:" description="" />
            </div>
            <div>
              <Items
                title="Medida:"
                description={selectedLand?.surface || ""}
              />
              <Items
                title="Ubicación:"
                description={selectedLand?.location || ""}
              />
              <Items
                title="Descripción:"
                description={selectedLand?.description || ""}
              />
            </div>
          </div>*/}
        </div>
        <div className="flex flex-row justify-end items-center w-full pb-10 pr-10">
          <PrimaryButton style={{ right: 10 }} onClick={navigateToProgress}>
            Progreso
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default UserSites;
