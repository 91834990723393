import { ReactComponent as Icon } from "../images/copy.svg";

const CopyIcon  = ({ size = "120", color = "#5EC394" }) => {
  return (
    <>
      <Icon width={size} height={size} fill={color} />
    </>
  );
};
export default CopyIcon;
