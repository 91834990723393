import api from '../api/axios'
import { endpoints } from '../api/endpoints'

export const getChartsInfo = async () => {
  try {
    return await api.get(`${endpoints.dashboard}/charts-info`);
  } catch (error) {
    return error.message;
  }
};

export const getSalesChart = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/sales`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getClientsChart = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/clients`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getAvailableLandsChart = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/available-land`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getNoAdvanceChart = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/no-advance`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getLandSales = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/land-sales`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getPaymentsDashboard = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/payments`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getSellerSignUp = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/sellers-signup`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getRegionSales = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/region-sales`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getOrganizationSales = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/organization-sales`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getSalesBySeller = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/sales-by-seller`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getClientsBySeller = async (params) => {
  try {
    return await api.get(`${endpoints.dashboard}/clients-by-seller`, {params});
  } catch (error) {
    return error.message;
  }
};

export const getSellersTop = async () => {
  return await api.get(endpoints.sellersTop);
};

export const getGroupeLands = async (params) => {
  return await api.get(endpoints.groupedLands, { params });
};

export const getLandsSales = async (params) => {
  return await api.get(`${endpoints.dashboard}/lands-sales`, { params });
};