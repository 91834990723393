import React, { useCallback, useEffect } from 'react'
import "./Modal.css"
import { IoCloseCircle } from 'react-icons/io5'

const Content = ({children, type, modalHeader}) => {

  if(modalHeader) {
    return (
      <div className={
        type === 'large'
          ? 'modal-children'
          : type === 'medium'
            ? 'modal-children-md'
            : 'modal-children-sm'
        }
      >
        { children }
      </div>
    )
  }
  return (
    <>
      { children }
    </>
  )
}

export default function Modal(
  {onCloseModal, children, type = 'large', title = '', modalHeader = true, style, iconColor}
) {

  const closeModal = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='Modal'>
      <div className='overflow-y-hidden'>
        <div className={
          type === 'large'
            ? 'modal-content'
            : type === 'medium'
              ? 'modal-content-md'
              : 'modal-content-sm'
          }
          style={style}
        >
          {modalHeader ? <div className='modal-header flex justify-between sticky'>
            <div className='modal-title'>
              { title }
            </div>
            <div>
              <IoCloseCircle
                className='text-2xl text-primary cursor-pointer' 
                onClick={closeModal}
                color={iconColor}
              />
            </div>
          </div>: null}
            <Content type={type} modalHeader={modalHeader} >
            { children }
            </Content>
        </div>
      </div>
    </div>
  )
}
