import { getFillByLot } from "./Manzana2Div1";

export const Manzana21Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1219" height="941" viewBox="0 0 1219 941" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1219" height="941" fill="url(#pattern0_1868_2616)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('4'))}
            d="M615.092 541.975H654.768V598.259H615.092V541.975Z" 
            fill={getFillByLot("4", selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('3'))}
            d="M575.878 598.259H615.093V541.975H575.878V598.259Z" 
            fill={getFillByLot("3", selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M533.435 598.259H575.879V541.975H533.435V598.259Z" 
            fill={getFillByLot("2", selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('7'))}
            d="M536.202 656.39H576.801V598.26H536.202V656.39Z" 
            fill={getFillByLot("7", selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('6'))}
            d="M576.801 656.39H617.4V598.26H576.801V656.39Z" 
            fill={getFillByLot("6", selected, lands)}
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('5'))}
            d="M617.398 656.39H654.768V598.26H617.398V656.39Z" 
            fill={getFillByLot("5", selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('1'))}
            d="M493.757 598.259H533.433V541.975H490.989L493.757 598.259Z" 
            fill={getFillByLot("1", selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('8'))}
            d="M499.294 656.39H536.202V598.26H493.758L499.294 656.39Z" 
            fill={getFillByLot("8", selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <defs>
        <pattern id="pattern0_1868_2616" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1868_2616" transform="matrix(0.00129767 0 0 0.00168189 -2.92477 -1.52861)"/>
        </pattern>
        <image id="image0_1868_2616" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}