import { getFillByLot } from "./Manzana2Div1";

export const Manzana35Div1 = ({ lands, landSelector, selected }) => {
  const findLandById = (id) => {
    return lands.filter((l) => l.id === id)[0];
  };

  const findLandByLot = (lot) => {
    return lands.filter((l) => l.lote === lot)[0];
  };

  const getFill = (id) => {
    const land = lands.filter((l) => l.id === id)[0];
    const disabledColor = "rgba(223, 250, 255, 0.48)";
    const normalColor = "#0096B3";
    const selectedColor = "#DFFAFF";

    if (!land) {
      return disabledColor; // disabled
    }

    if (selected) {
      if (id === selected.id) {
        return selectedColor;
      }
    }

    if (land.status !== "on_sale") {
      return disabledColor; // disabled
    } else {
      return normalColor; // normal color
    }
  };

  if (!lands) {
    return <></>;
  }

  return (
    <svg
      width="1219"
      height="941"
      viewBox="0 0 1219 941"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="1219" height="941" fill="url(#pattern0_1882_3561)" />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("9"))}
        d="M650.852 578.726C646.883 585.047 638.857 597.777 638.504 598.13L653.939 667.811C679.342 662.166 715.976 639.292 731.117 628.56L650.852 578.726Z"
        fill={getFillByLot("9", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("8"))}
        d="M638.504 598.129L621.745 588.867L570.146 679.275C591.315 683.509 634.829 673.395 653.939 667.809L638.504 598.129Z"
        fill={getFillByLot("8", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("6"))}
        d="M535.747 655.019L503.112 630.322L554.27 541.237L588.228 564.611L535.747 655.019Z"
        fill={getFillByLot("6", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("7"))}
        d="M570.146 679.276L535.747 655.02L588.228 564.612L621.745 588.868L570.146 679.276Z"
        fill={getFillByLot("7", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("4"))}
        d="M469.153 606.508L434.754 581.811L485.912 493.608L520.311 516.982L469.153 606.508Z"
        fill={getFillByLot("4", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("5"))}
        d="M503.114 630.763L469.156 606.508L520.314 516.981L554.272 541.237L503.114 630.763Z"
        fill={getFillByLot("5", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("3"))}
        d="M434.755 581.809L401.238 557.994L451.955 468.909L485.913 493.165L434.755 581.809Z"
        fill={getFillByLot("3", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("1"))}
        d="M365.517 463.619L404.326 393.498L432.992 454.799L451.956 468.912L429.023 508.603L365.517 463.619Z"
        fill={getFillByLot("1", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <path
        className="cursor-pointer"
        onClick={(e) => landSelector(e, findLandByLot("2"))}
        d="M337.291 513.454L365.516 463.619L429.022 508.603L401.238 557.996L337.291 513.454Z"
        fill={getFillByLot("2", selected, lands)}
        fillOpacity="0.64"
        stroke="#6D6859"
        stroke-opacity="0.6"
      />
      <defs>
        <pattern
          id="pattern0_1882_3561"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            href="#image0_1882_3561"
            transform="matrix(0.00124047 0 0 0.00160776 -2.48669 -0.760344)"
          />
        </pattern>
        <image
          id="image0_1882_3561"
          width="4096"
          height="2304"
          href="/mz1.png"
        />
      </defs>
    </svg>
  );
};
