import Select from "react-select";
import { components } from "react-select";
import row from "../../../src/assets/images/row.svg";
import { Controller } from "react-hook-form";
import "./Selector.css";

// Custom dropdown arrow component with row image
const CustomDropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <div
      className={
        props.isNewVersion ? "dropdown-indicator" : "old-dropdown-indicator"
      }
    >
      <img src={row} alt="Custom Arrow" />
    </div>
  </components.DropdownIndicator>
);

// Custom dropdown arrow component with row image
const CustomDropdownIndicatorGreen = (props) => (
  <components.DropdownIndicator {...props}>
    <div
      className={
        props.isNewVersion ? "dropdown-indicator" : "old-dropdown-indicator"
      }
    >
      <img src={row} alt="Custom Arrow" />
    </div>
  </components.DropdownIndicator>
);

export const Selector = (props) => {
  const {
    label,
    name,
    options,
    control,
    defaultValue,
    className,
    disabled,
    placeholder = null,
    isArrowGreen = false,
    isNewVersion = false, // TODO: Refactor this prop
    style
  } = props;

  const oldStyle = {
    control: (provided, state) => ({
      ...provided,
      padding: "0px",
      ...style?.control
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundImage: isArrowGreen
          ? isFocused
            ? "linear-gradient(90deg, #438D5D 0%, #69D890 100.01%)"
            : null
          : isFocused
          ? "linear-gradient(90deg, #058CBF 0%, #41CFE3 100.01%)"
          : null,
        color: (isFocused || isSelected) ? "#fff" :"#000",
      };
    },
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: null,
    }),
  };

  const colourStyles = {
    control: (provided, state) => ({
      ...provided,
      padding: "0px",
      minHeight: "30px",
      height: "30px",
      fontSize: "13px",
      boxShadow: "0px 4px 5px 0px rgba(0, 0, 0, 0.10)",
      borderColor: state.isFocused ? "#007bff" : provided.borderColor,
      transition: "box-shadow 0.2s ease-in-out",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
      padding: "0",
    }),
    singleValue: (provided) => ({
      ...provided,
      lineHeight: "1",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundImage: isArrowGreen
          ? isFocused
            ? "linear-gradient(90deg, #438D5D 0%, #69D890 100.01%)"
            : null
          : isFocused
          ? "linear-gradient(90deg, #058CBF 0%, #41CFE3 100.01%)"
          : null,
        color: (isFocused || isSelected) ? "#fff" :"#000",
      };
    },
    dropdownIndicator: (provided, state) => ({
      ...provided,
      padding: null,
      height: "20px",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "20px",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 10px",
      height: "19px",
    }),
  };

  if (!control) {
    return (
      <Select
        components={{
          DropdownIndicator: (props) =>
            isArrowGreen ? (
              <CustomDropdownIndicatorGreen
                {...props}
                isNewVersion={isNewVersion}
              />
            ) : (
              <CustomDropdownIndicator {...props} isNewVersion={isNewVersion} />
            ),
        }}
        name={name}
        styles={isNewVersion ? colourStyles : oldStyle}
        options={options}
        defaultValue={defaultValue}
        className={`select-text ${className}`}
        isClearable={disabled}
        isSearchable
        onInputChange={(inputValue) => inputValue}
        onChange={(selectedOption) => {
          props?.onChange(selectedOption);
        }}
        isOptionDisabled={disabled}
        isDisabled={disabled}
        placeholder={placeholder}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <Select
          components={{
            DropdownIndicator: (props) =>
              isArrowGreen ? (
                <CustomDropdownIndicatorGreen
                  {...props}
                  isNewVersion={isNewVersion}
                />
              ) : (
                <CustomDropdownIndicator
                  {...props}
                  isNewVersion={isNewVersion}
                />
              ),
          }}
          name={name}
          styles={isNewVersion ? colourStyles : oldStyle}
          options={options}
          defaultValue={defaultValue}
          className={`select-text ${className}`}
          isClearable={disabled}
          isSearchable
          onInputChange={(inputValue) => inputValue}
          onChange={(selectedOption) => {
            props?.onChange?.(selectedOption);
            field.onChange(selectedOption);
          }}
          isOptionDisabled={disabled}
          isDisabled={disabled}
          placeholder={placeholder}
        />
      )}
    />
  );
};
