export const themeRoles = {
  SUPER_ADMINISTRATOR: "superAdministrator",
  ADMINISTRATOR: "administrator",
  COORDINATOR: "coordinator",
  ORGANIZATION: "organization",
  SELLER: "seller",
  CLIENT: "client",
};

export const theme = {
  [themeRoles.SUPER_ADMINISTRATOR]: {
    primary: "#D2EFF4",
    secondary: "#0096B3",
    disabled: "#93959",
  },
  [themeRoles.ADMINISTRATOR]: {
    primary: "#B9C4F8",
    secondary: "#5E7EC2",
    disabled: "#93959",
  },
  [themeRoles.COORDINATOR]: {
    primary: "#B3DADB",
    secondary: "#10AFB1",
    disabled: "#93959",
  },
  [themeRoles.ORGANIZATION]: {
    primary: "#79AEE3",
    secondary: "#79AEE3",
    disabled: "#93959",
  },
  [themeRoles.SELLER]: {
    primary: "#9BDDAC",
    secondary: "#8AD19C",
    disabled: "#93959",
  },
  [themeRoles.CLIENT]: {
    primary: "#9ED5E4",
    secondary: "#2696B5",
    disabled: "#93959",
  },
};
