import { getFillByLot } from "./Manzana2Div1";

export const Manzana5Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1206" height="942" viewBox="0 0 1206 942" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1206" height="942" fill="url(#pattern0_1862_4014)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('1'))}
            d="M556.716 595.925L602.611 470.99L651.055 623.971L556.716 595.925Z" 
            fill={getFillByLot(1, selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M535.039 650.74L670.172 691.535L651.049 623.968L556.711 595.922L535.039 650.74Z" 
            fill={getFillByLot(2, selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <defs>
        <pattern id="pattern0_1862_4014" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1862_4014" transform="matrix(0.00213371 0 0 0.00273307 -5.67168 -3.52028)"/>
        </pattern>
        <image id="image0_1862_4014" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}