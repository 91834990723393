import { getFillByLot } from "./Manzana2Div1";

export const Manzana8Div1 = ({lands, landSelector, selected}) => {

    const findLandById = (id) => {
        return lands.filter(l => l.id === id)[0];
    }

    const findLandByLot = (lot) => {
        return lands.filter(l => l.lote === lot)[0];
    }

    const getFill = (id) => {
        const land = lands.filter(l => l.id === id)[0];
        const disabledColor = "rgba(223, 250, 255, 0.48)";
        const normalColor = "#0096B3";
        const selectedColor = "#DFFAFF";

        if (!land) {
            return disabledColor; // disabled
        }

        if (selected) {
            if (id === selected.id) {
                return selectedColor;
            }
        }

        if (land.status !== 'on_sale') {
            return disabledColor; // disabled
        } else {
            return normalColor; // normal color
        }
    }

    if (!lands) {
        return <></>
    }

    return (
        <svg width="1218" height="936" viewBox="0 0 1218 936" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1218" height="936" fill="url(#pattern0_1862_3357)"/>
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('1'))}
            d="M632.213 512.171L559.602 457.713C555.972 478.588 545.231 509.524 540.315 522.382L612.926 556.418L632.213 512.171Z" 
            fill={getFillByLot(1, selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('5'))}
            d="M684.405 557.554L632.216 512.172L602.718 579.11L704.827 623.357L684.405 557.554Z" 
            fill={getFillByLot(5, selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('4'))}
            d="M688.942 644.911H574.353L602.717 579.107L704.825 623.355C706.641 635.154 694.993 642.642 688.942 644.911Z" 
            fill={getFillByLot(4, selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('3'))}
            d="M477.917 644.913H574.353L589.102 610.876L517.625 577.975C506.734 601.573 486.236 632.433 477.917 644.913Z" 
            fill={getFillByLot(3, selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <path 
            className="cursor-pointer"
            onClick={(e) => landSelector(e, findLandByLot('2'))}
            d="M517.625 577.973C523.298 568.897 535.022 537.508 540.316 522.381L612.927 556.417L589.102 610.875L517.625 577.973Z" 
            fill={getFillByLot(2, selected, lands)} 
            fillOpacity="0.64" 
            stroke="#6D6859" 
            stroke-opacity="0.6"
        />
        <defs>
        <pattern id="pattern0_1862_3357" patternContentUnits="objectBoundingBox" width="1" height="1">
        <use href="#image0_1862_3357" transform="matrix(0.00188018 0 0 0.00244788 -5.08108 -3.86326)"/>
        </pattern>
        <image id="image0_1862_3357" width="4096" height="2304" href="/mz1.png"/>
        </defs>
        </svg>
    );
}