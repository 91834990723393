import { t } from "i18next";
import "./LandDetails.css";
import { formattedPrice } from "../../../../utils";

const LandDetails = ({ land }) => {
  const getStatus = (status) => {
    return t(`lotLandFormPage.${status}`);
  };

  return (
    <div className="p-4">
      {/* <div className="mb-1">
                <div className="label-details">{'Estado'}</div>
                <div className="text">{getStatus(land?.status)}</div>
            </div> */}
      <div className="mb-1">
        <div className="label-details">{"Nombre"}</div>
        <div className="text">{land?.identifying_label}</div>
      </div>
      <div className="mb-1">
        <div className="label-details">Enganche</div>
        <div className="text">
          {formattedPrice(land?.advance)}
          {/*<NumericFormat 
                        value={land?.advance} 
                        allowLeadingZeros 
                        thousandSeparator=","
                        prefix={'$'}
                    />*/}
        </div>
      </div>
      <div className="mb-1">
        <div className="label-details">Precio</div>
        <div className="text">
          {formattedPrice(land?.price)}
          {/*<NumericFormat 
                        value={land?.price} 
                        allowLeadingZeros 
                        thousandSeparator=","
                        prefix={'$'}
                    />*/}
        </div>
      </div>
      <div className="mb-1">
        <div className="label-details">Interés</div>
        <div className="text">0%</div>
      </div>
      <div className="mb-1">
        <div className="label-details">{"Medidas"}</div>
        <div className="text">{land?.surface || "Sin medidas"}</div>
      </div>
      {/* <div className="mb-1">
                <div className="label-details">{'Adelanto'}</div>
                <div className="text">{land?.advance}</div>
            </div> */}
      {/* <div className="mb-1">
                <div className="label-details">{'Medidas'}</div>
                <div className="text">{land?.measurements || 'Sin medidas'}</div>
            </div>
            <div className="mb-1">
                <div className="label-details">{'Superficie'}</div>
                <div className="text">{land?.surface || 'Sin superficie'}</div>
            </div> */}
      <div className="mb-1">
        <div className="label-details">{"Descripción"}</div>
        <div className="text">{land?.description || "Sin descripción"}</div>
      </div>
    </div>
  );
};

export default LandDetails;
