import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./DashboardSuperAdmin.css";
import { useTranslation } from "react-i18next";
import { roles } from "../../../../../constants/roles";
import LineChart from "../../../../../components/charts/lineChart/LineChart";
import Modal from "../../../../../components/modal/Modal";
import Register from "../../../register/Register";
import {
  getClientsChart,
  getGroupeLands,
  getLandSales,
  getPaymentsDashboard,
  getSalesChart,
  getLandsSales,
} from "../../../../../services/dashboardService";
import { PrimaryButton } from "../../../../../components/primary/PrimaryButton";
import { Selector } from "../../../../../components/selector/Selector";
import { useForm } from "react-hook-form";
import SellersTable from "../SellersTable";
import CircleChart from "../../../../../components/charts/CircleChart";
import SvgChart from "../../../../../components/charts/SvgChart";

export const LandStatus = {
  AVAILABLE: "available",
  UNAVAILABLE: "unavailable",
  SEPARATED: "separated",
  SOLD: "sold",
};

const capitalized = (value) => {
  if (!value) {
    return "";
  }

  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

export const sumValues = (values = []) => {
  return values.reduce((total, value) => total + value, 0);
};

const heightRegistrationForm = (type) => {
  if(type === 'cliente') {
    return '660px'
  }
  if(type === 'organizacion') {
    return '700px'

  }
  return '580px';
}

export default function DashboardSuperAdmin() {
  const [showForm, setShowForm] = useState(false);
  const [random, setRandom] = useState(0);
  const [first, setFirst] = useState(true);
  const [lands, setLands] = useState({
    [LandStatus.AVAILABLE]: {
      value: 0,
    },
    [LandStatus.UNAVAILABLE]: {
      value: 0,
    },
    [LandStatus.SEPARATED]: {
      value: 0,
    },
    [LandStatus.SOLD]: {
      value: 0,
    },
  });

  const [landSales, setLandSales] = useState({
    sold: 0,
    inProgress: 0,
  });

  const [type, setType] = useState("");

  const { t, i18n } = useTranslation();
  const [chartValues, setChartValues] = useState({
    sales: [],
    lands: [],
    clients: [],
    payments: [],
  });
  const ref = useRef(null);
  const currentDate = new Date();

  const landsToOptions = useMemo(() => {
    return [
      {
        value: lands.available.value,
        name: t("landChart.available"),
        itemStyle: { color: "#43E1FA", bottom: 10 },
      },
      {
        value: lands.unavailable.value,
        name: t("landChart.unavailable"),
        itemStyle: { color: "#5FA8B4" },
      },

      {
        value: lands.separated.value,
        name: t("landChart.reserved"),
        itemStyle: { color: "#C2EAF0" },
      },

      {
        value: lands.sold.value,
        name: t("landChart.sold"),
        itemStyle: { color: "#3CCAE0" },
      },
    ];
  }, [lands.available.value, lands.separated.value, lands.sold.value, lands.unavailable.value, t]);

  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, index) => {
      const monthValue = index + 1;
      return {
        label: capitalized(
          new Date(2023, index, 1).toLocaleString(
            i18n.language === "es" ? i18n.language : "default",
            { month: "long" }
          )
        ),
        value: monthValue,
      };
    });
  }, [i18n.language]);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: { month: currentDate.getMonth() + 1 },
  });

  const selectedMonth = watch("month");

  const submit = useCallback(async (data) => {
    // Handle form submission here
    try {
      const params = {
        month: data.month?.value ? data.month?.value : data.month,
      };

      const responseSales = await getSalesChart(params);
      const responseLands = await getLandSales(params);
      const responseClients = await getClientsChart(params);
      const responsePayments = await getPaymentsDashboard(params);
      setChartValues({
        ...chartValues,
        sales: responseSales.data,
        lands: responseLands.data,
        clients: responseClients.data,
        payments: responsePayments.data,
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  /**
   * Handle add user to show form
   */
  const handleAdd = useCallback(() => {
    setShowForm(true);
  }, []);

  /**
   * Handle close modal and reload users list
   */
  const handleCloseModal = useCallback(() => {
    setShowForm(false);
  }, []);

  /**
   * Handle success in form
   */
  const handleSuccess = useCallback(() => {
    setShowForm(false);
  }, []);

  useEffect(() => {
    if (first) {
      setFirst(false);
      setRandom(Math.random());
    }
  }, [first]);

  useEffect(() => {
    if (selectedMonth) {
      handleSubmit(submit)();
    }
  }, [selectedMonth, handleSubmit, submit]);

  useEffect(() => {
    const month = selectedMonth?.value || selectedMonth;

    if (!month) {
      return;
    }
    getGroupeLands({
      month: month,
    }).then((response) => {
      setLands({
        available: {
          value: sumValues(response?.data?.available?.values),
        },
        unavailable: {
          value: sumValues(response?.data?.unavailable?.values),
        },
        separated: {
          value: sumValues(response?.data?.reserved?.values),
        },
        sold: {
          value: sumValues(response?.data?.sold?.values),
        },
      });
    });
  }, [selectedMonth]);

  useEffect(() => {
    const month = selectedMonth?.value || selectedMonth;

    if (!month) {
      return;
    }

    getLandsSales({
      month: month,
    }).then((response) => {
      const {
        data: { sold, inPaymentProcess },
      } = response;
      setLandSales({
        sold: Number(sold),
        inProgress: Number(inPaymentProcess),
      });
    });
  }, [selectedMonth]);

  return (
    <div className="p-5 DashboardSuperAdmin max-w-screen-xl" ref={ref}>
      <h3 className="ml-4 mt-8 title">{t("dashboardPage.superAdmin.title")}</h3>
      <div className="pl-0 pr-4 md:pr-2 mt-5 lg:pl-4 lg:pr-4">
        <div className="flex flex-col lg:flex-row items-center justify-center gap-3 lg:justify-between mt-10 mb-10">
          <div className="w-full lg:w-1/6">
            <form>
              <Selector
                label=""
                name={"month"}
                defaultValue={
                  months.filter(
                    (r) => r.value === currentDate.getMonth() + 1
                  )[0]
                }
                control={control}
                options={months}
                isNewVersion
              />
            </form>
          </div>
          <PrimaryButton className="shadow" onClick={handleAdd}>
            {t("dashboardPage.superAdmin.newAdmin")}
          </PrimaryButton>
        </div>
        <div className="chart-container">
          <div className="mt-4 lg:mt-0 chart">
            <CircleChart data={landsToOptions} title={t("landChart.lands")} />
          </div>

          <div className="mt-4 lg:mt-0 chart">
            <LineChart
              title={t("landChart.totalSales")}
              bgColor1={"rgba(146, 224, 235, 1)"}
              bgColor2={"rgba(34, 159, 176, 1)"}
              chartData={chartValues?.sales}
            />
          </div>
          <div className="mt-4 lg:mt-0 chart">
            <SvgChart
              title={t("landChart.totalPayments")}
              data={{
                column1: {
                  title: t("landChart.paymentsInProgress"),
                  value: landSales.inProgress,
                },
                column2: {
                  title: t("landChart.completeLandPayments"),
                  value: landSales.sold,
                },
              }}
            />
          </div>
        </div>
        <div className="table-container">
          <SellersTable
            role={roles.ADMIN}
            random={random}
            onScroll={scrollToTop}
          />
        </div>
      </div>
      {showForm && (
        <Modal
          onCloseModal={handleCloseModal}
          type="medium"
          title={t("registerPage.add")}
          style={{width: '600px', minHeight: heightRegistrationForm(type)}}
        >
          <Register onSuccess={handleSuccess} type={type} setType={setType}  />
        </Modal>
      )}
    </div>
  );
}
