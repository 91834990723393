import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../../hooks/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import zihuaLogo from '../../../assets/images/zihua-logo.svg'
import Loading from '../../../components/loading/Loading';
import LangButtons from '../../../components/langButtons/LangButtons'
import "./Login.css"
import { useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../components/primary/PrimaryButton';

export default function Login() {

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  const { user, login } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm({});

  // Handle login submit
  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const params = {
      username: data.username,
      password: data.password
    };
    await login(params);
    setLoading(false);
  });

  useEffect(() => {
    if (user) {
      navigate('/admin/home');
    }
  }, [navigate, user]);

  return (
    <div
      className='Login'
    >
      { loading && <Loading /> }
      <div className='flex justify-end pt-10 pr-10'>
        <LangButtons />
      </div>
      <div className='flex items-center justify-center w-screen h-auto mt-8 bg-secondary lg:mt-20'>
        <div className='p-0 md:6/12'>
          <div className='flex justify-center w-full'>
            <a href='/' alt='home'>
              <img src={zihuaLogo} alt='Zihua Logo' className='w-24' />
            </a>
          </div>
          <div className=''>
          <form
            onSubmit={onSubmit}
            className='mt-10 w-64 sm:w-80'
          >
            <div>
              <label className='text-light'>
                {t('loginPage.username')}
              </label>
              <br />
              <input
                type='text'
                className='w-full p-2 border rounded-md border-tertiary'
                {
                  ...register(
                    'username', {
                      required: {
                        value: true,
                        message: t('loginPage.errors.username.required')
                      }
                    }
                  )
                }
              />
              {
                errors.username && 
                <div className='error-message'>
                  {errors.username.message}
                </div>
              }
            </div>
            <div className='mt-2'>
              <label className='text-light'>
                {t('loginPage.password')}
              </label>
              <br />
              <input
                type='password'
                className='w-full p-2 border rounded-md border-tertiary'
                {
                  ...register(
                    'password', {
                      required: {
                        value: true,
                        message: t('loginPage.errors.password.required')
                      }
                    }
                  )
                }
              />
              {
                errors.password && 
                <div className='error-message'>
                  {errors.password.message}
                </div>
              }
            </div>
            <div className='flex flex-col items-center justify-center w-full mt-1 text-center lg:flex-row lg:text-left lg:justify-between'>
              <div className='w-full cursor-pointer text-light text-xs lg:w-3/5'>
                <Link  to='/forgot-password'>
                  { t('loginPage.forgotPassword') }
                </Link>
              </div>
            </div>
            <div className='flex w-full justify-center mt-4'>
                <PrimaryButton>
                  {t('loginPage.logIn')}
                </PrimaryButton>
              </div>
          </form>
          </div>
          {/*<div className='flex justify-center w-full py-4'>
            <img src={fmLogo} alt="" className="w-50" />
          </div>*/}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        theme="light"
        autoClose={2000}
        style={{ width: '500px' }}
      />
    </div>
  )
}
